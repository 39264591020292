import React, { Fragment } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        padding: `${theme.spacing(7 / theme.spacing(1))}px ${theme.spacing(2)}`,
    },
    buttonProgress: {
        position: 'relative' as const,
        left: '6px',
    },
});
export type CustomButtonProps = ButtonProps & { loading: boolean };

const CustomButton = props => {
    const { classes, children, className, loading, ...other } = props;
    return (
        <Fragment>
            <Button style={{ textTransform: 'uppercase' }} className={classNames(classes.root, className)} {...other}>
                {children}
                {loading && <CircularProgress size={15} className={classes.buttonProgress} />}
            </Button>
        </Fragment>
    );
};

export default withStyles(CustomButton, styles);
