import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import logo from 'assets/img/logo.svg';
import Nav from 'components/common/Nav/Nav';
import React, { BaseSyntheticEvent } from 'react';
import { UserInfo } from 'react-adal';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type HeaderProps = {
    userInfo: UserInfo;
    logout: Function;
};
const useStyles = makeStyles()((theme: Theme) => ({
    logo: {
        marginLeft: -3,
    },
    navWrapper: {
        minHeight: 64,
        display: 'flex',
        flexDirection: 'row',
        padding: `0 0 0 ${theme.spacing(6)}`,
        width: '100%',
        justifyContent: 'space-between',
    },
    appBar: {
        position: 'relative',
        zIndex: 1501,
    },
    logOutLink: {
        marginTop: theme.spacing(5),
    },
}));

export function Header(props: HeaderProps): React.ReactElement {
    const [auth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userInfo = props.userInfo;
    const logout = props.logout;

    const handleMenu = (event: BaseSyntheticEvent): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleLogout = (): void => {
        setAnchorEl(null);
        logout();
    };

    const { classes } = useStyles();

    return (
        <header>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Link to="/">
                        <IconButton edge="start" color="inherit" aria-label="menu" size="large">
                            <img src={logo} className={classes.logo} alt="logo" />
                        </IconButton>
                    </Link>
                    <Box className={classes.navWrapper}>
                        <Nav></Nav>
                        {auth && (
                            <Box display="flex" flexWrap="wrap" alignContent="center">
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="admin-appbar-menu"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    size="large"
                                >
                                    <Typography variant="body1" className="">
                                        Welcome, {userInfo.profile.name}
                                    </Typography>
                                    <Box ml={1}>
                                        <AccountCircle />
                                    </Box>
                                </IconButton>

                                <Menu
                                    id="admin-appbar-menu"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem className={classes.logOutLink} onClick={handleLogout}>
                                        Log Out
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </header>
    );
}
