import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSeoFiles, postSeoFiles, RobotsUpdate, SeoFileType } from 'apis/seo';
import { createGenericExtraReducers } from 'common/createGenericExtraReducers';
import { getInitialRequestState, RequestType } from 'common/RequestState';

export const fetchSeoRobots = createAsyncThunk('seo/robots/get', async (): Promise<SeoFileType> => {
    const files = await getSeoFiles();
    const treatedFiles = files.reduce(function (result, item) {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
    }, {});
    return treatedFiles;
});

export const postSeoRobots = createAsyncThunk('seo/robots/post', async (updatePayload: RobotsUpdate, thunkApi) => {
    await postSeoFiles(updatePayload);
    return updatePayload;
});

export const robotsSlice = createSlice({
    name: 'robots',
    initialState: {
        robots: <RequestType<SeoFileType, SeoFileType>>getInitialRequestState({}),
    },
    reducers: {},
    extraReducers: builder => {
        createGenericExtraReducers(builder, fetchSeoRobots, 'robots');
    },
});
