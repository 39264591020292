import { CategoryType } from 'models/category';
import { Translation } from 'models/translation';

export interface MediaStyleOptions {
    horizontal_alignment: string;
    vertical_alignment: string;
    cta_button_color: string;
    text_color: string;
}
export interface Media {
    id: number;
    type: MediaTypes;
    visibility: string;
    style_options?: MediaStyleOptions;
    asset_path: string;
    asset_file?: File | null;
    in_use: boolean;
    cta_url?: string;
    cta_translation?: Translation;
    banner_subtitle_translation?: Translation;
    banner_title_translation?: Translation;
    promotions?: number[];
}

export enum MediaTypes {
    banner = 'banner',
    leftTile = 'left_tile',
    rightTile = 'right_tile',
    promotion = 'promotion',
}

export enum MediaVerticalAlignment {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}

export enum MediaHorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum MediaVisibility {
    all = 'all',
    mac = 'mac',
    windows = 'windows',
    desktop = 'desktop',
    mobile = 'mobile',
}

export interface DefaultMedia {
    article_type: string;
    asset_path: string;
    category_type: CategoryType;
    id: number;
    vendor_id: number;
}
