/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    bulkCreateTranslations,
    bulkDeleteTranslations,
    bulkUpdateTranslations,
    createTranslation,
    updateTranslation,
} from 'apis/campaignApi';
import { CUSTOM_TRANSLATION, TranslationControl } from 'models/translation';
import { call } from 'redux-saga/effects';

/**
 * Returns the Translation data formatted to submit to the API
 * @param submittedTranslation
 */
function getTranslationObject(submittedTranslation: { data: TranslationControl }) {
    const translation = {
        id: submittedTranslation.data.id ?? null,
        type: CUSTOM_TRANSLATION,
    };

    // Check if the translation text was submitted
    if (submittedTranslation.data.translation !== undefined) {
        translation['custom_text'] = submittedTranslation.data.translation;
        translation['locale'] = submittedTranslation.data.locale;
    }

    return translation;
}

export function* doSubmitTranslation(submittedTranslation: { data: TranslationControl }) {
    const translation = getTranslationObject(submittedTranslation);
    return yield call(translation.id ? updateTranslation : createTranslation, translation);
}

export function* doBulkSubmitTranslations(submittedTranslations, create: boolean) {
    const translations = submittedTranslations.map(submittedTranslation => {
        return getTranslationObject(submittedTranslation);
    });

    return yield call(create ? bulkCreateTranslations : bulkUpdateTranslations, {
        translations: translations,
    });
}

export function* doBulkDeleteTranslations(translations) {
    const translationsIds = translations.map(translation => {
        return { id: translation.id };
    });

    return yield call(bulkDeleteTranslations, {
        translations: translationsIds,
    });
}
