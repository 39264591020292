import { Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import HistoryIcon from '@mui/icons-material/History';
import { AccountAuditDto } from 'apis/b2bAccounts';
import React from 'react';
import AccountChangesTimeline from './AccountChangesTimeline';

const useStyles = makeStyles()(theme => ({
    historyIcon: {
        color: theme.palette.warning.light,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        minWidth: 250,
    },
}));

type AccountChangesPopperProps = {
    audit: AccountAuditDto[];
    propsToDisplay: string[];
    isMultiple: boolean;
};

export default function AccountChangesPopper({
    audit,
    propsToDisplay,
    isMultiple,
}: AccountChangesPopperProps): React.ReactElement {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <HistoryIcon
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.historyIcon}
                data-cy="B2bAccountModal-InputHistory"
            />
            <Popover
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <AccountChangesTimeline
                    audit={audit}
                    propsToDisplay={propsToDisplay}
                    isMultiple={isMultiple}
                    cy="B2bAccountModal-PropLog"
                />
            </Popover>
        </>
    );
}
