export default class DiscountValidationError extends Error {
    message: string;

    constructor(message: string) {
        super(message);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DiscountValidationError.prototype);
        this.message = message;
    }
}
