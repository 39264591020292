import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export type PapDetailsType = {
    id: string;
    articleType: string;
    resizeGroup?: string;
    distributionType: string;
    categories: string[];
    isDuplex: boolean;
    productShotFileName: string;
    resizeRatio?: string;
    materials: Array<{
        materialCode: string;
        quantity: number;
    }>;
    skewID: string;
    pageWidth: number;
    pageHeight: number;
    title: string;
    productInfo?: string;
    pageCount: number;
    setCount: number;
    visible: boolean;
    orderRemark?: string;
    notificationImageUrl?: string;
    vendorArticleID: number;
    financial: {
        price: string;
        priceExcludingDiscount: string;
        priceIncludingDiscount: string;
        startupPriceOverride: string;
        shippingFinancials: Array<{
            startupPrice: string;
            pricePerPiece: string;
            deliveryType: string;
            deliveryService: string;
            zoneDesciption: string;
        }>;
        discountTiers?: Array<{
            pricePerItem: string;
            quantityFrom: number;
            quantityTo: number;
        }>;
    };
    template: {
        version: string;
        links: Array<{
            rel: string;
            href: string;
            type: string;
        }>;
    };
    links: Array<{
        rel: string;
        href: string;
        type: string;
        ref?: string;
    }>;
    spineCalculationType: string;
    paperType: string;
    paperTypeDescription?: string;
    paperTypeUpsellText?: string;
    paperTypeImageUrl?: string;
    piecesCount: number;
};

const replaceVendorName = (vendorName: string) => {
    return vendorName === 'onskefoto.se' ? 'albelli.se' : vendorName;
};

export const getPapDetails = async (papId: string, vendorName: string): Promise<PapDetailsType> => {
    const token = await oidc.getAccessToken();

    return await callApi(
        'ProductPricingCatalogApiUrl',
        'GET',
        `/Articles/${papId}?channel=${replaceVendorName(vendorName)}&format=json`,
        null,
        {},
        token,
    );
};
