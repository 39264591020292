import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { AccountAuditDto } from 'apis/b2bAccounts';
import { format, parseISO } from 'date-fns';
import React from 'react';

const useStyles = makeStyles()(theme => ({
    timelineItem: {
        '&::before': {
            content: 'none',
        },
    },
    timelineColorRequired: {
        color: theme.palette.warning.light,
        backgroundColor: theme.palette.warning.light,
    },
    timelineColorApproved: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.main,
    },
}));

type AccountChangesTimelineProps = {
    audit: AccountAuditDto[];
    propsToDisplay: string[];
    isMultiple: boolean;
    cy?: string;
};

const toLocalTime = date => {
    try {
        const parsedTime = parseISO(date);
        return format(parsedTime, 'yyyy-MM-dd kk:mm:ss');
    } catch (e) {
        return 'Invalid date';
    }
};

export default function AccountChangesTimeline({
    audit,
    propsToDisplay,
    isMultiple,
    cy,
}: AccountChangesTimelineProps): React.ReactElement {
    const { classes } = useStyles();

    return (
        <>
            <Timeline data-cy={cy}>
                {audit.map((auditItem, idx) => (
                    <TimelineItem key={idx} className={classes.timelineItem}>
                        <TimelineSeparator>
                            <TimelineDot
                                color="inherit"
                                className={classes['timelineColor' + auditItem.reviewStatus]}
                            />
                            <TimelineConnector className={classes['timelineColor' + auditItem.reviewStatus]} />
                        </TimelineSeparator>
                        <TimelineContent data-cy="B2bAccountModal-HistoryRow">
                            <Typography variant="caption">
                                {toLocalTime(auditItem.createdAt)} by {auditItem.changedBy}
                            </Typography>
                            {propsToDisplay
                                .filter(prop => auditItem.changedFields[prop])
                                .map(prop => (
                                    <Typography key={prop}>
                                        {isMultiple ? `${prop}: ` : ''}
                                        {auditItem.changedFields[prop]}
                                    </Typography>
                                ))}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </>
    );
}
