import { callApi } from 'apis/callApi';
import { oidc } from 'services/oidcService';

export interface Source {
    id: number;
    name: string;
    description: string;
    shortCode: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface SourcesCreateRequest {
    name: string;
    description: string;
    shortCode: string;
    channelIds: number[];
}

export const getSources = async (): Promise<Source[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'GET', 'sources', null, {}, token);
};

export const postSource = async (createRequest: SourcesCreateRequest): Promise<Source[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'POST', 'sources', createRequest, {}, token);
};
