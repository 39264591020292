import { useMemo, useState } from 'react';
import _ from 'lodash';

type ReturnStateType<I> = {
    result: I[];
    count: number;
    perPage: number;
    page: number;
};

type ReturnActionType = {
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
};

export default function usePaginationFilter<I, F>(
    list: I[],
    filter: F,
    filterFn: (item: I, filter: F) => boolean,
    perPageInitial = 50,
): [ReturnStateType<I>, ReturnActionType] {
    const [currentPage, setPage] = useState(0);
    const [perPage, setPerPage] = useState(perPageInitial);

    const filteredList = useMemo(() => {
        return list.filter(item => filterFn(item, filter));
    }, [list, filter]);

    const chunkedList: I[][] = useMemo(() => _.chunk(filteredList, perPage), [filteredList, perPage]);

    const maxPage = Math.max(0, chunkedList.length - 1);

    const cappedPage = Math.min(maxPage, currentPage);

    return [
        {
            result: chunkedList[cappedPage] || [],
            count: filteredList.length,
            perPage,
            page: cappedPage,
        },
        {
            setPage,
            setPerPage,
        },
    ];
}
