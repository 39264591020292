import { Container, Grid, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { CampaingChannelTile } from 'components/campaigns/Campaigns/CampaingChannelTile';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { lodMomentRoutes } from 'config/lodMomentRoutes';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontSize: '2rem',
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    outerPaper: {
        backgroundColor: theme.palette.common.white,
        padding: `${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`,
        maxWidth: '937px',
        margin: '0 auto',
    },
    innerPaper: {
        backgroundColor: CustomColors.background,
        padding: theme.spacing(5),
    },
}));
export interface LODOverviewProps {
    optional?: string;
}

const LODOverview: React.FC<LODOverviewProps> = () => {
    const { classes } = useStyles();

    return (
        <Container maxWidth="xl">
            <Box component="div" m={1}>
                <Typography align="center" variant="subtitle1" component="h1" className={classes.title}>
                    Last Order Date Categories
                </Typography>

                <Paper elevation={0} square className={classes.outerPaper}>
                    <Paper elevation={0} className={classes.innerPaper}>
                        <Grid container spacing={3}>
                            {Object.values(lodMomentRoutes).map((lodRouteObject, index) => (
                                <Grid item xs={6} key={index}>
                                    <CampaingChannelTile
                                        title={lodRouteObject.name}
                                        link={lodRouteObject.path}
                                        color={lodRouteObject.color}
                                        icon={lodRouteObject.icon}
                                        cypressTag={`lod${lodRouteObject.name}`}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Paper>
            </Box>
        </Container>
    );
};
export default LODOverview;
