import * as React from 'react';
import CampaignEditor from './CampaignEditor';

export default function CreateCampaign(): React.ReactElement {
    return (
        <>
            <CampaignEditor />
        </>
    );
}
