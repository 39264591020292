import React, { ReactElement } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

// This context is dynamically populated, but the initial object needs to be hardcoded
// Otherwise TypeScript complains about missing properties at compile time
export const RouterContext = React.createContext({});

type CustomBrowserRouterProps = {
    children: ReactElement;
};

//CustomBrowserRouter lets us recreate a RouterContext rather than using the private react-router one
const CustomBrowserRouter = (props: CustomBrowserRouterProps): ReactElement => {
    return (
        <BrowserRouter>
            <LastLocationProvider>
                <Route>
                    {routeProps => <RouterContext.Provider value={routeProps}>{props.children}</RouterContext.Provider>}
                </Route>
            </LastLocationProvider>
        </BrowserRouter>
    );
};

export default CustomBrowserRouter;
