import { Channel } from 'apis/attributionApi/channels';
import { fetchChannels } from 'application/attributions/attributionsSlice';
import { RequestState } from 'common/RequestState';
import { isNotRequested } from 'helpers/requestStateHelper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';

export const useAttributionChannels = (): [Channel[] | void, RequestState] => {
    const channels = useSelector(state => state.attributions.channels);

    const dispatch = useDispatch();
    useEffect(() => {
        if (isNotRequested(channels)) {
            dispatch(fetchChannels());
        }
    }, []);

    return [channels?.payload, channels?.requestState];
};
