//from the api and bulk update
export interface TranslationBriefType {
    id: number;
    translation: string;
    locale: string;
}

export interface TranslationType {
    id: number | null;
    type: string;
    custom_translation?: {
        data: {
            custom_text: string | null;
            locale: string;
        };
    };
}

export const CUSTOM_TRANSLATION = 'custom';
export const PHRASE_TRANSLATION = 'phrase';
export enum TranslationTypes {
    CUSTOM_TRANSLATION = 'custom',
    PHRASE_TRANSLATION = 'phrase',
}

export type TranslationControl = {
    id: number | null;
    translation: string;
    changed?: boolean;
    locale: string;
    type?: TranslationTypes;
};

export interface TranslationLocales {
    'en-GB': string;
    'nl-NL': string;
    'nl-BE': string;
    'sv-SE': string;
    'no-NO': string;
    'fr-FR': string;
    'de-DE': string;
}
export interface TranslationPredefinedType {
    id: number | null;
    key: string | null;
    type: string;
    preview: string;
    translations: TranslationLocales;
    meta: {
        discount_type: string;
        related_pap_id: string[];
        translation_group: string;
        related_article_type: string | null;
    };
}

export type Translation = { data?: TranslationType | TranslationControl };

export const TRANSLATION_GROUP_OVERALL = 'Overall promo comms';
export const TRANSLATION_GROUP_PROMO_BAR = 'Promo bar offers';
export const TRANSLATION_GROUP_FIXED = 'Fixed promo bar fields';
export const TRANSLATION_GROUP_CTA = 'CTA';

export const getTranslationsTemplate = (): TranslationLocales => {
    return {
        'en-GB': '',
        'nl-NL': '',
        'nl-BE': '',
        'sv-SE': '',
        'no-NO': '',
        'fr-FR': '',
        'de-DE': '',
    };
};
