import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export type LodMomentListType = object;

export type LodMomentType = {
    activeDate: string;
    offlineDate: string;
    shippingMethod: string;
    messages: {
        'Home-Standard': object;
        'Home-Express': object;
    };
};

export const getLodMomentsList = async (): Promise<Array<string>> => {
    const token = await oidc.getAccessToken();
    return await callApi('LodApiUrl', 'GET', 'moments', null, {}, token);
};

export const getLodMomentsData = async (vendor, moment): Promise<LodMomentType> => {
    const token = await oidc.getAccessToken();
    try {
        return await callApi('LodApiUrl', 'GET', `${vendor}/${moment}`, null, {}, token);
    } catch (error) {
        console.error('Error fetching LOD data', error);

        return {
            activeDate: '',
            offlineDate: '',
            shippingMethod: '',
            messages: {
                'Home-Standard': {},
                'Home-Express': {},
            },
        };
    }
};

export const postLodFiles = async (vendor, moment, body): Promise<LodMomentType> => {
    const token = await oidc.getAccessToken();
    return await callApi('LodApiUrl', 'PUT', `${vendor}/${moment}`, body, {}, token);
};
