export default class DiscountDefinitionParseError extends Error {
    message: string;
    originalError?: Error;

    constructor(message: string, originalError?: Error) {
        super(message);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DiscountDefinitionParseError.prototype);
        this.message = message;
        if (originalError) {
            this.originalError = originalError;
        }
    }
}
