import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import ReduxThunk from 'redux-thunk';
import { indexReducer } from './indexReducer';
import { indexSaga } from './indexSaga';

const sagasMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer: indexReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagasMiddleware, ReduxThunk),
});

sagasMiddleware.run(indexSaga);

if (window.Cypress) {
    window.store = store;
}

export default store;

export type RootState = ReturnType<typeof indexReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { useSelector, dispatch, useDispatch };
