import { callApi } from 'apis/callApi';
import { oidc } from 'services/oidcService';

export interface Medium {
    id: number;
    name: string;
    description: string;
    shortCode: string;
    createdAt: Date;
    updatedAt: Date;
}

export const getMediums = async (): Promise<Medium[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'GET', 'mediums', null, {}, token);
};
