import { format, parseISO } from 'date-fns';

// 2020-04-22T13:05:20+00:00
// prettier-ignore
const ATOM_FORMAT = 'yyyy-LL-dd' + '\'T\'' + 'HH:mm:ssxxx';
const CAMPAIGN_UI_FORMAT = 'dd-LLL-yyyy';
const CAMPAIGN_UI_FORMAT_WITH_TIME = 'dd-LLL-yyyy HH:mm:ss';

/**
 * Parses an UTC Date string in Atom format to Date
 * @param {Date} atomDate
 */
export const parseAtomToDate = (atomDateString: string): Date => {
    return parseISO(atomDateString);
};

/**
 * Parses a date string in ATOM format
 * @param {string} atomDate
 */
export const formatCampaignDate = (atomDate: string, withTime = false): string => {
    let formatString = CAMPAIGN_UI_FORMAT;
    if (withTime) {
        formatString = CAMPAIGN_UI_FORMAT_WITH_TIME;
    }
    return format(parseAtomToDate(atomDate), formatString);
};

/**
 * Parses a date to ATOM format
 * @param {Date} date
 */
export const parseDateToAtom = (date: Date): string => {
    const isoDate = new Date(date.toISOString());
    return format(isoDate, ATOM_FORMAT);
};

export const formatDate = (date: Date): string => {
    return format(date, 'dd/LLL/yyyy');
};

export const hasRepeatedDates = (dates: Date[]): boolean => {
    const tempArray: number[] = [];
    let has = false;
    if (dates) {
        for (const date of dates) {
            if (tempArray.indexOf(date.getTime()) < 0) {
                tempArray.push(date.getTime());
            } else {
                has = true;
            }
        }
    }

    return has;
};

/**
 * Set second an milisecond values to standard values for time comparison
 */
export const setStandardSecondValue = (date: Date): Date => {
    const clonedDate = new Date(date.valueOf());
    clonedDate.setSeconds(0);
    clonedDate.setMilliseconds(0);
    return clonedDate;
};

export const isValidDate = (d: unknown): boolean => {
    return d instanceof Date && !isNaN(d.getTime());
};
