import { Loader } from 'components/common/Loader/Loader';
import React, { ReactElement, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';

export type PrivateRouteProps = {
    children?: ReactElement;
    path: string | string[];
    // All other props
    [prop: string]: any;
};

// Based on https://reactrouter.com/web/example/auth-workflow
// A wrapper for <Route> that redirects to the same page to trigger the Auth token refresh.
// If the user is not yet authenticated.
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path, ...props }) => {
    const [tokenRefreshed, setTokenRefreshed] = useState(false);
    useEffect(() => {
        async function refreshToken(): Promise<void> {
            // Token refresh, it may be silent or force a login redirect
            await activeDirectoryServiceInstance.renewToken();
            // Change the state value to force a re-render
            setTokenRefreshed(true);
        }
        // Checks if the user is no longer authenticated
        if (!activeDirectoryServiceInstance.isAuthenticated) {
            refreshToken();
        }
    }, [activeDirectoryServiceInstance.isAuthenticated]);

    return (
        <Route
            path={path}
            {...props}
            render={() => (activeDirectoryServiceInstance.isAuthenticated ? children : <Loader />)}
        />
    );
};

export default PrivateRoute;
