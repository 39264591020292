import { Box, Paper, Typography } from '@mui/material';
import * as React from 'react';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel: React.JSXElementConstructor<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            <Paper square>{value === index && <Box p={3}>{children}</Box>}</Paper>
        </Typography>
    );
};

export default TabPanel;
