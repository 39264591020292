import {
    CAMPAIGN_CURRENT_RECEIVED,
    CAMPAIGN_CURRENT_REQUESTED,
    CAMPAIGN_CURRENT_REQUEST_FAILED,
    CAMPAIGN_SCHEDULED_REQUESTED,
    CAMPAIGN_SCHEDULED_RECEIVED,
    CAMPAIGN_SCHEDULED_REQUEST_FAILED,
    CAMPAIGN_EXPIRED_REQUESTED,
    CAMPAIGN_EXPIRED_RECEIVED,
    CAMPAIGN_EXPIRED_REQUEST_FAILED,
} from './campaignListTypes';
import { createGenericReducer } from 'common/genericReducer';

export const campaignsListCurrent = createGenericReducer(
    CAMPAIGN_CURRENT_REQUESTED,
    CAMPAIGN_CURRENT_RECEIVED,
    CAMPAIGN_CURRENT_REQUEST_FAILED,
);
export const campaignsListScheduled = createGenericReducer(
    CAMPAIGN_SCHEDULED_REQUESTED,
    CAMPAIGN_SCHEDULED_RECEIVED,
    CAMPAIGN_SCHEDULED_REQUEST_FAILED,
);
export const campaignsListExpired = createGenericReducer(
    CAMPAIGN_EXPIRED_REQUESTED,
    CAMPAIGN_EXPIRED_RECEIVED,
    CAMPAIGN_EXPIRED_REQUEST_FAILED,
);
