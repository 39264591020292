import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { BnclVendorCampaign, PageDetailsDto, VendorCampaignStatus } from 'apis/bnclCampaign';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { fetchTranslations } from '../../../application/translation/translationSlice';
import { isLoading } from '../../../helpers/requestStateHelper';
import { useDispatch, useSelector } from '../../../store';
import { Loader } from '../../common/Loader/Loader';
import BnclOfferAccordion, { BnclAccordionHandlers, BnclOfferResult } from './BnclOfferAccordion';
import VendorBnclCampaignUrls from './VendorBnclCampaignUrls';
import VendorBnclDetailsForm, { VendorBnclDetailsFormHandlers } from './VendorBnclDetailsForm';

type VendorBnclCampaignProps = {
    vendor: Vendor;
    vendorCampaign?: BnclVendorCampaign;
    campaignId?: string;
    onSubmit: (status: VendorCampaignStatus) => void;
    onDelete: (vendorName: string) => void;
};

export interface VendorCampaignHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<{ offers: BnclOfferResult[]; pageDetails: PageDetailsDto } | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    wrapper: {
        minHeight: '70vh',
    },
    actionBox: {
        float: 'right',
        border: `1px solid ${CustomColors.border}`,
        borderRadius: theme.shape.borderRadius,
    },
    clearfix: {
        '&:after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
    },
    actionRow: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        '& > * + *': {
            marginLeft: `${theme.spacing(1)} !important`,
        },
    },
    delBtn: {
        alignSelf: 'center',
        color: theme.palette.error.main,
        '&[disabled]': {
            color: theme.palette.grey[500],
            textDecoration: 'none',
            cursor: 'default',
        },
    },
}));

function VendorBnclCampaign(
    { vendor, onSubmit, onDelete, vendorCampaign, campaignId }: VendorBnclCampaignProps,
    ref,
): React.ReactElement {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const translations = useSelector(state => state.translations.list);

    const offersRef = useRef<BnclAccordionHandlers>();
    const detailsRef = useRef<VendorBnclDetailsFormHandlers>();

    useEffect(() => {
        dispatch(fetchTranslations(vendor.locale));
    }, [vendor]);

    useImperativeHandle(
        ref,
        (): VendorCampaignHandlers => ({
            submit: async () => {
                const [offers, pageDetails] = await Promise.all([
                    offersRef.current?.submit(),
                    detailsRef.current?.submit(),
                ]);

                if (!offers || !pageDetails) return;

                return {
                    offers,
                    pageDetails,
                };
            },
            isModified: () => {
                return offersRef.current?.isModified() || detailsRef.current?.isModified() || false;
            },
        }),
    );

    const onDeleteConfirm = async () => {
        setIsDeleteDialogOpen(false);
        onDelete(vendor.name);
    };

    const isDraft = !vendorCampaign || vendorCampaign.status === VendorCampaignStatus.DRAFT;

    if (isLoading(translations)) {
        return <Loader />;
    }

    return (
        <Box className={`${classes.wrapper} ${classes.clearfix}`}>
            <Box className={classes.clearfix}>
                <Box className={classes.actionBox}>
                    {isDraft && (
                        <Box className={classes.actionRow}>
                            {vendorCampaign && (
                                <Link
                                    component="button"
                                    type="button"
                                    className={classes.delBtn}
                                    variant="body2"
                                    onClick={() => {
                                        setIsDeleteDialogOpen(true);
                                    }}
                                    data-cy="Bncl-Campaign-DeleteVendor"
                                >
                                    Delete Campaign
                                </Link>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => onSubmit(VendorCampaignStatus.DRAFT)}
                                data-cy="Bncl-Campaign-SaveDraft"
                            >
                                Save Draft
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onSubmit(VendorCampaignStatus.PUBLISHED)}
                                data-cy="Bncl-Campaign-SavePublished"
                            >
                                Publish Vendor
                            </Button>
                        </Box>
                    )}
                    {!isDraft && (
                        <Box className={classes.actionRow}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => onSubmit(VendorCampaignStatus.UNPUBLISHED)}
                                data-cy="Bncl-Campaign-SaveUnpublished"
                            >
                                {vendorCampaign?.status === VendorCampaignStatus.UNPUBLISHED ? 'Save' : 'Unpublish'}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onSubmit(VendorCampaignStatus.PUBLISHED)}
                                data-cy="Bncl-Campaign-SavePublished"
                            >
                                {vendorCampaign?.status === VendorCampaignStatus.PUBLISHED ? 'Save' : 'Publish'}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <BnclOfferAccordion
                ref={offersRef}
                vendor={vendor}
                offers={vendorCampaign?.offers}
                campaignStatus={vendorCampaign?.status}
            />
            <VendorBnclDetailsForm ref={detailsRef} details={vendorCampaign?.pageDetails} />
            <VendorBnclCampaignUrls vendor={vendor} vendorCampaign={vendorCampaign} campaignId={campaignId} />

            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                data-cy="Bncl-Campaign-DeleteConfirmDialog"
            >
                <DialogTitle sx={{ mb: 1 }}>Delete Campaign at {vendor.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this Campaign?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setIsDeleteDialogOpen(false)} data-cy="dialog-btn-cancel">
                        Cancel
                    </Button>
                    <Button onClick={onDeleteConfirm} variant="contained" color="primary" data-cy="dialog-btn-confirm">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default forwardRef(VendorBnclCampaign);
