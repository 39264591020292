import { InputLabelProps, SlotComponentProps, TextField } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers';
import { isValidDate } from 'helpers/dateHelper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    tzHints: {
        fontSize: '0.7rem',
    },
}));

export type KeyboardDateTimePickerProps = DateTimePickerProps<Date> & {
    showTzHints?: boolean;
    helperText?: string;
    error?: boolean;
    fullWidth?: boolean;
    required?: boolean;
    InputLabelProps?: Partial<InputLabelProps>;
};

export const KeyboardDateTimePicker = ({
    showTzHints = false,
    value,
    label,
    helperText,
    error,
    fullWidth,
    required = false,
    InputLabelProps,
    ...props
}: KeyboardDateTimePickerProps): React.ReactElement => {
    const { classes } = useStyles();
    const [tzHints, setTzHints] = useState<string | React.ReactNode>('');

    if (showTzHints) {
        label += ' (your local time)';

        useEffect(() => {
            const date = (typeof value === 'string' ? new Date(value) : value) as Date;
            if (!isValidDate(date)) {
                setTzHints('');
                return;
            }

            const wetTime = date.toLocaleString('en-UK', { timeZone: 'Europe/London' }).slice(0, -3);
            const cetTime = date.toLocaleString('en-UK', { timeZone: 'Europe/Amsterdam' }).slice(0, -3);

            setTzHints(
                <span className={classes.tzHints}>
                    <b>WET:</b> {wetTime} | <b>CET:</b> {cetTime}
                </span>,
            );
        }, [value]);
    }

    return (
        <DateTimePicker
            value={value}
            label={label}
            slotProps={{
                textField: {
                    helperText: helperText || tzHints,
                    error,
                    fullWidth,
                    required,
                    InputLabelProps,
                    'data-cy': props['data-cy'],
                } as SlotComponentProps<typeof TextField & { 'data-cy': string }, object, Record<string, any>>,
            }}
            ampm={false}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            {...props}
            data-cy="asadasd"
        />
    );
};
