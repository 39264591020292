import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Badge, Box, FormHelperText, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { Loader } from 'components/common/Loader/Loader';
import React, { Fragment, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

export const UPLOAD_STATE_UPLOADED = 'uploaded';
export const UPLOAD_STATE_LOADING = 'loading';

export interface ImageUploadInputProps {
    name: string;
    buttonText?: string;
    preview?: boolean;
    previewWidth?: number;
    previewHeight?: number;
    fileValue: File | null;
    handleChange: any;
    error?: boolean;
    helperText?: string;
    filePath?: string | null;
    multiple?: boolean;
    allowDeletion?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    fileName: {
        color: CustomColors.text.light,
        overflowWrap: 'break-word',
        display: 'block',
    },
    input: {
        display: 'none',
    },
    preview: {
        marginTop: theme.spacing(1),
        borderRadius: 0,
    },
    closeIcon: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: theme.spacing(1),
        width: '20px',
        height: '20px',
        color: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: theme.palette.grey['300'],
        },
    },
    previewContainer: {
        right: '5%',
    },
    label: {
        color: CustomColors.text.link,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    labelDisabled: {
        color: theme.palette.text.disabled,
    },
    errorHelperText: {
        float: 'right',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
    },
}));

const ImageUploadInput = ({
    error = false,
    helperText = '',
    buttonText = 'Add Image',
    preview = true,
    previewWidth = 415,
    previewHeight = 137,
    name,
    fileValue,
    handleChange,
    multiple = false,
    filePath,
    allowDeletion = true,
    disabled = false,
}: ImageUploadInputProps): React.ReactElement => {
    const { classes } = useStyles();

    const inputRef = React.useRef<HTMLInputElement>(null);
    const [mainState, setMainState] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(fileValue);
    const [path, setPath] = React.useState(filePath ?? '');

    useEffect(() => {
        setPath(filePath ?? '');
    }, [filePath]);

    if (multiple) preview = false;

    const handleClick = ({ target }) => {
        const file = target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setPath(URL.createObjectURL(file));
        setMainState(UPLOAD_STATE_LOADING);
        target.value = null;

        reader.onloadend = e => {
            setSelectedFile(file);
            setMainState(UPLOAD_STATE_UPLOADED);
            handleChange(file);
        };
    };

    const handleMultiple = ({ target }) => {
        handleChange(target.files);
    };

    const handleDeleteClick = e => {
        setMainState('');
        setSelectedFile(null);
        setPath('');
        handleChange(null);
    };

    const handleOpen = () => {
        inputRef.current?.click();
    };

    return (
        <Fragment>
            {mainState === UPLOAD_STATE_LOADING && <Loader />}
            {((selectedFile && mainState === UPLOAD_STATE_UPLOADED) || path !== '') && preview === true && (
                <Fragment>
                    <span className={classes.fileName}>{selectedFile?.name}</span>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        classes={{ badge: classes.previewContainer }}
                        badgeContent={
                            allowDeletion === true && (
                                <IconButton
                                    aria-label="delete"
                                    className={classes.closeIcon}
                                    onClick={handleDeleteClick}
                                    size="large"
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )
                        }
                        data-cy={`ImageUploadInput-preview-${name}`}
                    >
                        <Avatar
                            className={classes.preview}
                            src={path}
                            style={{ height: previewHeight, width: previewWidth }}
                        />
                    </Badge>
                </Fragment>
            )}
            <input
                name={name}
                ref={inputRef}
                className={classes.input}
                id={'file-upload-' + name}
                multiple
                type="file"
                onChange={multiple ? handleMultiple : handleClick}
                accept=".jpg, .png, .jpeg, .gif"
                disabled={disabled}
                data-cy={`ImageUploadInput-uploadImage-${name}`}
            />
            <Box mt={1} mb={1}>
                <label className={disabled ? classes.labelDisabled : classes.label} onClick={handleOpen}>
                    {buttonText}
                </label>
                {error && helperText && (
                    <FormHelperText
                        error={error}
                        className={classes.errorHelperText}
                        data-cy={`ImageUploadInput-imageError-${name}`}
                    >
                        {helperText}
                    </FormHelperText>
                )}
            </Box>
        </Fragment>
    );
};

export default ImageUploadInput;
