import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    RadioGroupProps,
} from '@mui/material';
import React, { useMemo } from 'react';

export type FormikRadioGroupProps = Omit<RadioGroupProps, 'value' | 'onChange'> & {
    formik: any;
    name: string;
    label?: string;
    disabled?: boolean;
    options: Array<{ label: string; value: any } | string>;
};

export const FormikRadioGroup = ({
    formik,
    name,
    label,
    disabled,
    options,
    ...props
}: FormikRadioGroupProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    const opts = useMemo(() => {
        return options.map(opt => (typeof opt === 'string' ? { label: opt, value: opt } : opt));
    }, [options]);

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    return (
        <FormControl component="fieldset" error={hasError}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup
                aria-label={name}
                name={name}
                {...props}
                value={formik.values[name]}
                onChange={formik.handleChange}
            >
                {opts.map(opt => (
                    <FormControlLabel
                        key={opt.value}
                        value={opt.value}
                        control={<Radio color="primary" data-cy={`FormikRadioGroup-Radio-${opt.value}`} />}
                        label={opt.label}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
            {hasError && <FormHelperText>{formik.errors[name]}</FormHelperText>}
        </FormControl>
    );
};
