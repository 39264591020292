import { Catalog } from '@albelli/ecom-promotions-editor';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCatalog } from 'apis/promotionsApi';
import { getInitialRequestState, RequestState, RequestType } from 'common/RequestState';

export const fetchCatalog = createAsyncThunk('catalog', async (): Promise<Catalog> => {
    return await getCatalog();
});

const initialState: RequestType<Catalog, Catalog> = getInitialRequestState({} as Catalog);

export const promotionCatalogSlice = createSlice({
    name: 'promotionCatalog',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCatalog.pending, (state, action) => {
            state.requestState = RequestState.InProgress;
        });
        builder.addCase(fetchCatalog.fulfilled, (state, action) => {
            state.payload = action.payload;
            state.requestState = RequestState.Finished;
        });
        builder.addCase(fetchCatalog.rejected, (state, action) => {
            state.requestState = RequestState.Failed;
        });
    },
});
