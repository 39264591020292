import { OfferDiscountDetails } from 'apis/bnclCampaign';

const optionDefinition = (optionName: string, options: OfferDiscountDetails['options'], PAP: string) => {
    const val = options[optionName];
    if (!val || !val.isFree) return null;
    let str = `apply 100% discount to ${PAP}/${optionName} of 1 ${PAP}`;
    if (val.value) {
        str += `\n    having ${PAP}/${optionName} = ${val.value}`;
    }
    return str;
};

export const buildDiscountDefinition = (discount: OfferDiscountDetails, vendorName: string): string => {
    if (!discount.papId) {
        return '';
    }
    const codeLines: Array<string | null> = [`for vendor ${vendorName}`];

    const PAP = discount.papId;
    codeLines.push(`for cart with 1 ${PAP}`);
    codeLines.push(`apply 100% discount to base price of 1 ${PAP}`);

    codeLines.push(optionDefinition('CoverType', discount.options, PAP));

    const extraPages = 'ExtraPages';
    if (discount.options[extraPages] && discount.options[extraPages].isFree && discount.options[extraPages].value) {
        codeLines.push(`apply 100% discount to ${discount.options[extraPages].value} ${PAP}/ExtraPages of 1 ${PAP}`);
    }

    codeLines.push(optionDefinition('Glossy', discount.options, PAP));
    codeLines.push(optionDefinition('CoverText', discount.options, PAP));
    codeLines.push(optionDefinition('LayFlat', discount.options, PAP));
    codeLines.push(optionDefinition('PremiumLayFlat', discount.options, PAP));
    codeLines.push(optionDefinition('PaperType', discount.options, PAP));

    if (discount.freeShipping) {
        const country = vendorName.split('.').pop() as string;
        const countryReplacement = {
            uk: 'gb',
        };
        codeLines.push(`apply 100% discount to ${countryReplacement[country] || country} shipping`);
    }

    return codeLines.filter(Boolean).join('\n');
};

export const supportedPapTags = {
    photobook: 'PHOTOBOOKS',
    calendar: 'CALENDARS',
} as const;
