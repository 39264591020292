import { Alert, Box, Grid, TextField, Theme, Typography } from '@mui/material';
import { BnclVendorCampaign, CampaignCategory, VendorCampaignStatus } from 'apis/bnclCampaign';
import { useCopier } from 'components/hooks/useCopier';
import { getStorefrontUrl } from 'helpers/urlHelper';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

type VendorBnclCampaignUrlsProps = {
    vendor: Vendor;
    vendorCampaign?: BnclVendorCampaign;
    campaignId?: string;
};

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
    },

    title: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },

    notAvailableWarning: {
        '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
        },
    },
}));

export default function VendorBnclCampaignUrls({
    vendor,
    vendorCampaign,
    campaignId,
}: VendorBnclCampaignUrlsProps): React.ReactElement {
    const { classes } = useStyles();

    const [copiedText, onCopy] = useCopier();

    const { isVisible, merchandising, crm, hasNotAvailableOffers, previewLink } = useMemo(() => {
        if (!vendorCampaign || vendorCampaign.status !== VendorCampaignStatus.PUBLISHED)
            return {
                isVisible: false,
            };

        const merchandising = vendorCampaign.attributions.find(a => a.category === CampaignCategory.MERCHANDISING);
        const crm = vendorCampaign.attributions.find(a => a.category === CampaignCategory.CRM);

        const getLink = (utm: string) =>
            getStorefrontUrl(vendor.name, `lp/?bnclCampaign=${campaignId}&utm_campaign=${utm}`);

        // Check if the vendor campaign has offers that were last published within last hour
        const currentTimestamp = new Date().getTime();
        const hasNotAvailableOffers = vendorCampaign.offers.some(
            offer => currentTimestamp - new Date(offer.lastPublishDate).getTime() <= 60 * 60 * 1000,
        );

        const merchandisingLink = merchandising && getLink(merchandising.code);
        const crmLink = crm && getLink(crm.code);
        const previewLink = `${merchandisingLink || crmLink}&bnclPreview=true`;

        return {
            isVisible: true,
            merchandising: merchandisingLink,
            crm: crmLink,
            previewLink: previewLink,
            hasNotAvailableOffers,
        };
    }, [vendor, vendorCampaign]);

    return (
        <Box className={classes.container} data-cy="Bncl-CampaignUrls">
            <Box component="h2" className={classes.title}>
                Campaign URLs
            </Box>
            {isVisible && (
                <Grid container spacing={2}>
                    {merchandising && (
                        <Grid item xs={10}>
                            <TextField
                                name="merchandising"
                                label="Attribution URL - Merchandising"
                                fullWidth
                                value={merchandising}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                onClick={() => onCopy(merchandising)}
                                data-cy="Bncl-CampaignUrls-Merchandising"
                                helperText={copiedText === merchandising && 'Copied to clipboard'}
                            />
                        </Grid>
                    )}
                    {crm && (
                        <Grid item xs={10}>
                            <TextField
                                name="crm"
                                label="Attribution URL - CRM"
                                fullWidth
                                value={crm}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                onClick={() => onCopy(crm)}
                                data-cy="Bncl-CampaignUrls-CRM"
                                helperText={copiedText === crm && 'Copied to clipboard'}
                            />
                        </Grid>
                    )}
                    {hasNotAvailableOffers && (
                        <Grid item xs={10}>
                            <Alert
                                severity="warning"
                                variant="filled"
                                className={classes.notAvailableWarning}
                                data-cy="Bncl-CampaignUrls-OfferAvailabilityWarning"
                            >
                                Product catalog takes 1 hour to update offer-data, offers will be hidden on the landing
                                page until available! Use the{' '}
                                <a data-cy="Bncl-CampaignUrls-PreviewLink" href={previewLink}>
                                    preview link
                                </a>{' '}
                                to preview the campaign.
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            )}
            {!isVisible && (
                <Typography data-cy="Bncl-CampaignUrls-Hidden">
                    URL(s) will become available when landing page is set up and this campaign is published
                </Typography>
            )}
        </Box>
    );
}
