// eslint-disable @typescript-eslint/no-explicit-any
import ResponseError, { ResponseData } from './ResponseError';

export default class ConflictResponseError extends ResponseError {
    constructor(message: string, responseData: ResponseData, data: any = {}) {
        super(message, responseData, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ConflictResponseError.prototype);
    }
}
