/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createCampaign, updateCampaign } from 'apis/campaignApi';
import { receivedOffers } from 'application/campaignForm/offerCreate/offerCreateActions';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { parseDateToAtom, setStandardSecondValue } from 'helpers/dateHelper';
import { Offer, OfferApi, mapOfferFromApi } from 'models/offer';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { completeCampaignFormSubmission } from './campaignFormStore';

export const CAMPAIGN_SUBMIT_REQUESTED = 'CAMPAIGN_SUBMIT_REQUESTED';
export const CAMPAIGN_SUBMIT_RECEIVED = 'CAMPAIGN_SUBMIT_RECEIVED';
export const CAMPAIGN_SUBMIT_REQUEST_FAILED = 'CAMPAIGN_SUBMIT_REQUEST_FAILED';
export const CAMPAIGN_SUBMIT_REQUEST_RESET = 'CAMPAIGN_SUBMIT_REQUEST_RESET';

export const submitCampaign = (submitType, campaign) => ({
    type: CAMPAIGN_SUBMIT_REQUESTED,
    submitType,
    campaign,
});
export const receiveSubmittedCampaign = payload => ({
    type: CAMPAIGN_SUBMIT_RECEIVED,
    payload,
});
export const errorSubmittedCampaign = requestAction => ({
    type: CAMPAIGN_SUBMIT_REQUEST_FAILED,
    error: new Error('Failed submitting the Campaign.'),
    requestAction,
});

export const resetSubmittedCampaign = () => ({
    type: CAMPAIGN_SUBMIT_REQUEST_RESET,
});

export const submittedCampaignReducer = createGenericReducer(
    CAMPAIGN_SUBMIT_REQUESTED,
    CAMPAIGN_SUBMIT_RECEIVED,
    CAMPAIGN_SUBMIT_REQUEST_FAILED,
    CAMPAIGN_SUBMIT_REQUEST_RESET,
);

export function* doSubmitCampaign(action, requestInfo: RequestInfo) {
    let campaign;
    const expirationDate = setStandardSecondValue(action.campaign.expirationDate);
    const activationDate = setStandardSecondValue(action.campaign.activationDate);

    action.campaign['activation_date'] = parseDateToAtom(activationDate);
    action.campaign['expiration_date'] = parseDateToAtom(expirationDate);

    if (action.campaign.extensions && action.campaign.extensions.length > 0) {
        const formattedExtensions: Record<string, any>[] = [];
        action.campaign.extensions.forEach(extension => {
            if (extension.date) {
                formattedExtensions.push({ ...extension, date: parseDateToAtom(extension.date) });
            }
        });
        action.campaign['extensions'] = formattedExtensions;
    }

    if (action.submitType === 'create') {
        campaign = yield call(createCampaign, action.campaign);
    } else if (action.submitType === 'edit') {
        campaign = yield call(updateCampaign, action.campaign);
    }

    yield all([put(receiveSubmittedCampaign(campaign)), put(completeCampaignFormSubmission(1, campaign.data, true))]);

    if (campaign.data.offers?.data) {
        const formattedOffers: Array<Offer> = campaign.data.offers.data.map(offer =>
            mapOfferFromApi({ ...offer } as OfferApi),
        );
        yield put(receivedOffers({ data: formattedOffers }));
    }
}

export function* watchSubmittedFormStore() {
    yield all([takeEvery(CAMPAIGN_SUBMIT_REQUESTED, doCall(doSubmitCampaign, CAMPAIGN_SUBMIT_REQUEST_FAILED))]);
}
