import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
    Account,
    AccountAddressDto,
    AccountAuditDto,
    AccountDetailsDto,
    AccountStatuses,
    blockAccount,
    getAccountAddresses,
    getAccountAudit,
    getAccountDetails,
    getAccountTransactionsByIds,
    getAccountTransactionsByStatus,
    getB2BAccounts,
    SearchableTransactionStatuses,
    searchByEmail,
    successfullyReviewAccount,
    TransactionStatuses,
} from 'apis/b2bAccounts';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { ReduxAction } from 'common/genericTypes';
import { getInitialRequestState, RequestState, RequestType } from 'common/RequestState';

export type WithTransactionStatus = {
    lastTransactionStatus: string | null;
};

type AccountDetails = {
    isVisible: boolean;
    content: null | {
        account: AccountDetailsDto & WithTransactionStatus;
        address: AccountAddressDto | undefined;
        audit: AccountAuditDto[];
        fullAudit: AccountAuditDto[];
    };
};

type B2bAccountType = Account & WithTransactionStatus;

type AccountsState = {
    list: RequestType<B2bAccountType[], B2bAccountType[]>;
    lastEvaluatedKey: string;
    accountDetails: RequestType<AccountDetails, AccountDetails>;
    accountStatusSubmit: RequestType<void>;
};

type AccountRequestsState = Omit<AccountsState, 'lastEvaluatedKey'>;

type SetPayloadActionType<S extends keyof AccountRequestsState> = {
    store: S;
    value: AccountRequestsState[S]['payload'];
};

const handleError = (dispatch: Dispatch, error: any) => {
    if (error && typeof error.message === 'string') {
        dispatch(showStatusMessage(error.message, 'error'));
    }
    if (error && Array.isArray(error.message) && error.message.every(e => typeof e === 'string')) {
        // nestjs class-validator errors
        dispatch(showStatusMessage(['Validation Error', ...error.message].join('\n'), 'error'));
    }
};

const initialState: AccountsState = {
    list: getInitialRequestState([]),
    lastEvaluatedKey: '',
    accountDetails: getInitialRequestState({
        isVisible: false,
        content: null,
    }),
    accountStatusSubmit: getInitialRequestState(),
} as const;

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        startLoading(state, action: ReduxAction<keyof AccountRequestsState>) {
            state[action.payload].requestState = RequestState.InProgress;
        },
        loadCompleted(state, action: ReduxAction<keyof AccountRequestsState>) {
            state[action.payload].requestState = RequestState.Finished;
        },
        loadFailed(state, action: ReduxAction<keyof AccountRequestsState>) {
            state[action.payload].requestState = RequestState.Failed;
        },
        setPayload<S extends keyof AccountRequestsState>(state, action: ReduxAction<SetPayloadActionType<S>>) {
            state[action.payload.store].payload = action.payload.value;
        },
        appendAccountPayload(state, action: ReduxAction<B2bAccountType[]>) {
            state.list.payload = [...state.list.payload, ...action.payload];
        },
        setLastEvaluatedKey(state, action: ReduxAction<string>) {
            state.lastEvaluatedKey = action.payload;
        },
        setAccountDetailsFullAudit(state, action: ReduxAction<AccountAuditDto[]>) {
            if (state.accountDetails.payload.content) {
                state.accountDetails.payload.content.fullAudit = action.payload;
            }
        },
        removeListItem(state, action: ReduxAction<string>) {
            state.list.payload = state.list.payload.filter(account => !(account.accountId === action.payload));
        },
    },
});

async function fetchCustomerAccountsFirst(status: string, ascendingOrder = false, lastEvaluatedKey?: string) {
    const customerResponse = await getB2BAccounts({ status, ascendingOrder }, lastEvaluatedKey);
    if (customerResponse.profiles.length === 0) {
        return {
            list: [],
            lastEvaluatedKey: customerResponse.lastEvaluatedKey,
        };
    }
    const accountIds = customerResponse.profiles.map(account => account.accountId);
    const transactionResponse = await getAccountTransactionsByIds(accountIds);

    return {
        list: customerResponse.profiles.map(row => ({
            ...row,
            lastTransactionStatus: transactionResponse.find(t => t.accountId === row.accountId)?.status || null,
        })) as B2bAccountType[],
        lastEvaluatedKey: customerResponse.lastEvaluatedKey,
    };
}

async function fetchAccountTransactionsFirst(status: TransactionStatuses, lastEvaluatedKey?: string) {
    const transactionResponse = await getAccountTransactionsByStatus({ status }, lastEvaluatedKey);
    if (transactionResponse.list.length === 0) {
        return {
            list: [],
            lastEvaluatedKey: transactionResponse.lastEvaluatedKey || '',
        };
    }

    const memo = {} as { [accountId: string]: Account };

    await Promise.all(
        transactionResponse.list.map(
            t =>
                getAccountDetails(t.accountId)
                    .then(account => (memo[account.accountId] = account))
                    .catch(() => null), // ignore errors, show as unknown
        ),
    );

    const unknownEntry = {
        email: 'Unknown Account',
        status: 'Unknown',
        reviewStatus: '',
        reviewRequestedDate: '',
    };

    return {
        list: transactionResponse.list.map(t => ({
            ...(memo[t.accountId] || unknownEntry),
            accountId: t.accountId,
            lastTransactionStatus: t.status,
        })) as B2bAccountType[],
        lastEvaluatedKey: transactionResponse.lastEvaluatedKey || '',
    };
}

export function fetchB2BAccounts(status: string, ascendingOrder = false, lastEvaluatedKey?: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(accountsSlice.actions.startLoading('list'));
        try {
            const seoPagesResponse = SearchableTransactionStatuses.includes(status)
                ? await fetchAccountTransactionsFirst(status as TransactionStatuses, lastEvaluatedKey)
                : await fetchCustomerAccountsFirst(status, ascendingOrder, lastEvaluatedKey);

            if (lastEvaluatedKey) {
                dispatch(accountsSlice.actions.appendAccountPayload(seoPagesResponse.list));
            } else {
                dispatch(
                    accountsSlice.actions.setPayload({
                        store: 'list',
                        value: seoPagesResponse.list,
                    }),
                );
            }
            dispatch(accountsSlice.actions.setLastEvaluatedKey(seoPagesResponse.lastEvaluatedKey));
            dispatch(accountsSlice.actions.loadCompleted('list'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(accountsSlice.actions.loadFailed('list'));
        }
    };
}

export function searchB2BAccounts(search: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(accountsSlice.actions.startLoading('list'));
        try {
            let b2bAccount;
            if (search.includes('@')) {
                b2bAccount = await searchByEmail(search);
            }
            const account = await getAccountDetails(b2bAccount ? b2bAccount.accountId : search);
            const [{ addresses }, audit, [accountTransaction]] = await Promise.all([
                getAccountAddresses(account.accountId),
                getAccountAudit(account.accountId, AccountStatuses.REQUIRED),
                getAccountTransactionsByIds([account.accountId]),
            ]);
            dispatch(
                accountsSlice.actions.setPayload({
                    store: 'accountDetails',
                    value: {
                        isVisible: true,
                        content: {
                            account: {
                                ...account,
                                lastTransactionStatus: accountTransaction?.status || null,
                            },
                            address: addresses[0],
                            audit: audit,
                            fullAudit: [],
                        },
                    },
                }),
            );
            dispatch(accountsSlice.actions.loadCompleted('list'));
        } catch (error: any) {
            error.message = 'Customer has not been found';
            handleError(dispatch, error);
            dispatch(accountsSlice.actions.loadFailed('list'));
        }
    };
}

export function openAccountDetails(accountId: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(accountsSlice.actions.startLoading('accountDetails'));
        dispatch(
            accountsSlice.actions.setPayload({
                store: 'accountDetails',
                value: {
                    isVisible: true,
                    content: null,
                },
            }),
        );
        try {
            const [account, { addresses }, audit, [accountTransaction]] = await Promise.all([
                getAccountDetails(accountId),
                getAccountAddresses(accountId),
                getAccountAudit(accountId, AccountStatuses.REQUIRED),
                getAccountTransactionsByIds([accountId]),
            ]);
            dispatch(
                accountsSlice.actions.setPayload({
                    store: 'accountDetails',
                    value: {
                        isVisible: true,
                        content: {
                            account: {
                                ...account,
                                lastTransactionStatus: accountTransaction?.status || null,
                            },
                            address: addresses.find(theAddress => theAddress.isDefaultBillingAddress),
                            audit: audit,
                            fullAudit: [],
                        },
                    },
                }),
            );
            dispatch(accountsSlice.actions.loadCompleted('accountDetails'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(accountsSlice.actions.loadFailed('accountDetails'));
        }
    };
}

export function loadFullAudit(accountId: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(accountsSlice.actions.startLoading('accountDetails'));
        try {
            const fullAudit = await getAccountAudit(accountId);
            dispatch(accountsSlice.actions.setAccountDetailsFullAudit(fullAudit));
            dispatch(accountsSlice.actions.loadCompleted('accountDetails'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(accountsSlice.actions.loadFailed('accountDetails'));
        }
    };
}

export function closeAccountDetails() {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(
            accountsSlice.actions.setPayload({
                store: 'accountDetails',
                value: {
                    isVisible: false,
                    content: null,
                },
            }),
        );
    };
}

export function submitAccountStatus(accountId: string, status: AccountStatuses) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(accountsSlice.actions.startLoading('accountStatusSubmit'));
        try {
            switch (status) {
                case AccountStatuses.APPROVED:
                    await successfullyReviewAccount(accountId);
                    break;
                case AccountStatuses.BLOCKED:
                    await blockAccount(accountId);
                    break;
            }

            dispatch(
                accountsSlice.actions.setPayload({
                    store: 'accountDetails',
                    value: {
                        isVisible: false,
                        content: null,
                    },
                }),
            );
            dispatch(accountsSlice.actions.removeListItem(accountId));
            dispatch(accountsSlice.actions.loadCompleted('accountStatusSubmit'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(accountsSlice.actions.loadFailed('accountStatusSubmit'));
        }
    };
}
