import { TextField, TextFieldProps, TextFieldVariants } from '@mui/material';
import * as React from 'react';

export type FormikTextFieldProps = TextFieldProps & { formik: any; variant?: TextFieldVariants };

export const FormikTextField = ({
    formik,
    name,
    required,
    variant = 'standard',
    ...props
}: FormikTextFieldProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    return (
        <TextField
            name={name}
            variant={variant}
            value={formik.values[name]}
            error={!!formik.touched[name] && !!formik.errors[name]}
            helperText={!!formik.touched[name] && formik.errors[name]}
            onChange={formik.handleChange}
            {...props}
            InputLabelProps={{ shrink: true, required }}
        />
    );
};
