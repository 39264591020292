import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getTranslations } from '../../apis/translations';
import { ReduxAction } from '../../common/genericTypes';
import { getInitialRequestState, RequestState, RequestType } from '../../common/RequestState';

export type Translation = {
    key: string;
    value: string;
};

type TranslationsState = {
    list: RequestType<Translation[], Translation[]>;
};

type SetPayloadActionType<S extends keyof TranslationsState> = {
    store: S;
    value: TranslationsState[S]['payload'];
};

const initialState: TranslationsState = {
    list: getInitialRequestState([]),
} as const;

export const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {
        startLoading(state, action: ReduxAction<keyof TranslationsState>) {
            state[action.payload].requestState = RequestState.InProgress;
        },
        loadCompleted(state, action: ReduxAction<keyof TranslationsState>) {
            state[action.payload].requestState = RequestState.Finished;
        },
        loadFailed(state, action: ReduxAction<keyof TranslationsState>) {
            state[action.payload].requestState = RequestState.Failed;
        },
        setPayload<S extends keyof TranslationsState>(state, action: ReduxAction<SetPayloadActionType<S>>) {
            state[action.payload.store].payload = action.payload.value;
        },
    },
});

export function fetchTranslations(lang: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(
            translationsSlice.actions.setPayload({
                store: 'list',
                value: [],
            }),
        );
        dispatch(translationsSlice.actions.startLoading('list'));
        try {
            const translationsResponse = await getTranslations(lang);
            dispatch(
                translationsSlice.actions.setPayload({
                    store: 'list',
                    value: Object.keys(translationsResponse).map(key => ({ key, value: translationsResponse[key] })),
                }),
            );
            dispatch(translationsSlice.actions.loadCompleted('list'));
        } catch (error) {
            dispatch(translationsSlice.actions.loadFailed('list'));
        }
    };
}
