import { Box, Popper } from '@mui/material';
import Autocomplete, { autocompleteClasses, AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useMemo } from 'react';

type OptionType = { label: string; value: string };

type TypedOptionType = { label: string; value: string; inputValue?: string };

type AppendableAutocompleteProps = {
    value: string;
    onChange: (value: string) => void;
    options: Array<OptionType>;
    autocompleteProps?: Partial<AutocompleteProps<TypedOptionType, false, false, true>>;
    textFieldProps?: Partial<TextFieldProps & { 'data-cy': string }>;
};

const filter = createFilterOptions<OptionType>();

const PopperFitContent = props => <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;

// typescript can't handle this...
const MuiAutocomplete = Autocomplete as unknown as React.JSXElementConstructor<
    AutocompleteProps<TypedOptionType, false, false, true>
>;

// copy pasted from https://v4.mui.com/components/autocomplete/#creatable with modifications
export default function AppendableAutocomplete({
    value,
    onChange,
    options,
    autocompleteProps = {},
    textFieldProps = {},
}: AppendableAutocompleteProps): React.ReactElement {
    const valueOpt = useMemo(() => {
        if (!value) return null;
        const opt = options.find(o => o.value === value);
        return (
            opt || {
                label: value,
                value: value,
            }
        );
    }, [value, options]);

    return (
        <MuiAutocomplete
            value={valueOpt}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onChange(newValue);
                } else if (newValue && newValue.inputValue) {
                    // Created value from the user input
                    onChange(newValue.inputValue);
                } else {
                    onChange(newValue ? newValue.value : '');
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        value: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                    } as TypedOptionType);
                }

                return filtered;
            }}
            isOptionEqualToValue={option => option.value === value}
            PopperComponent={PopperFitContent}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Regular option
                return option.label;
            }}
            renderOption={(props, option, state, ownerState) => {
                const { key, ...optionProps } = props;
                return (
                    <Box
                        key={key}
                        sx={{
                            borderRadius: '8px',
                            margin: '5px',
                            [`&.${autocompleteClasses.option}`]: {
                                padding: '8px',
                            },
                        }}
                        component="li"
                        {...optionProps}
                    >
                        {ownerState.getOptionLabel(option)}
                    </Box>
                );
            }}
            freeSolo
            {...autocompleteProps}
            renderInput={params => <TextField {...params} {...textFieldProps} />}
        />
    );
}
