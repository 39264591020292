// eslint-disable @typescript-eslint/no-explicit-any
export type ResponseData = {
    statusCode: number;
    path: string;
    method: string;
};

export default class ResponseError extends Error {
    message: string;
    responseData: ResponseData;
    data: any;

    constructor(message: string, responseData: ResponseData, data: any = {}) {
        super(message);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ResponseError.prototype);

        this.message = message;
        this.data = data;
        this.responseData = responseData;
    }
}
