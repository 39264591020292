import { Vendor } from 'models/vendor';

export const SF_ALL_ROUTES_REQUESTED = 'SF_ALL_ROUTES_REQUESTED';
export const SF_ALL_ROUTES_RECEIVED = 'SF_ALL_ROUTES_RECEIVED';
export const SF_ALL_ROUTES_REQUEST_FAILED = 'SF_ALL_ROUTES_REQUEST_FAILED';

export const requestAllRoutes = (vendor: Vendor, forceCacheUpdate = false) => ({
    type: SF_ALL_ROUTES_REQUESTED,
    vendor,
    forceCacheUpdate,
});
export const receiveAllRoutes = (payload, vendor: Vendor) => ({ type: SF_ALL_ROUTES_RECEIVED, payload, vendor });
export const errorReceiveAllRoutes = requestAction => ({
    type: SF_ALL_ROUTES_REQUEST_FAILED,
    error: new Error('Failed to retrieve all the SFP Routes'),
    requestAction,
});
