import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import * as React from 'react';

export type FormikCheckboxProps = Omit<CheckboxProps, 'value' | 'onChange'> & {
    formik: any;
    name: string;
    label: string;
    cy?: string;
};

export const FormikCheckbox = ({ formik, name, cy, label, ...props }: FormikCheckboxProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    const onChange = (checked: boolean) => {
        formik.setFieldValue(name, checked);
    };

    return (
        <FormControl error={!!hasError}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formik.values[name]}
                        onChange={e => onChange(!!e.target.checked)}
                        color="primary"
                        data-cy={cy}
                        {...props}
                    />
                }
                label={label}
            />
            {hasError && (
                <FormHelperText error={hasError} data-cy={`${cy}-ErrorText`}>
                    {formik.errors[name]}
                </FormHelperText>
            )}
        </FormControl>
    );
};
