import { Link, Typography } from '@mui/material';
import * as React from 'react';
import { getAppConfig } from 'services/configService';

export type LookerLinkProps = {
    attributions: string[];
    disabled?: boolean;
};

export default function LookerLink({ attributions, disabled = false, ...props }: LookerLinkProps) {
    if (disabled) {
        return (
            <Typography variant="body2" component="span" color="textSecondary" data-cy="Bncl-LookerLinkDisabled">
                View results
            </Typography>
        );
    }

    const attrStr = attributions
        .map(attr => {
            return `"${attr}"`;
        })
        .join(',');

    const resultingUrl2 =
        getAppConfig().LookerUrl +
        `/dashboards/766?Redeemed+Reference+Date=&Discount+Name=${attrStr}&Discount+Campaign=%25bncl%25&Vendor=&Discount+channel=&Voucher+Sales+Date=`;

    return (
        <Link
            {...props}
            underline="always"
            target="_blank"
            rel="noopener"
            data-cy="Bncl-LookerLink"
            href={resultingUrl2}
        >
            View results
        </Link>
    );
}
