/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getArticleTypes } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { all, call, put, takeLeading } from 'redux-saga/effects';

export const ARTICLE_TYPES_REQUESTED = 'ARTICLE_TYPES_REQUESTED';
export const ARTICLE_TYPES_RECEIVED = 'ARTICLE_TYPES_RECEIVED';
export const ARTICLE_TYPES_REQUEST_FAILED = 'ARTICLE_TYPES_REQUEST_FAILED';

export const requestArticleTypes = () => ({
    type: ARTICLE_TYPES_REQUESTED,
});
export const receiveArticleTypes = payload => ({
    type: ARTICLE_TYPES_RECEIVED,
    payload,
});
export const errorArticleTypes = requestAction => ({
    type: ARTICLE_TYPES_REQUEST_FAILED,
    error: new Error('Failed to retrieve offer article types.'),
    requestAction,
});

export const getArticleTypesReducer = createGenericReducer(
    ARTICLE_TYPES_REQUESTED,
    ARTICLE_TYPES_RECEIVED,
    ARTICLE_TYPES_REQUEST_FAILED,
);

export function* doRequestArticleTypes(action, requestInfo: RequestInfo) {
    const articleTypes = yield call(getArticleTypes, {});
    yield put(receiveArticleTypes(articleTypes));
}

export function* watchArticleTypeStore() {
    yield all([takeLeading(ARTICLE_TYPES_REQUESTED, doCall(doRequestArticleTypes, ARTICLE_TYPES_REQUEST_FAILED))]);
}
