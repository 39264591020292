import { getConfig } from 'apis/backend';
import { doCall } from 'common/genericSaga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAppConfig, setAppConfig } from 'services/configService';
import { CONFIG_REQUESTED, CONFIG_REQUEST_FAILED, receiveConfig } from './configActions';

export function* doRequestConfig() {
    if (yield call(getAppConfig)) return;

    const configValues = yield call(getConfig);
    yield call(setAppConfig, configValues);
    yield put(receiveConfig());
}

export function* watchConfig() {
    yield takeLatest(CONFIG_REQUESTED, doCall(doRequestConfig, CONFIG_REQUEST_FAILED));
}
