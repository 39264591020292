import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export type RedirectsDto = {
    id: string;
    vendorName: string;
    routeFrom: string;
    routeTo: string;
    statusCode: number;
    lastUsed: string | null;
    lastModified: string;
};

export type UpdateRedirectDto = {
    routeFrom: string;
    routeTo: string;
    statusCode: number;
};

export type BulkCreateRedirectDto = {
    id: number;
} & UpdateRedirectDto;

export const VALIDATION_ERROR_TYPE = {
    EXIST: 'redirect-already-exists',
    LOOP: 'redirect-loop',
    CHAIN: 'multiple-redirect-chain',
    SAME_ORIGIN: 'redirect-same-origin',
    BASIC_VALIDATION: 'basic-validation',
} as const;

type BaseRedirectSuggestionDto = {
    type: (typeof VALIDATION_ERROR_TYPE)[keyof typeof VALIDATION_ERROR_TYPE];
    message: string;
};

export type RedirectLocalSuggestionDto = BaseRedirectSuggestionDto & {
    delete?: BulkCreateRedirectDto[];
    create?: BulkCreateRedirectDto[];
};

export type RedirectExecutiveSuggestionDto = BaseRedirectSuggestionDto & {
    delete?: RedirectsDto[];
    create?: UpdateRedirectDto[];
    update?: Array<{ target: RedirectsDto; change: UpdateRedirectDto }>;
};

export type RedirectValidationErrorDto<SRC extends UpdateRedirectDto = UpdateRedirectDto> = {
    src: SRC;
    executiveSuggestions?: RedirectExecutiveSuggestionDto[];
    localSuggestions?: RedirectLocalSuggestionDto[];
};

export type ApplySuggestionResultDto = {
    created: RedirectsDto[];
    updated: RedirectsDto[];
    deleted: RedirectsDto[];
};

export const getVendorRedirects = async (vendorName: string): Promise<RedirectsDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'GET', `redirects/${vendorName}`, null, {}, token);
};

export const updateRedirect = async (
    vendorName: string,
    id: string,
    redirect: UpdateRedirectDto,
): Promise<RedirectsDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'PATCH', `redirects/${vendorName}/redirect/${id}`, redirect, {}, token);
};

export const applySuggestion = async (
    vendorName: string,
    suggestion: RedirectExecutiveSuggestionDto,
): Promise<ApplySuggestionResultDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'PATCH', `redirects/${vendorName}/suggestion`, suggestion, {}, token);
};

export const createRedirect = async (vendorName: string, redirect: UpdateRedirectDto): Promise<RedirectsDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'POST', `redirects/${vendorName}`, redirect, {}, token);
};

export const createBulkRedirects = async (
    vendorName: string,
    redirects: BulkCreateRedirectDto[],
): Promise<RedirectsDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'POST', `redirects/${vendorName}/bulk`, redirects, {}, token);
};

export const deleteRedirect = async (vendorName: string, id: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi('RedirectApiUrl', 'DELETE', `redirects/${vendorName}/${id}`, null, {}, token);
};
