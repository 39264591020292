import { Media } from 'models/media';
import { Translation, TranslationBriefType, TranslationType } from 'models/translation';
import { Vendor } from './vendor';

export type MerchandisingSlot = {
    id: number | null;
    campaign_id: number;
    position: string;
    media: { data: Array<Media> };
    vendor: { data: Array<Vendor> };
    translation: Translation;
    vendor_id: number;
    has_countdown: boolean;
    countdown_main_title: string;
    countdown_sub_title: string;
    countdown_color: string;
    countdown_date: string;
};

export enum MerchandisingSlotTypes {
    media = 'media',
    mediaTile = 'mediaTile',
    text = 'text',
}

export type MerchandisingSlotPosition = {
    id: string;
    label: string;
    countdown_compatible: boolean;
    related_article_type?: string;
    type: MerchandisingSlotTypes;
};

export enum CountDownMerchandisingSlotTypes {
    brief = 'brief',
    full = 'full',
}

export enum MERCHANDISING_SLOT_POSITION {
    website_homepage_banner = 'website_homepage_banner',
}

export const hasTranslation = (
    merchandisingSlot: MerchandisingSlot,
    allowEmptyExistingTranslations = false,
): boolean => {
    let has = true;
    //if it has translation text or en existant translation id
    if (merchandisingSlot.translation && merchandisingSlot.translation.data) {
        has = true;
    } else {
        has = false;
    }

    //instanceof TranslationBriefType
    if (merchandisingSlot.translation.data && 'translation' in merchandisingSlot.translation.data) {
        has = !!(
            (merchandisingSlot.translation.data as TranslationBriefType).locale &&
            (merchandisingSlot.translation.data as TranslationBriefType).translation &&
            (merchandisingSlot.translation.data as TranslationBriefType).translation.trim() !== ''
        );
    }
    //instanceof TranslationType
    if (merchandisingSlot.translation.data && 'custom_translation' in merchandisingSlot.translation.data) {
        has = !!(
            (merchandisingSlot.translation.data as TranslationType).custom_translation &&
            (merchandisingSlot.translation.data as TranslationType).custom_translation?.data &&
            (merchandisingSlot.translation.data as TranslationType).custom_translation?.data.locale &&
            (merchandisingSlot.translation.data as TranslationType).custom_translation?.data.custom_text &&
            (merchandisingSlot.translation.data as TranslationType).custom_translation?.data.custom_text?.trim() !== ''
        );
    }
    if (allowEmptyExistingTranslations && !has) {
        has = merchandisingSlot.translation.data?.id !== null;
    }
    return has;
};
