/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getCampaign, getCampaignChannels } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { all, call, put, takeLatest } from 'redux-saga/effects';

//Types
export const CAMPAIGN_REQUESTED = 'CAMPAIGN_REQUESTED';
export const CAMPAIGN_RECEIVED = 'CAMPAIGN_RECEIVED';
export const CAMPAIGN_REQUEST_FAILED = 'CAMPAIGN_REQUEST_FAILED';
export const CAMPAIGN_REQUEST_RESET = 'CAMPAIGN_REQUEST_RESET';

//Actions
export const requestCampaign = id => ({
    type: CAMPAIGN_REQUESTED,
    id,
});

export const receiveCampaign = (payload, channelConfiguration) => ({
    type: CAMPAIGN_RECEIVED,
    payload,
    channelConfiguration,
});

export const errorCampaignRequest = requestAction => ({
    type: CAMPAIGN_REQUEST_FAILED,
    error: new Error('Failed to retrieve the Campaign'),
    requestAction,
});

export const resetCampaignRequest = () => ({
    type: CAMPAIGN_REQUEST_RESET,
});

//Reducer
export const getCampaignReducer = createGenericReducer(
    CAMPAIGN_REQUESTED,
    CAMPAIGN_RECEIVED,
    CAMPAIGN_REQUEST_FAILED,
    CAMPAIGN_REQUEST_RESET,
);

//Saga
export function* doRequestCampaign(action, requestInfo: RequestInfo) {
    const campaign = yield call(getCampaign, action.id);

    //get channel configuration
    const channelsConfiguration = yield call(getCampaignChannels);
    const channelConfiguration = channelsConfiguration.data[campaign.data.channel];

    yield put(receiveCampaign(campaign, channelConfiguration));
}

export function* watchCampaignStore() {
    yield all([takeLatest(CAMPAIGN_REQUESTED, doCall(doRequestCampaign, CAMPAIGN_REQUEST_FAILED))]);
}
