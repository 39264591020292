import 'polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';
import App from './components/common/App/App';
import './index.css';

activeDirectoryServiceInstance.login(() => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(<App />);
});
