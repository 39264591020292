import _ from 'lodash';

export function capitalize(str): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const PATH = '(\\/[-a-z\\d%_.~+]*)';
const QUERY_STRING = '(\\?[;&a-z\\d%_.~+=-]*)?';
const HASH = '(\\#[-a-z\\d_]*)?';

export function isValidURL(str: string): boolean {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // port
            PATH +
            '*' +
            QUERY_STRING +
            HASH +
            '$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
}

export function isValidPath(str: string): boolean {
    const pattern = new RegExp(`^${PATH}+${QUERY_STRING}${HASH}$`, 'i');
    return !!pattern.test(str);
}

export const sortAlphabetically = (objects: Record<string, any>, propertyName: string) => {
    return objects.sort((a, b) => (a[propertyName] > b[propertyName] ? 1 : -1));
};

export function beautifyIdentifier(str: string): string {
    const match = str.match(/[a-z0-9]+/gi);
    if (!match) return str;
    return match.map(capitalize).join(' ');
}

export function beautifyBreadcrumbDefaultName(path: string): string {
    const str = _.findLast(path.split('/'), Boolean) || '';
    if (!str && path) return 'Home';
    const match = str.match(/[a-z0-9]+/gi);
    if (!match) return str;
    return match.map((txt, idx) => (idx === 0 ? capitalize(txt) : txt)).join(' ');
}
