import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    navLink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        display: 'flex',
        height: '100%',
        '&:hover': {
            background: CustomColors.navHover,
        },
        padding: '0 10px 0',
    },
    navLinkSelected: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
    },
    button: {
        textTransform: 'capitalize',
    },
}));

export default function Nav(): React.ReactElement {
    const { classes } = useStyles();
    return (
        <Box display="flex" flexWrap="wrap" alignContent="center">
            <NavLink to={routes.campaigns} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="campaigns">
                    <Typography variant="body1" className={classes.button}>
                        Campaign Manager
                    </Typography>
                </Button>
            </NavLink>
            <NavLink to={routes.bnclCampaigns} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="vouchers">
                    <Typography variant="body1" className={classes.button}>
                        Vouchers
                    </Typography>
                </Button>
            </NavLink>
            <NavLink to={routes.discounts} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="discounts">
                    <Typography variant="body1" className={classes.button}>
                        Discounts
                    </Typography>
                </Button>
            </NavLink>
            <NavLink to={routes.seo} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="seo">
                    <Typography variant="body1" className={classes.button}>
                        SEO
                    </Typography>
                </Button>
            </NavLink>
            <NavLink to={routes.b2b} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="b2b">
                    <Typography variant="body1" className={classes.button}>
                        B2B
                    </Typography>
                </Button>
            </NavLink>
            <NavLink to={routes.lod} activeClassName={classes.navLinkSelected} className={classes.navLink}>
                <Button color="inherit" data-cy="lod">
                    <Typography variant="body1" className={classes.button}>
                        LOD
                    </Typography>
                </Button>
            </NavLink>
        </Box>
    );
}
