import { IconButton, IconButtonProps, PropTypes, Theme } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react/types';

type ExtraProps = {
    variant?: 'contained' | 'outlined' | 'text';
    color?: PropTypes.Color | 'error' | 'success';
};

type ColoredIconButtonType = {
    children?: any;
    className?: string;
} & ExtraProps &
    Omit<IconButtonProps, 'color'>;

const useStyles = makeStyles<ExtraProps>()((theme: Theme, { color, variant }) => {
    const button = {} as CSSObject;

    const paletteType = color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color;
    const palette = theme.palette[paletteType];

    if (variant === 'contained') {
        button.backgroundColor = palette.main;
        button.color = palette.contrastText;
        button['&:hover'] = {
            backgroundColor: palette.dark,
        };
    }

    if (variant === 'outlined') {
        button.border = `1px solid ${palette.main}`;
        button.color = palette.main;
        button['&[disabled]'] = {
            borderColor: theme.palette.grey[400],
            cursor: 'default',
        };
    }

    return { button } as Record<'button', CSSObject>;
});

const extraColors = ['error', 'success'];

export default function ColoredIconButton({
    variant,
    color,
    children,
    className = '',
    ...props
}: ColoredIconButtonType): React.ReactElement {
    const { classes } = useStyles({ variant, color });

    return (
        <IconButton
            className={`${classes.button} ${className}`}
            color={extraColors.includes(color as string) ? undefined : (color as PropTypes.Color)}
            {...props}
        >
            {children}
        </IconButton>
    );
}
