import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export enum AccountStatuses {
    REQUIRED = 'Required',
    APPROVED = 'Approved',
    BLOCKED = 'Blocked',
    DELETED = 'Deleted',
}

export enum TransactionStatuses {
    PENDING = 'pending',
    FAILED = 'failed',
    COMPLETED = 'completed',
}

export const SearchableTransactionStatuses: string[] = [TransactionStatuses.PENDING, TransactionStatuses.FAILED];

export type Account = {
    accountId: string;
    email: string;
    status: string;
    reviewStatus: string;
    reviewRequestedDate: string;
};

export type AccountsList = {
    profiles: Account[];
    lastEvaluatedKey: string;
};

export type AccountDetailsDto = {
    accountId: string;
    email: string;
    customerNumber?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    title?: string;
    salutation?: string;
    gender?: string;
    externalId?: string;
    dateOfBirth?: string;
    companyName?: string;
    vatId: string;
    createdAt: string;
    lastModifiedAt: string;
    paymentMethod: string;
    status: string;
    reviewStatus: string;
    reviewRequestedDate: string;
};

export type AccountAddressDto = {
    id: string;
    salutationId: number;
    title: string;
    salutation: string;
    firstName: string;
    middleName: string;
    lastName: string;
    streetName?: string;
    streetNumber?: string;
    frenchAddress?: string;
    complement1?: string;
    complement2?: string;
    lieuDit?: string;
    address1?: string; // not sure they are here
    address2?: string; // not sure they are here
    additionalStreetInfo: string;
    postalCode: string;
    city: string;
    region: string;
    state: string;
    country: string;
    company: string;
    department: string;
    building: string;
    apartment: string;
    poBox: string;
    phone: string;
    mobile: string;
    isBillingAddress: boolean;
    isDefaultBillingAddress: boolean;
    isShippingAddress: boolean;
    isDefaultShippingAddress: boolean;
    profileAddressId: number;
    createdAt: string;
    lastModifiedAt: string;
};

export type AccountAuditDto = {
    accountId: string;
    typeId: string;
    createdAt: string;
    isDefaultBillingAddress: boolean;
    isDefaultShippingAddress: boolean;
    reviewStatus: string;
    changedFields: Record<string, string>;
    changedBy: string;
};

export type AccountTransactionDto = {
    accountId: string;
    status: string;
    transactionType: string;
};

export type PaginatedAccountTransactionsDto = {
    list: AccountTransactionDto[];
    lastEvaluatedKey?: string;
};

export const getB2BAccounts = async (
    params: { status: string; ascendingOrder: boolean },
    lastEvaluatedKey?: string,
): Promise<AccountsList> => {
    const token = await oidc.getAccessToken();
    const queryParams = {
        ascendingOrder: params.ascendingOrder.toString(),
        pageSize: '20',
    };
    if (lastEvaluatedKey) queryParams['lastEvaluationKey'] = lastEvaluatedKey;
    const route = `review/${params.status}?${new URLSearchParams(queryParams).toString()}`;
    return await callApi('CustomersApiUrl', 'GET', route, null, {}, token);
};

export const getAccountDetails = async (accountId: string): Promise<AccountDetailsDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('CustomersApiUrl', 'GET', `customers/${accountId}`, null, {}, token);
};

export const getAccountAddresses = async (accountId: string): Promise<{ addresses: AccountAddressDto[] }> => {
    const token = await oidc.getAccessToken();
    return await callApi('CustomersApiUrl', 'GET', `customers/${accountId}/addresses`, null, {}, token);
};

export const getAccountAudit = async (
    accountId: string,
    reviewStatus?: AccountStatuses,
): Promise<AccountAuditDto[]> => {
    const token = await oidc.getAccessToken();
    const result = await callApi(
        'CustomersApiUrl',
        'GET',
        `/review/${accountId}/audits?reviewStatus=${reviewStatus || ''}`,
        null,
        {},
        token,
    );

    return result.map(a => ({ ...a, changedFields: JSON.parse(a.changedFields || '{}') }));
};

export const successfullyReviewAccount = async (accountId: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi(
        'CustomersApiUrl',
        'PATCH',
        `review/${accountId}/status`,
        {
            reviewStatus: AccountStatuses.APPROVED,
        },
        {},
        token,
    );
};

export const blockAccount = async (accountId: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi(
        'CustomersApiUrl',
        'PATCH',
        `review/${accountId}/status`,
        {
            reviewStatus: AccountStatuses.BLOCKED,
            status: AccountStatuses.BLOCKED,
        },
        {},
        token,
    );
};

export const searchByEmail = async (email: string): Promise<AccountDetailsDto> => {
    const token = await oidc.getAccessToken();

    return await callApi('AuthAccountApiUrl', 'GET', `accounts/search/email/${email}`, null, {}, token);
};

export const getAccountTransactionsByStatus = async (
    params: { status: TransactionStatuses },
    lastEvaluatedKey?: string,
): Promise<PaginatedAccountTransactionsDto> => {
    const token = await oidc.getAccessToken();
    const queryParams = {};
    if (lastEvaluatedKey) queryParams['lastEvaluationKey'] = lastEvaluatedKey;
    const route = `account-transactions/status/${params.status}?${new URLSearchParams(queryParams).toString()}`;
    return await callApi('B2bOracleProfileApiUrl', 'GET', route, null, {}, token);
};

export const getAccountTransactionsByIds = async (ids: string[]): Promise<AccountTransactionDto[]> => {
    const token = await oidc.getAccessToken();
    const queryParams = {
        accountIds: ids.join(','),
    };
    const route = `account-transactions?${new URLSearchParams(queryParams).toString()}`;
    return await callApi('B2bOracleProfileApiUrl', 'GET', route, null, {}, token);
};
