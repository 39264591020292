import { InputAdornment, TextField } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';

type CheckoutTextFieldType = {
    formik: any;
    disabled: boolean;
    prefix: string;
};

export default function CheckoutTextField({ formik, disabled, prefix }: CheckoutTextFieldType): React.ReactElement {
    const name = 'checkoutLabel';
    const value = formik.values[name];
    const userInput = useMemo(() => {
        return value.replace(prefix, '');
    }, [value]);

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value;
        formik.setFieldValue(name, `${prefix}${val}`);
    };

    return (
        <TextField
            name={name}
            value={userInput}
            error={!!formik.touched[name] && !!formik.errors[name]}
            helperText={!!formik.touched[name] && formik.errors[name]}
            onChange={onChange}
            label="Checkout label (shown in Basket and My Account)"
            fullWidth
            required
            disabled={disabled}
            InputLabelProps={{ shrink: true, required: true }}
            InputProps={{
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
            }}
            data-cy="Bncl-OfferForm-CheckoutLabelInput"
        />
    );
}
