import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
    applySuggestion,
    ApplySuggestionResultDto,
    BulkCreateRedirectDto,
    createBulkRedirects,
    createRedirect,
    deleteRedirect,
    getVendorRedirects,
    RedirectExecutiveSuggestionDto,
    RedirectLocalSuggestionDto,
    RedirectsDto,
    RedirectValidationErrorDto,
    updateRedirect,
    UpdateRedirectDto,
} from 'apis/redirects';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { ReduxAction } from 'common/genericTypes';
import { getInitialRequestState, RequestState, RequestType } from 'common/RequestState';

export interface RedirectUpdate {
    vendorName: string;
    id: string;
    redirect: UpdateRedirectDto;
}

type SuggestionError = {
    isVisible: boolean;
    submitErrors: null | RedirectValidationErrorDto[];
} & (
    | {
          requestPlan: null;
          suggestion: null;
      }
    | {
          requestPlan:
              | { type: 'create'; data: UpdateRedirectDto; src?: void }
              | { type: 'update'; data: UpdateRedirectDto; src: RedirectsDto };
          suggestion: RedirectValidationErrorDto;
      }
);

export type CreateBulkError = {
    hasError: boolean;
    isCommitted: boolean;
} & RedirectValidationErrorDto<BulkCreateRedirectDto>;

type RedirectsState = {
    list: RequestType<RedirectsDto[], RedirectsDto[]>;
    create: RequestType<void, void>;
    createBulk: RequestType<CreateBulkError[], CreateBulkError[]>;
    update: RequestType<string, string>;
    delete: RequestType<string, string>;
    suggestionError: RequestType<SuggestionError, SuggestionError>;
};

type SetPayloadActionType<S extends keyof RedirectsState> = {
    store: S;
    value: RedirectsState[S]['payload'];
};

const handleError = (dispatch: Dispatch, error: any) => {
    if (error && typeof error.message === 'string') {
        dispatch(showStatusMessage(error.message, 'error'));
    }
    if (error && Array.isArray(error.message) && error.message.every(e => typeof e === 'string')) {
        // nestjs class-validator errors
        dispatch(showStatusMessage(['Validation Error', ...error.message].join('\n'), 'error'));
    }
};

const initialState: RedirectsState = {
    list: getInitialRequestState([]),
    create: getInitialRequestState(),
    createBulk: getInitialRequestState([]),
    update: getInitialRequestState(''),
    delete: getInitialRequestState(''),
    suggestionError: getInitialRequestState({
        isVisible: false,
        requestPlan: null,
        suggestion: null,
        submitErrors: null,
    }),
} as const;

export const redirectSlice = createSlice({
    name: 'redirect',
    initialState,
    reducers: {
        startLoading(state, action: ReduxAction<keyof RedirectsState>) {
            state[action.payload].requestState = RequestState.InProgress;
        },
        loadCompleted(state, action: ReduxAction<keyof RedirectsState>) {
            state[action.payload].requestState = RequestState.Finished;
        },
        loadFailed(state, action: ReduxAction<keyof RedirectsState>) {
            state[action.payload].requestState = RequestState.Failed;
        },
        setPayload<S extends keyof RedirectsState>(state, action: ReduxAction<SetPayloadActionType<S>>) {
            state[action.payload.store].payload = action.payload.value;
        },
        updateListItem(state, action: ReduxAction<RedirectsDto>) {
            state.list.payload = state.list.payload.map(row => (row.id === action.payload.id ? action.payload : row));
        },
        removeListItem(state, action: ReduxAction<string>) {
            state.list.payload = state.list.payload.filter(row => row.id !== action.payload);
        },
        prependList(state, action: ReduxAction<RedirectsDto[]>) {
            state.list.payload = [...action.payload, ...state.list.payload];
        },
        setSuggestionResult(state, action: ReduxAction<ApplySuggestionResultDto>) {
            const toRemove = [...action.payload.updated, ...action.payload.deleted].reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: true,
                }),
                {} as Record<string, boolean>,
            );
            const clearedList = state.list.payload.filter(item => !toRemove[item.id]);
            state.list.payload = [...action.payload.created, ...action.payload.updated, ...clearedList];
        },
        closeSuggestionModal(state) {
            state.suggestionError.payload.isVisible = false;
        },
        applyLocalSuggestion(state, action: ReduxAction<{ id: number; suggestion: RedirectLocalSuggestionDto }>) {
            const { id, suggestion } = action.payload;
            state.createBulk.payload = state.createBulk.payload.map(row => {
                if (row.src.id === id) {
                    delete row.localSuggestions;
                    if (suggestion.create && suggestion.create[0]) {
                        row.src = { ...row.src, ...suggestion.create[0] };
                    }
                }
                return row;
            });
            const idMapToRemove = (suggestion.delete || []).reduce(
                (acc, item) => ({ ...acc, [item.id]: true }),
                {} as Record<string, boolean>,
            );
            state.createBulk.payload = state.createBulk.payload.filter(row => !idMapToRemove[row.src.id]);
        },
        updateBulkErrorItem(state, action: ReduxAction<{ id: number; data: UpdateRedirectDto }>) {
            state.createBulk.payload = state.createBulk.payload.map(row => {
                if (row.src.id === action.payload.id) {
                    row.src = { ...row.src, ...action.payload.data };
                }
                return row;
            });
        },
        deleteBulkErrorItem(state, action: ReduxAction<number>) {
            state.createBulk.payload = state.createBulk.payload.filter(row => row.src.id !== action.payload);
        },
        markBulkErrorItemAsCommitted(state, action: ReduxAction<number>) {
            state.createBulk.payload = state.createBulk.payload.map(row => {
                if (row.src.id === action.payload) {
                    row.isCommitted = true;
                }
                return row;
            });
        },
        deleteBulkErrorsByType(state, action: ReduxAction<string>) {
            state.createBulk.payload = state.createBulk.payload.filter(row => {
                if (row.localSuggestions) {
                    return !row.localSuggestions.find(ls => ls.type === action.payload);
                }
                if (row.executiveSuggestions) {
                    return !row.executiveSuggestions.find(ls => ls.type === action.payload);
                }
                return true;
            });
        },
    },
});

export function fetchVendorRedirects(vendorName: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('list'));
        try {
            const redirectResponse = await getVendorRedirects(vendorName);
            dispatch(
                redirectSlice.actions.setPayload({
                    store: 'list',
                    value: redirectResponse,
                }),
            );
            dispatch(
                redirectSlice.actions.setPayload({
                    store: 'createBulk',
                    value: [],
                }),
            );
            dispatch(redirectSlice.actions.loadCompleted('list'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('list'));
        }
    };
}

export function createVendorRedirect(vendorName: string, redirect: UpdateRedirectDto) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('create'));
        try {
            const createResult = await createRedirect(vendorName, redirect);
            dispatch(redirectSlice.actions.prependList([createResult]));
            dispatch(redirectSlice.actions.loadCompleted('create'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('create'));

            const maybeSuggestion = (error as any).message as RedirectValidationErrorDto;
            if (maybeSuggestion && maybeSuggestion.src && maybeSuggestion.executiveSuggestions) {
                dispatch(
                    redirectSlice.actions.setPayload({
                        store: 'suggestionError',
                        value: {
                            requestPlan: { type: 'create', data: redirect },
                            isVisible: true,
                            suggestion: maybeSuggestion,
                            submitErrors: null,
                        },
                    }),
                );
            }
        }
    };
}

export function createVendorBulkRedirects(vendorName: string, redirects: BulkCreateRedirectDto[]) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('createBulk'));
        try {
            const createResults = await createBulkRedirects(vendorName, redirects);
            dispatch(redirectSlice.actions.prependList(createResults));
            dispatch(redirectSlice.actions.loadCompleted('createBulk'));
            dispatch(
                redirectSlice.actions.setPayload({
                    store: 'createBulk',
                    value: [],
                }),
            );
            dispatch(showStatusMessage('Successfully Imported', 'success'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('createBulk'));

            const maybeSuggestions = (error as any).message as RedirectValidationErrorDto<BulkCreateRedirectDto>[];
            if (Array.isArray(maybeSuggestions) && maybeSuggestions[0] && maybeSuggestions[0].src) {
                dispatch(showStatusMessage('Import Failed. Please resolve all issues', 'error'));
                const result = redirects.map(item => {
                    const error = maybeSuggestions.find(suggestion => suggestion.src.id === item.id);
                    return {
                        ...(error || { src: item }),
                        hasError: !!error,
                        isCommitted: false,
                    };
                });
                dispatch(
                    redirectSlice.actions.setPayload({
                        store: 'createBulk',
                        value: result,
                    }),
                );
            }
        }
    };
}

export function updateVendorRedirect(vendorName: string, srcRedirect: RedirectsDto, redirect: UpdateRedirectDto) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('update'));
        dispatch(
            redirectSlice.actions.setPayload({
                store: 'update',
                value: srcRedirect.id,
            }),
        );
        try {
            const updateResult = await updateRedirect(vendorName, srcRedirect.id, redirect);
            dispatch(redirectSlice.actions.updateListItem(updateResult));
            dispatch(redirectSlice.actions.loadCompleted('update'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('update'));

            const maybeSuggestion = (error as any).message as RedirectValidationErrorDto;
            if (maybeSuggestion && maybeSuggestion.src && maybeSuggestion.executiveSuggestions) {
                dispatch(
                    redirectSlice.actions.setPayload({
                        store: 'suggestionError',
                        value: {
                            requestPlan: { type: 'update', data: redirect, src: srcRedirect },
                            isVisible: true,
                            suggestion: maybeSuggestion,
                            submitErrors: null,
                        },
                    }),
                );
            }
        }
    };
}

export function deleteVendorRedirect(vendorName: string, id: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('delete'));
        dispatch(
            redirectSlice.actions.setPayload({
                store: 'delete',
                value: id,
            }),
        );
        try {
            await deleteRedirect(vendorName, id);
            dispatch(redirectSlice.actions.removeListItem(id));
            dispatch(redirectSlice.actions.loadCompleted('delete'));
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('delete'));
        }
    };
}

export function applyRedirectSuggestion(
    vendorName: string,
    suggestion: RedirectExecutiveSuggestionDto,
    bulkItemId?: number,
) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.startLoading('suggestionError'));
        try {
            const result = await applySuggestion(vendorName, suggestion);
            dispatch(redirectSlice.actions.setSuggestionResult(result));
            dispatch(redirectSlice.actions.loadCompleted('suggestionError'));
            dispatch(redirectSlice.actions.closeSuggestionModal());
            if (typeof bulkItemId === 'number') {
                dispatch(redirectSlice.actions.markBulkErrorItemAsCommitted(bulkItemId));
            }
        } catch (error) {
            handleError(dispatch, error);
            dispatch(redirectSlice.actions.loadFailed('suggestionError'));
            const maybeMoreSuggestions = (error as any).message as RedirectValidationErrorDto[];

            if (Array.isArray(maybeMoreSuggestions) && maybeMoreSuggestions[0] && maybeMoreSuggestions[0].src) {
                dispatch(
                    redirectSlice.actions.setPayload({
                        store: 'suggestionError',
                        value: {
                            requestPlan: null,
                            isVisible: true,
                            suggestion: null,
                            submitErrors: maybeMoreSuggestions,
                        },
                    }),
                );
            }
        }
    };
}

export function closeSuggestionModal() {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.closeSuggestionModal());
    };
}

export function applyLocalSuggestion(redirect: CreateBulkError, suggestion: RedirectLocalSuggestionDto) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.applyLocalSuggestion({ id: redirect.src.id, suggestion }));
    };
}

export function updateBulkErrorItem(id: number, data: UpdateRedirectDto) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.updateBulkErrorItem({ id, data }));
    };
}

export function deleteBulkErrorItem(id: number) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.deleteBulkErrorItem(id));
    };
}

export function deleteBulkErrorsByType(type: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(redirectSlice.actions.deleteBulkErrorsByType(type));
    };
}
