import { DiscountsEditor } from '@albelli/ecom-promotions-editor';
import { fetchCatalog } from 'application/discounts/promotionCatalogSlice';
import { isFinished, isNotRequested } from 'helpers/requestStateHelper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';

export interface DiscountsPanelState {
    code?: string;
    onCodeChanged: any;
    disabled?: boolean;
    height?: string;
}

export const DiscountEditor = ({
    code,
    onCodeChanged,
    disabled,
    height = '50vh',
}: DiscountsPanelState): React.ReactElement => {
    const dispatch = useDispatch();
    const catalog = useSelector(state => state.promotionCatalog);

    useEffect(() => {
        if (isNotRequested(catalog)) dispatch(fetchCatalog());
    }, []);
    if (isFinished(catalog))
        return (
            <div data-cy="DiscountEditor-Wrapper">
                <DiscountsEditor
                    height={height}
                    code={code}
                    catalog={catalog.payload}
                    onCodeChanged={onCodeChanged}
                    options={{
                        minimap: { enabled: false },
                        lineNumbers: 'off',
                        cursorStyle: 'line',
                        scrollbar: { vertical: 'hidden' },
                        readOnly: disabled,
                    }}
                />
            </div>
        );
    else return <div />;
};
