import {
    createPromotion,
    createPromotionOffer,
    deletePromotion,
    getPromotion,
    getPromotionDefaultMedia,
    updatePromotion,
    uploadImage,
} from 'apis/campaignApi';
import { submitMedia } from 'application/campaignForm/merchandisingSlotCreate/merchandisingSlotCreateSaga';
import { doCall } from 'common/genericSaga';
import { CampaignSubmissionData } from 'common/genericTypes';
import { WalldecorGroup } from 'components/campaigns/LandingPages/DedicatedLandingPageForm';
import ResponseError from 'errors/ResponseError';
import { partition } from 'helpers/partitionHelper';
import { LandingPage } from 'models/landingPage';
import { Media, MediaTypes, MediaVisibility } from 'models/media';
import { Promotion } from 'models/promotion';
import { PromotionOffer } from 'models/promotionOffer';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    errorSubmittedPromotions,
    receivedPromotionCreation,
    receivedPromotionOfferCreation,
    receivePromotion,
    receivePromotionDefaultMedia,
    receiveSubmittedPromotions,
} from './promotionCreateActions';
import {
    PROMOTIONS_SUBMIT_FAILED,
    PROMOTIONS_SUBMIT_REQUESTED,
    PROMOTION_CREATE_FAILED,
    PROMOTION_CREATE_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED,
    PROMOTION_OFFER_CREATE_FAILED,
    PROMOTION_OFFER_CREATE_REQUESTED,
    PROMOTION_REQUESTED,
    PROMOTION_REQUEST_FAILED,
} from './promotionCreateTypes';
import _ from 'lodash';

export function* doRequestPromotion(action, requestInfo) {
    const landingPage = yield call(getPromotion, action.id);
    yield put(receivePromotion(landingPage));
}

function* submitPromotion(promotion: Promotion) {
    let createdPromotion = yield call(!!promotion.id ? updatePromotion : createPromotion, promotion);

    if (promotion.promotion_offers && promotion.promotion_offers.data.length) {
        const formatPromoOffers = promotion.promotion_offers.data.map(promoOffer => {
            return { ...promoOffer };
        });
        yield call(createPromotionOffer, createdPromotion.data.id, { offers: formatPromoOffers });

        createdPromotion = yield call(getPromotion, createdPromotion.data.id);
    }

    return createdPromotion;
}

export function* doSubmitPromotion(action: { promotion: Promotion }, requestInfo) {
    const createdPromotion = yield call(submitPromotion, action.promotion);
    yield put(receivedPromotionCreation(createdPromotion));
}

export function* doUploadImageFile(file: File) {
    const form = new FormData();
    form.append('image', file);

    const res = yield call(uploadImage, form);
    return res.url;
}

export function* doSubmitPromotions(action: { promotions: CampaignSubmissionData<Promotion> }, requestInfo) {
    const promotions: { data: Array<Promotion> } = { data: [] };
    for (const itemsAction of ['itemsToCreate', 'itemsToUpdate']) {
        if (action.promotions[itemsAction]) {
            for (const promotionObject of action.promotions[itemsAction]) {
                try {
                    const createdPromotion = yield call(submitPromotion, promotionObject);

                    //check if the promotion has media
                    if (promotionObject.media && promotionObject.media.data) {
                        const mediaData: Partial<Media> = promotionObject.media.data[0];
                        if (!mediaData.id && !mediaData.asset_file && !mediaData.asset_path) {
                            throw new Error('Missing Asset path');
                        }

                        if (mediaData.asset_file) {
                            mediaData.asset_path = yield call(doUploadImageFile, promotionObject.asset_file);
                        }

                        mediaData.promotions = [createdPromotion.data.id];

                        yield call(submitMedia, mediaData);
                    }
                    const promotionWithMedia = yield call(getPromotion, createdPromotion.data.id);
                    promotions.data.push(promotionWithMedia);
                } catch (error) {
                    if (error instanceof ResponseError) {
                        yield put(errorSubmittedPromotions(error, requestInfo.correlationId));
                        return;
                    }

                    throw error;
                }
            }
        }
    }

    if (action.promotions.itemsToDelete.length) {
        for (const promotionObject of action.promotions.itemsToDelete) {
            yield call(deletePromotion, promotionObject.id);
        }
    }

    yield put(receiveSubmittedPromotions(promotions));
}

export function* doSubmitPromotionOffer(action: { promotionId: number; promotionOffer: PromotionOffer }, requestInfo) {
    const createdPromotionOffer = yield call(createPromotionOffer, action.promotionId, action.promotionOffer);
    yield put(receivedPromotionOfferCreation(createdPromotionOffer.data));
}

export function* doRequestDefaultMedia(action, requestInfo) {
    const defaultMedia = yield call(getPromotionDefaultMedia);
    yield put(receivePromotionDefaultMedia(defaultMedia));
}

export function* watchPromotionCreationConfig() {
    yield all([
        takeLatest(PROMOTION_REQUESTED, doCall(doRequestPromotion, PROMOTION_REQUEST_FAILED)),
        takeEvery(PROMOTION_CREATE_REQUESTED, doCall(doSubmitPromotion, PROMOTION_CREATE_FAILED)),
        takeEvery(PROMOTIONS_SUBMIT_REQUESTED, doCall(doSubmitPromotions, PROMOTIONS_SUBMIT_FAILED)),
        takeEvery(PROMOTION_OFFER_CREATE_REQUESTED, doCall(doSubmitPromotionOffer, PROMOTION_OFFER_CREATE_FAILED)),
        takeEvery(
            PROMOTION_DEFAULT_MEDIA_REQUESTED,
            doCall(doRequestDefaultMedia, PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED),
        ),
    ]);
}
/**
 * Return the promotions to be deleted/creted/updated by vendor id
 */
export const preparePromotionsSubmitData = (
    promotions: Promotion[],
    wallDecorGroup: WalldecorGroup,
    oldLandingPage?: LandingPage,
): CampaignSubmissionData<Promotion> => {
    let filteredPromotions = _.cloneDeep(promotions);
    let itemsToDelete: Promotion[] = [];

    if (oldLandingPage && oldLandingPage.promotions.data) {
        const deletedPromos = oldLandingPage.promotions.data.filter(existingPromo => {
            let found = false;
            for (const newPromo of promotions) {
                if (existingPromo.id && newPromo.id && newPromo.id === existingPromo.id) {
                    found = true;
                    break;
                }
            }
            return found ? false : true;
        });
        itemsToDelete = deletedPromos;
    }

    if (wallDecorGroup.isGrouped) {
        const wallDecorGoupPromotionIds = wallDecorGroup.isGrouped
            ? wallDecorGroup.promotionGroup.flatMap(promotion => promotion.id || promotion.temporary_id)
            : [];
        //do not format individual promotions from the walldecor group
        filteredPromotions = filteredPromotions.filter((promotion: Promotion) => {
            if (
                wallDecorGoupPromotionIds.length > 0 &&
                wallDecorGoupPromotionIds.includes(promotion.id || promotion.temporary_id)
            ) {
                return false;
            }
            return true;
        });
    } else {
        //filter out merged offer
        filteredPromotions = filteredPromotions.filter((promotion: Promotion) => {
            if (promotion.promotion_offers.data.length === 1) {
                return true;
            } else {
                if (promotion.id) {
                    itemsToDelete.push(promotion);
                }
                return false;
            }
        });
    }

    const formattedPromotions: Promotion[] = [...filteredPromotions].map((promotion: Promotion) => {
        if (promotion.media?.data.length) {
            const promotionMedia = promotion.media?.data[0];
            if (promotion.asset_path && promotion.asset_path.trim() !== '') {
                promotionMedia.asset_path = promotion.asset_path;
            }
            if (promotion.cta_link && promotion.cta_link.trim() !== '') {
                promotionMedia.cta_url = promotion.cta_link;
            }
            if (promotion.asset_file) {
                promotionMedia.asset_file = promotion.asset_file;
            }
            promotion.media.data[0] = promotionMedia;
        } else {
            promotion.media = {
                data: [
                    {
                        type: MediaTypes.promotion,
                        visibility: MediaVisibility.all,
                        asset_path: promotion.asset_path ?? '',
                        in_use: true,
                        cta_url: promotion.cta_link,
                        asset_file: promotion.asset_file ?? null,
                    },
                ],
            };
        }

        delete promotion.temporary_id;
        return promotion;
    });

    const [itemsToUpdate, itemsToCreate] = partition(formattedPromotions, promotion => {
        return !!promotion.id || promotion.id === null;
    });

    return {
        itemsToUpdate,
        itemsToCreate,
        itemsToDelete,
    };
};
