import { TrackingCode } from 'apis/attributionApi/trackingCodes';
import { callApi } from 'apis/callApi';
import { oidc } from 'services/oidcService';
import { Channel } from './channels';
import { Medium } from './mediums';
import { Source } from './sources';

export interface AttributionCreate {
    id?: number;
    name: string;
    description: string;
    channelId: number;
    mediumId: number;
    sourceId: number;
    validFrom: Date;
    validUntil: Date;
    discountId?: string;
    voucherDiscountOptionId?: string;
    trackingCodes: TrackingCode[];
}

export interface Attribution {
    id: number;
    name: string;
    description: string;
    channel: Channel;
    medium: Medium;
    source: Source;
    validFrom: Date;
    validUntil: Date;
    discountId?: number;
    trackingCodes: TrackingCode[];
}

export interface AttributionAuditInfo {
    createdAt: string;
    updatedAt: string | null;
    createdBy: string;
    updatedBy: string | null;
}

export const postAttribution = async (attribution: AttributionCreate): Promise<Attribution> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'POST', 'attributions', attribution, {}, token);
};

export const getAttributionByConditions = async (conditions: Record<string, string>): Promise<Attribution> => {
    let queryString = '';
    for (const key in conditions) {
        queryString += `${key}=${conditions[key]}`;
    }
    const token = await oidc.getAccessToken();
    return await callApi(
        'AttributionApiUrl',
        'GET',
        `attributions${queryString.length ? `?${queryString}` : ''}`,
        null,
        {},
        token,
    );
};
