import { Box, FormLabel, Theme } from '@mui/material';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

interface BorderedBoxProps {
    children: any;
    label?: string;
    className?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        border: `1px solid ${CustomColors.border}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    label: {
        marginLeft: '0.71em',
        marginTop: '-1.1em',
        paddingLeft: '0.44em',
        paddingRight: '0.44em',
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        fontSize: '1em',
        width: 'auto',
    },
}));

export default function BorderedBox({ children, label, className = '' }: BorderedBoxProps): React.ReactElement {
    const { classes } = useStyles();
    return (
        <Box className={`${classes.container} ${className}`}>
            {label && <FormLabel className={classes.label}>{label}</FormLabel>}

            {children}
        </Box>
    );
}
