import { Category } from 'models/category';
import { LandingPage } from 'models/landingPage';
import { MerchandisingSlot } from 'models/merchandisingSlot';
import { Offer, OfferApi } from 'models/offer';
import { Vendor } from 'models/vendor';

export enum CampaignStatus {
    draft = 'draft',
    scheduled = 'scheduled',
    published = 'published',
    expired = 'expired',
    cancelled = 'cancelled',
}

export enum CampaignChannels {
    crm = 'crm',
    display = 'display',
    display_exp = 'display_exp',
    merchandising = 'merchandising',
    local = 'local',
    sea = 'sea',
    sea_exp = 'sea_exp',
    partner = 'partner',
    affiliate = 'affiliate',
}

export interface Campaign {
    uuid: string;
    id: number;
    name: string;
    channel: string;
    category: string;
    status: string;
    activation_date: string;
    expiration_date: string;
    merchandising_slots: { data: Array<MerchandisingSlot> };
    offers: { data: Array<Offer> | Array<OfferApi> };
    vendors: { data: Array<Vendor> };
    extensions: { data: CampaignExtension[] };
    landing_pages: { data: LandingPage[] };
    categories?: { data: Category[] };
}

export type CampaignForm = {
    formStep: number;
    campaign: Campaign | undefined;
    completedSteps: Array<number>;
    errors: Record<string, any>;
    requestState: string;
};

export interface CampaignExtension {
    id: number | null;
    date?: Date | string;
    temporaryId?: null;
}

export const isValidCampaignChannel = channel => {
    return Object.values(CampaignChannels).includes(channel);
};
