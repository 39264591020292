// eslint-disable @typescript-eslint/no-explicit-any
export interface DiscountCodeErrorMetadata {
    conflict: boolean;
    discountDefinitionId: string;
    discountCode: string;
    isOldDiscount: boolean;
}

export default class DiscountCodeError extends Error {
    message: string;
    metadata: DiscountCodeErrorMetadata;

    constructor(
        message: string,
        metadata: DiscountCodeErrorMetadata = {
            conflict: false,
            discountDefinitionId: '',
            discountCode: '',
            isOldDiscount: false,
        },
    ) {
        super(message);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DiscountCodeError.prototype);
        this.message = message;
        this.metadata = metadata;
    }
}
