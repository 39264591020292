/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createOffer, deleteOffer, getCampaign, getOfferDiscountTypes, updateOffer } from 'apis/campaignApi';
import { requestCampaign } from 'application/campaignForm/campaignCreate/requestedCampaignStore';
import { getOffers, getOfferTemp, getVendorsInSaga } from 'application/selectors';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import {
    MappedDefinitionOfferArticleType,
    MappedDefinitionOfferExtras,
} from 'components/campaigns/CampaignForm/CampaignDiscountCodeSelection/DiscountDefinitionParser';
import ResponseError from 'errors/ResponseError';
import { parseDateToAtom } from 'helpers/dateHelper';
import { isInProgress, isNotRequested } from 'helpers/requestStateHelper';
import { Campaign } from 'models/campaign';
import { DISCOUNT_TYPE_PERCENTAGE } from 'models/discount';
import { ArticleTypes, mapOfferFromApi, Offer, OfferApi } from 'models/offer';
import { Vendor } from 'models/vendor';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
    errorOfferCreation,
    receivedDeleteOffer,
    receivedDeleteTemporaryOffer,
    receiveDiscountTypes,
    receivedOfferCreation,
    receivedOffers,
    receivedOfferTemporaryCreation,
    receivedOfferTemporaryUpdate,
    receivedOfferUpdate,
    receivedUpdateCampaignMerchandisingSlots,
    requestedOfferCreation,
    requestOffers,
} from './offerCreateActions';
import {
    CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED,
    CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED,
    DISCOUNT_TYPES_REQUESTED,
    DISCOUNT_TYPES_REQUEST_FAILED,
    OFFERS_REQUESTED,
    OFFERS_REQUEST_FAILED,
    OFFER_CREATE_FAILED,
    OFFER_CREATE_REQUESTED,
    OFFER_DELETE_FAILED,
    OFFER_DELETE_REQUESTED,
    OFFER_TEMPORARY_CREATE_FAILED,
    OFFER_TEMPORARY_CREATE_REQUESTED,
    OFFER_TEMPORARY_DELETE_FAILED,
    OFFER_TEMPORARY_DELETE_REQUESTED,
    OFFER_TEMPORARY_UPDATE_FAILED,
    OFFER_TEMPORARY_UPDATE_REQUESTED,
    OFFER_UPDATE_FAILED,
    OFFER_UPDATE_REQUESTED,
} from './offerCreateTypes';

interface OfferAction {
    offerData: Offer;
    type: string;
    persist: boolean;
    refreshOffersFromServer: boolean;
}

interface DoCreateOfferAction {
    type: string;
    offerTemporaryId?: string;
    offerData?: Offer;
    refreshOffersFromServer: boolean;
}

interface DoCreateTemporaryOfferAction {
    type: string;
    offerData: Offer;
}

interface DeleteOfferAction {
    type: string;
    offerId: number;
}

export function* doRequestDiscountTypes(action, requestInfo: RequestInfo) {
    const discountTypes = yield call(getOfferDiscountTypes, requestInfo);
    yield put(receiveDiscountTypes(discountTypes));
}

export function* doCreateTemporaryOffer(action: DoCreateTemporaryOfferAction, requestInfo: RequestInfo) {
    const tempOffers = yield select(getOfferTemp);
    const updatedOffers: { data: Array<Offer> } =
        isInProgress(tempOffers) || isNotRequested(tempOffers) ? { data: [] } : { data: tempOffers.data };
    updatedOffers.data.push({ ...action.offerData });

    yield put(receivedOfferTemporaryCreation(updatedOffers));
}

export function* doUpdateTemporaryOffer(action: OfferAction, requestInfo: RequestInfo) {
    const tempOffers: { data: Array<Offer> } = yield select(getOfferTemp);
    const updatedOffers = {
        ...tempOffers,
        data: tempOffers.data.map(offer => ({ ...offer })),
    };

    if (!tempOffers.data.length) {
        updatedOffers.data = [action.offerData];
    } else {
        const elementsIndex = updatedOffers.data.findIndex(
            element => element.temporaryId === action.offerData.temporaryId,
        );
        updatedOffers.data[elementsIndex] = Object.assign(updatedOffers.data[elementsIndex], action.offerData); // { ...updatedOffers[elementsIndex], offer };
    }

    yield put(receivedOfferTemporaryUpdate(updatedOffers.data));
    if (action.persist === true && action.offerData.temporaryId) {
        yield put(requestedOfferCreation(action.refreshOffersFromServer, action.offerData.temporaryId));
    }
}

function formatArticleTypeWithExtras(
    article_types: ArticleTypes[],
    papIds: Array<string>,
    extras: MappedDefinitionOfferExtras,
): MappedDefinitionOfferArticleType[] {
    const articleTypes: any = [];

    article_types.forEach(article_type => {
        articleTypes.push({
            article_type: article_type,
            pap_id: papIds,
            extras: extras,
        });
    });

    return articleTypes;
}

/**
 * Create Offer by temporary offer id or passing offer dat
 *
 * @param action
 * @param requestInfo
 * @returns
 */
export function* doCreateOffer(action: DoCreateOfferAction, requestInfo: RequestInfo) {
    let offerData;
    let updatedTempOffers = new Array<Offer>();
    const isTemporaryOffer = action.offerTemporaryId && action.offerTemporaryId.trim().length > 0;
    if (isTemporaryOffer) {
        const offerTemp = yield select(getOfferTemp);
        updatedTempOffers = [...offerTemp.data];
        //update offer with the given temporary id
        const elementsIndex = updatedTempOffers.findIndex(element => element.temporaryId === action.offerTemporaryId);
        if (elementsIndex < 0) {
            throw new Error('Unable to find temporary offer ' + action.offerTemporaryId);
        }
        offerData = updatedTempOffers[elementsIndex];
    } else if (action.offerData) {
        offerData = { ...action.offerData };
    }
    const vendors = yield select(getVendorsInSaga);
    const formattedOfferData = {
        type: offerData.type,
        campaign_id: offerData.campaign_id,
        vendor: vendors.data.filter((vendor: Vendor) => vendor.id === offerData.vendor_id)[0].name,
        promo_code: offerData.promo_code,
        offer_value: offerData.type !== DISCOUNT_TYPE_PERCENTAGE ? offerData.offer_value : 0,
        offer_percentage: offerData.type === DISCOUNT_TYPE_PERCENTAGE ? offerData.offer_percentage : 0,
        highest_offer: offerData.highest_offer,
        activation_date: offerData.activation_date ? parseDateToAtom(offerData.activation_date) : null,
        end_date: offerData.end_date ? parseDateToAtom(offerData.end_date) : null,
        first_extension_date: '',
        second_extension_date: '',
        article_types: formatArticleTypeWithExtras(offerData.article_types, offerData.pap_ids, offerData.extras),
    };

    try {
        yield call(createOffer, formattedOfferData);
        if (action.offerTemporaryId) {
            yield put(
                receivedOfferCreation(
                    updatedTempOffers.filter(element => element.temporaryId !== action.offerTemporaryId),
                ),
            );
        }
        if (action.refreshOffersFromServer === undefined || action.refreshOffersFromServer === true) {
            yield put(requestOffers(offerData.campaign_id));
        }
    } catch (error) {
        if (error instanceof ResponseError) {
            yield put(errorOfferCreation(action, error, requestInfo.correlationId));
            return;
        }

        throw error;
    }
}

export function* doRequestOffers(action, requestInfo: RequestInfo) {
    const campaign: { data: Campaign } = yield call(getCampaign, action.campaignId);
    const campaignOffers = campaign.data?.offers?.data ? { data: [...campaign.data.offers.data] } : { data: [] };
    const formattedOffers: Array<Offer> = campaignOffers.data.map(offer => mapOfferFromApi({ ...offer } as OfferApi));

    yield put(receivedOffers({ data: formattedOffers }));
}

export function* doDeleteOffer(action: DeleteOfferAction, requestInfo: RequestInfo) {
    yield call(deleteOffer, action.offerId);
    const offers = yield select(getOffers);
    const updatedOffers: Array<Offer> = [...offers.data].filter(element => element.id !== action.offerId);
    yield put(receivedDeleteOffer({ data: updatedOffers }));
}

export function* doDeleteOfferTemporary(action, requestInfo: RequestInfo) {
    const offerTemp = yield select(getOfferTemp);
    const updatedTempOffers: Array<Offer> = [...offerTemp.data].filter(
        element => element.temporaryId !== action.temporaryOfferId,
    );
    yield put(receivedDeleteTemporaryOffer({ data: updatedTempOffers }));
}

export function* doUpdateCampaignMerchandisingSlots(action, requestInfo: RequestInfo) {
    yield put(receivedUpdateCampaignMerchandisingSlots({ data: action.slotsData }));
}

export function* doUpdateOffer(action: OfferAction, requestInfo: RequestInfo) {
    const vendors = yield select(getVendorsInSaga);
    const vendor = vendors.data.filter(vendor => vendor.id === action.offerData.vendor_id);
    const vendorName = vendor && vendor.length ? vendor.pop()!.name : '';
    const formattedOffer = {
        ...action.offerData,
        activation_date: action.offerData.activation_date ? parseDateToAtom(action.offerData.activation_date) : null,
        end_date: action.offerData.end_date ? parseDateToAtom(action.offerData.end_date) : null,
        vendor: vendorName,
        article_types: action.offerData.article_types,
    };

    try {
        const offer = yield call(updateOffer, formattedOffer);
        const offers: { data: Offer[] } = yield select(getOffers);
        const updatedOffers = { ...offers };
        const elementsIndex = updatedOffers.data.findIndex(element => element.id === action.offerData.id);
        updatedOffers.data[elementsIndex] = Object.assign(
            updatedOffers.data[elementsIndex],
            mapOfferFromApi(offer.data),
        );
        updatedOffers.data[elementsIndex].edited = true;
        yield put(receivedOfferUpdate(updatedOffers.data));
        yield put(requestCampaign(action.offerData.campaign_id));
    } catch (error) {
        if (error instanceof ResponseError) {
            yield put(errorOfferCreation(action, error, requestInfo.correlationId));
            return;
        }

        throw error;
    }
}

export function* watchRequestOfferCreate() {
    yield all([
        takeEvery(DISCOUNT_TYPES_REQUESTED, doCall(doRequestDiscountTypes, DISCOUNT_TYPES_REQUEST_FAILED)),
        takeEvery(OFFER_CREATE_REQUESTED, doCall(doCreateOffer, OFFER_CREATE_FAILED)),
        takeEvery(OFFER_TEMPORARY_CREATE_REQUESTED, doCall(doCreateTemporaryOffer, OFFER_TEMPORARY_CREATE_FAILED)),
        takeEvery(OFFER_TEMPORARY_UPDATE_REQUESTED, doCall(doUpdateTemporaryOffer, OFFER_TEMPORARY_UPDATE_FAILED)),
        takeEvery(OFFERS_REQUESTED, doCall(doRequestOffers, OFFERS_REQUEST_FAILED)),
        takeEvery(OFFER_UPDATE_REQUESTED, doCall(doUpdateOffer, OFFER_UPDATE_FAILED)),
        takeEvery(OFFER_DELETE_REQUESTED, doCall(doDeleteOffer, OFFER_DELETE_FAILED)),
        takeEvery(OFFER_TEMPORARY_DELETE_REQUESTED, doCall(doDeleteOfferTemporary, OFFER_TEMPORARY_DELETE_FAILED)),
        takeEvery(
            CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED,
            doCall(doUpdateCampaignMerchandisingSlots, CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED),
        ),
    ]);
}
