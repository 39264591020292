import { Container } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { AddSourceForm } from '../AddSourceForm/AddSourceForm';

const useStyles = makeStyles()(theme => ({
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

export default function AddSourcePage(): React.ReactElement {
    const { classes } = useStyles();

    return (
        <div>
            <Container maxWidth="lg">
                <h2 className={classes.title}>Add a new Attribution source</h2>
                <AddSourceForm />
            </Container>
        </div>
    );
}
