import { CampaignChannels, CampaignStatus } from 'models/campaign';
import {
    CAMPAIGN_CURRENT_RECEIVED,
    CAMPAIGN_CURRENT_REQUESTED,
    CAMPAIGN_CURRENT_REQUEST_FAILED,
    CAMPAIGN_SCHEDULED_RECEIVED,
    CAMPAIGN_SCHEDULED_REQUESTED,
    CAMPAIGN_SCHEDULED_REQUEST_FAILED,
    CAMPAIGN_EXPIRED_RECEIVED,
    CAMPAIGN_EXPIRED_REQUESTED,
    CAMPAIGN_EXPIRED_REQUEST_FAILED,
} from './campaignListTypes';

export const requestCurrentCampaigns = (channel: CampaignChannels, page: number) => ({
    type: CAMPAIGN_CURRENT_REQUESTED,
    status: [CampaignStatus.published, CampaignStatus.draft],
    channel,
    page,
});
export const receiveCurrentCampaigns = payload => ({
    type: CAMPAIGN_CURRENT_RECEIVED,
    payload,
});
export const errorCurrentCampaign = requestAction => ({
    type: CAMPAIGN_CURRENT_REQUEST_FAILED,
    error: new Error('Failed to retrieve current campaigns.'),
    requestAction,
});

export const requestScheduledCampaigns = (channel: CampaignChannels, page: number) => ({
    type: CAMPAIGN_SCHEDULED_REQUESTED,
    status: [CampaignStatus.scheduled],
    channel,
    page,
});
export const receiveScheduledCampaigns = payload => ({
    type: CAMPAIGN_SCHEDULED_RECEIVED,
    payload,
});
export const errorScheduledCampaign = requestAction => ({
    type: CAMPAIGN_SCHEDULED_REQUEST_FAILED,
    error: new Error('Failed to retrieve scheduled campaigns.'),
    requestAction,
});

export const requestExpiredCampaigns = (channel: CampaignChannels, page: number) => ({
    type: CAMPAIGN_EXPIRED_REQUESTED,
    status: [CampaignStatus.expired],
    channel,
    page,
});
export const receiveExpiredCampaigns = payload => ({
    type: CAMPAIGN_EXPIRED_RECEIVED,
    payload,
});
export const errorExpiredCampaign = requestAction => ({
    type: CAMPAIGN_EXPIRED_REQUEST_FAILED,
    error: new Error('Failed to retrieve expired campaigns.'),
    requestAction,
});
