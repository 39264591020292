import { createGenericReducer } from 'common/genericReducer';
import {
    PROMOTIONS_SUBMIT_FAILED,
    PROMOTIONS_SUBMIT_RECEIVED,
    PROMOTIONS_SUBMIT_REQUESTED,
    PROMOTIONS_SUBMIT_RESET,
    PROMOTION_CREATE_FAILED,
    PROMOTION_CREATE_RECEIVED,
    PROMOTION_CREATE_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_RECEIVED,
    PROMOTION_DEFAULT_MEDIA_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED,
    PROMOTION_OFFER_CREATE_FAILED,
    PROMOTION_OFFER_CREATE_RECEIVED,
    PROMOTION_OFFER_CREATE_REQUESTED,
} from './promotionCreateTypes';

export const submittedPromotionReducer = createGenericReducer(
    PROMOTION_CREATE_REQUESTED,
    PROMOTION_CREATE_RECEIVED,
    PROMOTION_CREATE_FAILED,
);

export const submittedPromotionsReducer = createGenericReducer(
    PROMOTIONS_SUBMIT_REQUESTED,
    PROMOTIONS_SUBMIT_RECEIVED,
    PROMOTIONS_SUBMIT_FAILED,
    PROMOTIONS_SUBMIT_RESET,
);

export const submittedPromotionOfferReducer = createGenericReducer(
    PROMOTION_OFFER_CREATE_REQUESTED,
    PROMOTION_OFFER_CREATE_RECEIVED,
    PROMOTION_OFFER_CREATE_FAILED,
);

export const defaultMediaReducer = createGenericReducer(
    PROMOTION_DEFAULT_MEDIA_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_RECEIVED,
    PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED,
);
