import { requestVendors } from 'application/config/vendorsStore';
import useQuery from 'components/hooks/useQuery';
import { isNotRequested } from 'helpers/requestStateHelper';
import _ from 'lodash';
import { Vendor } from 'models/vendor';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

const TAB_QUERY = 'tab';

export type useVendorPickerState = {
    allVendors: Vendor[];
    currentVendor: Vendor | void;
    setCurrentVendor: (vendor?: Vendor) => void;
    pickedVendors: Vendor[];
    setPickedVendors: (vendors: Vendor[]) => void;
    onVendorCheck: (vendor: Vendor, checked: boolean) => void;
};

const emptyArr = [];

export default function useVendorPicker(): useVendorPickerState {
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();

    const [currentVendor, setCurrentVendor] = useState<Vendor>();
    const [pickedVendors, setPickedVendors] = useState<Vendor[]>([]);
    const vendors = useSelector(state => state.apiConfig.vendors);

    useEffect(() => {
        if (isNotRequested(vendors)) {
            dispatch(requestVendors());
        }
    }, []);

    const updateTabQuery = (vendor?: Vendor) =>
        history.push(history.location.pathname + (vendor ? `?${TAB_QUERY}=${vendor.name}` : ''));

    useEffect(() => {
        if (pickedVendors.length) {
            const tab = query.get(TAB_QUERY);
            const vendorFound = pickedVendors.find(v => v.name === tab);
            setCurrentVendor(vendorFound || pickedVendors[0]);
        }
    }, [query.get(TAB_QUERY), pickedVendors, vendors.data]);

    const onVendorCheck = (vendor: Vendor, checked: boolean) => {
        if (!checked) {
            const newPickedVendors = pickedVendors.filter(item => item.id !== vendor.id);
            const removedVendorIndex = pickedVendors.findIndex(item => item.name === vendor.name);
            setPickedVendors(newPickedVendors);
            if (currentVendor?.name === vendor.name) {
                const switchTo = newPickedVendors[removedVendorIndex] || _.last(newPickedVendors);
                updateTabQuery(switchTo);
                setCurrentVendor(switchTo);
            }
        } else {
            setPickedVendors([...pickedVendors, vendor]);
            updateTabQuery(vendor);
        }
    };

    return {
        allVendors: vendors.data || emptyArr,
        currentVendor,
        setCurrentVendor: (vendor?: Vendor) => updateTabQuery(vendor),
        pickedVendors,
        setPickedVendors,
        onVendorCheck,
    };
}
