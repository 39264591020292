import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLodMomentsData, getLodMomentsList, LodMomentListType, LodMomentType, postLodFiles } from 'apis/lod';
import { createGenericExtraReducers } from 'common/createGenericExtraReducers';
import { getInitialRequestState, RequestType } from 'common/RequestState';

export const fetchLodMomentsList = createAsyncThunk('lod/momentsdata/get', async (): Promise<LodMomentListType> => {
    return await getLodMomentsList();
});

export const fetchLodDataByVendorMoment = createAsyncThunk(
    'lod/momentsdata/get',
    async ({ vendor, moment }: { vendor: string; moment: string }): Promise<LodMomentType> => {
        return await getLodMomentsData(vendor, moment);
    },
);

export const postLodDataByVendorMoment = createAsyncThunk(
    'lod/momentsdata/post',
    async ({ vendor, moment, updatePayload }: { vendor: string; moment: string; updatePayload: LodMomentType }) => {
        await postLodFiles(vendor, moment, updatePayload);
        return updatePayload;
    },
);

export const lodSettingsSlice = createSlice({
    name: 'momentsdata',
    initialState: {
        momentsdata: <RequestType<object>>getInitialRequestState({}),
    },
    reducers: {},
    extraReducers: builder => {
        createGenericExtraReducers(builder, fetchLodDataByVendorMoment, 'momentsdata');
    },
});
