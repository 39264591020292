import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.common.white,
        padding: '10px',
        textAlign: 'center',
    },
    message: {
        fontWeight: 'bolder',
        lineHeight: 1.5,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.common.white,
    },
}));

export interface AlertProps {
    children: any;
    severity?: string;
}

const Alert: React.FunctionComponent<AlertProps> = ({ children, severity = 'error' }: AlertProps) => {
    const { classes, cx } = useStyles();
    return (
        <Box m={1} className={cx(classes.container, classes[severity])} data-cy="Alert-alertBox">
            <div className={classes.message} data-cy="Alert-alertMessage">
                {children}{' '}
            </div>
        </Box>
    );
};

export default Alert;
