/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGenericReducer } from 'common/genericReducer';
import { RequestState } from 'common/RequestState';
import reduceReducers from 'reduce-reducers';
import {
    DISCOUNT_TYPES_RECEIVED,
    DISCOUNT_TYPES_REQUESTED,
    DISCOUNT_TYPES_REQUEST_FAILED,
    OFFERS_RECEIVED,
    OFFERS_REQUESTED,
    OFFERS_REQUEST_FAILED,
    OFFER_CREATE_FAILED,
    OFFER_CREATE_RECEIVED,
    OFFER_CREATE_REQUESTED,
    OFFER_DELETE_FAILED,
    OFFER_DELETE_RECEIVED,
    OFFER_DELETE_REQUESTED,
    OFFER_DISCOUNT_CODE_UPDATE_FAILED,
    OFFER_DISCOUNT_CODE_UPDATE_RECEIVED,
    OFFER_DISCOUNT_CODE_UPDATE_REQUESTED,
    OFFER_TEMPORARY_CREATE_FAILED,
    OFFER_TEMPORARY_CREATE_RECEIVED,
    OFFER_TEMPORARY_CREATE_REQUESTED,
    OFFER_TEMPORARY_CREATE_RESET,
    OFFER_TEMPORARY_DELETE_FAILED,
    OFFER_TEMPORARY_DELETE_RECEIVED,
    OFFER_TEMPORARY_DELETE_REQUESTED,
    OFFER_TEMPORARY_UPDATE_FAILED,
    OFFER_TEMPORARY_UPDATE_RECEIVED,
    OFFER_TEMPORARY_UPDATE_REQUESTED,
    OFFER_UPDATE_FAILED,
    OFFER_UPDATE_RECEIVED,
    OFFER_UPDATE_REQUESTED,
} from './offerCreateTypes';

export const getDiscountTypes = createGenericReducer(
    DISCOUNT_TYPES_REQUESTED,
    DISCOUNT_TYPES_RECEIVED,
    DISCOUNT_TYPES_REQUEST_FAILED,
);

export const offers = reduceReducers(
    createGenericReducer(OFFERS_REQUESTED, OFFERS_RECEIVED, OFFERS_REQUEST_FAILED, null, null, {
        data: [],
        requestState: RequestState.NotRequested,
    }),
    createGenericReducer(OFFER_UPDATE_REQUESTED, OFFER_UPDATE_RECEIVED, OFFER_UPDATE_FAILED),
    createGenericReducer(OFFER_DELETE_REQUESTED, OFFER_DELETE_RECEIVED, OFFER_DELETE_FAILED),
    createGenericReducer(
        OFFER_DISCOUNT_CODE_UPDATE_REQUESTED,
        OFFER_DISCOUNT_CODE_UPDATE_RECEIVED,
        OFFER_DISCOUNT_CODE_UPDATE_FAILED,
    ),
);

export const offerTemp = reduceReducers(
    createGenericReducer(
        OFFER_TEMPORARY_CREATE_REQUESTED,
        OFFER_TEMPORARY_CREATE_RECEIVED,
        OFFER_TEMPORARY_CREATE_FAILED,
        OFFER_TEMPORARY_CREATE_RESET,
        null,
        { data: [], requestState: RequestState.NotRequested },
    ),
    createGenericReducer(
        OFFER_TEMPORARY_UPDATE_REQUESTED,
        OFFER_TEMPORARY_UPDATE_RECEIVED,
        OFFER_TEMPORARY_UPDATE_FAILED,
    ),
    createGenericReducer(OFFER_CREATE_REQUESTED, OFFER_CREATE_RECEIVED, OFFER_CREATE_FAILED),
    createGenericReducer(
        OFFER_TEMPORARY_DELETE_REQUESTED,
        OFFER_TEMPORARY_DELETE_RECEIVED,
        OFFER_TEMPORARY_DELETE_FAILED,
    ),
);
