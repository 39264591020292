import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { ReactElement } from 'react';

import { makeStyles } from 'tss-react/mui';

export interface LoaderProps {
    withBackdrop?: boolean;
    label?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    label: {
        display: 'block',
    },
}));
const LabelComponent = (props): ReactElement => {
    return (
        <Box mt={1} ml={1}>
            <Typography>{props.text}</Typography>
        </Box>
    );
};

export const Loader: React.FC<LoaderProps> = ({ withBackdrop = true, label }: LoaderProps) => {
    const { classes } = useStyles();
    const [open] = React.useState(withBackdrop);

    return (
        <div className={classes.loader} data-cy={'Loader' + (withBackdrop ? '-Backdrop' : '')}>
            {!withBackdrop && <CircularProgress disableShrink />}
            {!withBackdrop && label && <LabelComponent text={label} />}
            {withBackdrop && (
                <Backdrop className={classes.backdrop} open={open}>
                    <CircularProgress color="inherit" />
                    {label && <LabelComponent text={label} />}
                </Backdrop>
            )}
        </div>
    );
};
