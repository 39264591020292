import { AttributionAuditInfo } from 'apis/attributionApi/attributions';
import { callApi } from 'apis/callApi';
import { oidc } from 'services/oidcService';

export interface TrackingCode extends AttributionAuditInfo {
    id: number | null;
    suffix: string;
    code: string;
}

export interface TrackingCodeCreate {
    attributionId: number;
    suffix?: string;
    timestamp?: string;
}

export const postTrackingCode = async (trackingCodeCreate: TrackingCodeCreate): Promise<TrackingCode> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'POST', 'trackingCodes', trackingCodeCreate, {}, token);
};
