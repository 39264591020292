/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Offer } from 'models/offer';
import {
    CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED,
    CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_RECEIVED,
    CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED,
    DISCOUNT_TYPES_RECEIVED,
    DISCOUNT_TYPES_REQUESTED,
    DISCOUNT_TYPES_REQUEST_FAILED,
    OFFERS_RECEIVED,
    OFFERS_REQUESTED,
    OFFERS_REQUEST_FAILED,
    OFFER_CREATE_FAILED,
    OFFER_CREATE_RECEIVED,
    OFFER_CREATE_REQUESTED,
    OFFER_DELETE_FAILED,
    OFFER_DELETE_RECEIVED,
    OFFER_DELETE_REQUESTED,
    OFFER_TEMPORARY_CREATE_FAILED,
    OFFER_TEMPORARY_CREATE_RECEIVED,
    OFFER_TEMPORARY_CREATE_REQUESTED,
    OFFER_TEMPORARY_CREATE_RESET,
    OFFER_TEMPORARY_DELETE_FAILED,
    OFFER_TEMPORARY_DELETE_RECEIVED,
    OFFER_TEMPORARY_DELETE_REQUESTED,
    OFFER_TEMPORARY_UPDATE_FAILED,
    OFFER_TEMPORARY_UPDATE_RECEIVED,
    OFFER_TEMPORARY_UPDATE_REQUESTED,
    OFFER_UPDATE_FAILED,
    OFFER_UPDATE_RECEIVED,
    OFFER_UPDATE_REQUESTED,
} from './offerCreateTypes';

export const requestDiscountTypes = () => ({
    type: DISCOUNT_TYPES_REQUESTED,
});
export const receiveDiscountTypes = payload => ({
    type: DISCOUNT_TYPES_RECEIVED,
    payload,
});
export const errorDiscountTypes = requestAction => ({
    type: DISCOUNT_TYPES_REQUEST_FAILED,
    error: new Error('Failed to retrieve vendors.'),
    requestAction,
});

export const requestedOfferCreation = (
    refreshOffersFromServer: boolean,
    offerTemporaryId?: string,
    offerData?: Offer,
) => ({
    type: OFFER_CREATE_REQUESTED,
    offerTemporaryId,
    refreshOffersFromServer,
    offerData,
});

export const receivedOfferCreation = payload => ({
    type: OFFER_CREATE_RECEIVED,
    payload: { data: payload },
});

export const errorOfferCreation = (requestAction, error, correlationId: string) => ({
    type: OFFER_CREATE_FAILED,
    error,
    requestAction,
    correlationId,
});

/**Offer Update */
export const requestedOfferUpdate = (offerData: Offer) => ({
    type: OFFER_UPDATE_REQUESTED,
    offerData,
});

export const receivedOfferUpdate = payload => ({
    type: OFFER_UPDATE_RECEIVED,
    payload: { data: payload },
});

export const errorOfferUpdate = requestAction => ({
    type: OFFER_UPDATE_FAILED,
    error: new Error('Failed to update custom dates.'),
    requestAction,
});

export const requestedOfferTemporaryUpdate = (offerData: Offer, persist = false) => ({
    type: OFFER_TEMPORARY_UPDATE_REQUESTED,
    offerData,
    persist,
});
export const receivedOfferTemporaryUpdate = payload => ({
    type: OFFER_TEMPORARY_UPDATE_RECEIVED,
    payload: { data: payload },
});
export const errorOfferTemporaryUpdate = requestAction => ({
    type: OFFER_TEMPORARY_UPDATE_FAILED,
    error: new Error('Failed to update custom dates.'),
    requestAction,
});

export const requestedOfferTemporaryCreation = (offerData: Offer) => ({
    type: OFFER_TEMPORARY_CREATE_REQUESTED,
    offerData,
});
export const receivedOfferTemporaryCreation = payload => ({
    type: OFFER_TEMPORARY_CREATE_RECEIVED,
    payload,
});
export const errorOfferTemporaryCreation = requestAction => ({
    type: OFFER_TEMPORARY_CREATE_FAILED,
    error: new Error('Failed to crete temporary offer.'),
    requestAction,
});
export const resetOfferTemporaryCreation = () => ({
    type: OFFER_TEMPORARY_CREATE_RESET,
});

/**Fetch Offers */
export const requestOffers = campaignId => ({
    type: OFFERS_REQUESTED,
    campaignId,
});
export const receivedOffers = payload => ({
    type: OFFERS_RECEIVED,
    payload,
});
export const errorRequestOffers = requestAction => ({
    type: OFFERS_REQUEST_FAILED,
    error: new Error('Failed to retrieve campaign offers.'),
    requestAction,
});

export const requestDeleteOffer = offerId => ({
    type: OFFER_DELETE_REQUESTED,
    offerId,
});
export const receivedDeleteOffer = payload => ({
    type: OFFER_DELETE_RECEIVED,
    payload,
});
export const errorDeleteOffer = requestAction => ({
    type: OFFER_DELETE_FAILED,
    error: new Error('Failed to delete offer.'),
    requestAction,
});

export const requestDeleteTemporaryOffer = temporaryOfferId => ({
    type: OFFER_TEMPORARY_DELETE_REQUESTED,
    temporaryOfferId,
});
export const receivedDeleteTemporaryOffer = payload => ({
    type: OFFER_TEMPORARY_DELETE_RECEIVED,
    payload,
});
export const errorDeleteTemporaryOffer = requestAction => ({
    type: OFFER_TEMPORARY_DELETE_FAILED,
    error: new Error('Failed to delete temporary offer.'),
    requestAction,
});

export const requestUpdateCampaignMerchandisingSlots = slotsData => ({
    type: CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED,
    slotsData,
});
export const receivedUpdateCampaignMerchandisingSlots = payload => ({
    type: CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_RECEIVED,
    payload,
});
export const errorUpdateCampaignMerchandisingSlots = requestAction => ({
    type: CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED,
    error: new Error('Failed to update merchandising slots.'),
    requestAction,
});
