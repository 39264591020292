import { getRandomString } from 'helpers/generalHelper';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';
import { getAppConfig } from 'services/configService';
import { doRequest } from 'services/httpService';
import urlJoin from 'url-join';

/**
 * Calls the Campaigns Manager API
 * @param  {String} api
 * @param  {String} uri
 * @param  {Object} data
 * @param  {Object} options
 * @param  {Object} options.headers
 * @param  {String} options.cacheKey
 * @param  {Number}    options.cacheDuration
 * @param  {String} token
 * @return {Promise}
 */
export const callApi = (
    api: string,
    method: string,
    uri: string,
    data: Record<string, any> | null = null,
    { headers = {}, cacheKey = '', cacheDuration = 3600 } = {},
    token: string | null = null,
): Promise<any> => {
    const requestToken = token ?? activeDirectoryServiceInstance.accessToken;
    if (requestToken) {
        headers['Authorization'] = `Bearer ${requestToken}`;
    }

    // Guarantee the API response is not the cached one
    headers['Cache-Control'] = 'no-cache, must-revalidate, max-age=0';
    headers['X-cache-refresh'] = getRandomString(12);

    return doRequest(method, urlJoin(getAppConfig()[api], uri), data, headers, cacheKey, cacheDuration);
};
