/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getPredefinedTranslations, MetaData } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { all, call, put, takeLeading } from 'redux-saga/effects';

export const PREDEFINED_TRANSLATIONS_REQUESTED = 'PREDEFINED_TRANSLATIONS_REQUESTED';
export const PREDEFINED_TRANSLATIONS_RECEIVED = 'PREDEFINED_TRANSLATIONS_RECEIVED';
export const PREDEFINED_TRANSLATIONS_FAILED = 'PREDEFINED_TRANSLATIONS_FAILED';

export const requestPredefinedTranslations = () => ({
    type: PREDEFINED_TRANSLATIONS_REQUESTED,
});
export const receivePredefinedTranslations = payload => ({
    type: PREDEFINED_TRANSLATIONS_RECEIVED,
    payload,
});
export const errorRequestPredefinedTranslations = requestAction => ({
    type: PREDEFINED_TRANSLATIONS_FAILED,
    error: new Error('Failed to retrieve predefined translations'),
    requestAction,
});

export const getPredefinedTranslationsReducer = createGenericReducer(
    PREDEFINED_TRANSLATIONS_REQUESTED,
    PREDEFINED_TRANSLATIONS_RECEIVED,
    PREDEFINED_TRANSLATIONS_FAILED,
);

export function* doRequestPredefinedTranslations(action) {
    let predefinedTranslations = [];
    let paginated = false;
    const parameters: Record<string, any> = {};

    do {
        const response = yield call(getPredefinedTranslations, parameters);
        predefinedTranslations = predefinedTranslations.concat(response.data);
        if (response.meta && response.meta.pagination) {
            const meta = response.meta as MetaData;
            const pagination = meta.pagination;
            if (pagination.current_page !== pagination.total_pages) {
                paginated = true;
                parameters.page = pagination.current_page + 1;
            } else {
                paginated = false;
            }
        }
    } while (paginated);

    yield put(receivePredefinedTranslations({ data: predefinedTranslations }));
}

export function* watchPredefinedTranslationsStore() {
    yield all([
        takeLeading(
            PREDEFINED_TRANSLATIONS_REQUESTED,
            doCall(doRequestPredefinedTranslations, PREDEFINED_TRANSLATIONS_FAILED),
        ),
    ]);
}
