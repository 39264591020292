/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    MERCHANDISING_SLOT_SUBMIT_REQUESTED,
    MERCHANDISING_SLOT_SUBMIT_RECEIVED,
    MERCHANDISING_SLOT_SUBMIT_FAILED,
    MERCHANDISING_SLOT_SUBMIT_RESET,
    MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
    MERCHANDISING_SLOTS_SUBMIT_RECEIVED,
    MERCHANDISING_SLOTS_SUBMIT_FAILED,
    MERCHANDISING_SLOTS_SUBMIT_RESET,
    MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_RECEIVED,
    MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED,
    MERCHANDISING_SLOTS_UPDATE_COUNTDOWN,
    CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED,
    CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_COMPLETED,
} from './merchandisingSlotCreateTypes';

export const submitMerchandisingSlot = merchandisingSlot => ({
    type: MERCHANDISING_SLOT_SUBMIT_REQUESTED,
    merchandisingSlot,
});
export const receiveSubmittedMerchandisingSlot = payload => ({
    type: MERCHANDISING_SLOT_SUBMIT_RECEIVED,
    payload,
});
export const resetMerchandisingSlot = () => ({
    type: MERCHANDISING_SLOT_SUBMIT_RESET,
});
export const errorSubmittedMerchandisingSlot = (error, correlationId: string) => ({
    type: MERCHANDISING_SLOT_SUBMIT_FAILED,
    error,
    correlationId,
});

export const submitMerchandisingSlots = merchandisingSlots => ({
    type: MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
    merchandisingSlots,
});

export const finalizeCampaignFormMerchSlots = (
    merchandisingSlots,
    categories,
    finalizeForm = false,
    moveToNextStep = false,
) => ({
    type: CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED,
    merchandisingSlots,
    categories,
    finalizeForm,
    moveToNextStep,
});

export const finalizeCampaignFormMerchSlotsCompleted = () => ({
    type: CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_COMPLETED,
});

export const receiveSubmittedMerchandisingSlots = payload => ({
    type: MERCHANDISING_SLOTS_SUBMIT_RECEIVED,
    payload,
});

export const errorSubmittedMerchandisingSlots = (error, correlationId: string) => ({
    type: MERCHANDISING_SLOTS_SUBMIT_FAILED,
    error,
    correlationId,
});
export const resetMerchandisingSlots = () => ({
    type: MERCHANDISING_SLOTS_SUBMIT_RESET,
});

export const updateSlotsCountdown = (textSlots, withCountdown: boolean, vendorId: number) => ({
    type: MERCHANDISING_SLOTS_UPDATE_COUNTDOWN,
    textSlots,
    withCountdown,
    vendorId,
});
export const updatedSlotsCountdown = payload => ({
    type: MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_RECEIVED,
    payload,
});
export const errorUpdateSlotsCountdown = requestAction => ({
    type: MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED,
    error: new Error('Failed updating merchandising slots countdown.'),
    requestAction,
});
