import { TransactionStatuses } from 'apis/b2bAccounts';
import { capitalize } from 'helpers/stringHelper';

// reviewStatus: Default, Required, Blocked, Deleted, Approved
// status: Default, New, Blocked, Deleted, Approved
// lastTransactionStatus: null, pending, failed, completed
export const humanizeAccountStatus = ({
    reviewStatus,
    status,
    lastTransactionStatus,
}: {
    reviewStatus: string;
    status: string;
    lastTransactionStatus: string | null;
}): string => {
    if (!lastTransactionStatus || lastTransactionStatus === TransactionStatuses.COMPLETED) {
        return defaultAccountStatusHumanizer(reviewStatus, status);
    }
    const transactionStatus = capitalize(lastTransactionStatus);

    if (reviewStatus === 'Required' || status === 'Approved') {
        const actionName = status === 'Approved' ? 'Update' : 'Creation';
        return `${transactionStatus} ${actionName}`;
    }

    // not sure what to do with other cases. just defaulting to this
    return `${status} (${transactionStatus} Action)`;
};

function defaultAccountStatusHumanizer(reviewStatus: string, status: string): string {
    switch (reviewStatus) {
        case 'Required':
            if (status === 'Approved') {
                return 'Account update';
            }
            return 'New account';

        default:
            return status;
    }
}
