const consoleLogError = (error): void => {
    (console.error || console.log).call(console, error);
};

const logErrorToNewRelic = (error: Error): void => {
    // NewRelic script can be blocked by AdBlock etc
    if (!window.NREUM) return;
    window.NREUM.noticeError(error);
};

//Errors reported in our code should use this to report them
export const handleError = (error: Error, info: any = null): void => {
    consoleLogError(error);
    if (!!info) consoleLogError(info);
    logErrorToNewRelic(error);
};
