import { getVersionedCacheKey } from 'helpers/cacheHelper';
import { getRandomString } from 'helpers/generalHelper';
import { getStorefrontUrl } from 'helpers/urlHelper';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';
import { doRequest } from 'services/httpService';

const CACHE_EXPIRATION_SECONDS = 3600 * 3;

/**
 * Calls a Strorefront Endpoint
 *
 * @param  {String} vendor
 * @param  {String} method
 * @param  {String} uri
 * @param  {Object} data
 * @param  {Object} options
 * @param  {Object} options.headers
 * @param  {String} options.cacheKey
 * @param  {Number} options.cacheDuration
 * @return {Promise}
 */
const callApi = (
    vendor: string,
    method: string,
    uri: string,
    data = null,
    { headers = {}, cacheKey = '', cacheDuration = 3600, forceCacheUpdate = false } = {},
): Promise<any> => {
    const requestToken = activeDirectoryServiceInstance.accessToken;
    if (requestToken) {
        headers['Authorization'] = `Bearer ${requestToken}`;
    }

    // Guarantee the API response is not the cached one
    headers['Cache-Control'] = 'no-cache, must-revalidate, max-age=0';
    headers['X-cache-refresh'] = getRandomString(12);

    return doRequest(method, getStorefrontUrl(vendor, uri), data, headers, cacheKey, cacheDuration, forceCacheUpdate);
};

// Routes
export const getAllRoutes = (vendor: string, forceCacheUpdate = false): Promise<any> => {
    const options = {
        cacheKey: getVersionedCacheKey('STOREFRONT'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
        forceCacheUpdate,
    };

    return callApi(vendor, 'GET', 'platform/all-routes', null, options);
};
