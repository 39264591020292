import { useEffect, useRef } from 'react';

/** ONE TICK delay */
export default function usePrevious<V>(value: V): V | undefined {
    const ref = useRef<V>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
