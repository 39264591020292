import GlobalStyles from '@mui/material/GlobalStyles';
import { Theme, adaptV4Theme, createTheme } from '@mui/material/styles';
import React from 'react';

export const CustomColors = {
    background: '#f4f6f8',
    heading: '#38637e',
    text: {
        light: '#333',
        link: '#38637E',
    },
    navHover: 'rgba(216, 216, 216, 0.5)',
    subTitle: '#38637e',
    border: '#ccc',
};

// To extend this, check out:
// https://material-ui.com/customization/default-theme/
export const CustomTheme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                main: '#003952',
            },
            secondary: {
                light: '#fff',
                main: '#fff',
                contrastText: '#003952',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            background: {
                default: CustomColors.background,
            },
            info: {
                main: '#2D81B0',
            },
        },
    }),
    {
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTypography: {
                variants: [
                    {
                        props: { variant: 'h6' },
                        style: {
                            height: '30px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            letterSpacing: 'normal',
                        },
                    },
                ],
            },
        },
        palette: {
            // use mui v7 palettes (v5 is too dark)
            warning: {
                light: '#ffb74d',
                main: '#ffa726',
                dark: '#f57c00',
            },
            success: {
                light: '#81c784',
                main: '#66bb6a',
                dark: '#388e3c',
            },
        },
    },
);

/**
 * Put global css here
 */
export const GlobalCss: React.ReactElement = (
    <GlobalStyles
        styles={({ palette }: Theme) => ({
            // You should target [class*="MuiButton-root"] instead if you nest themes.
            // '.MuiButton-root': {
            // },
            '.MuiTypography-h1': {
                fontSize: '2em',
            },
            '.MuiTypography-h6': {
                height: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: palette.primary.main,
            },
            '.MuiTypography-subtitle1': {
                fontSize: '1.1em',
            },
            '.MuiSvgIcon-root': {
                width: '0.75em',
                height: '0.75em',
            },
            '.MuiPaper-elevation4': {
                boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
            },
            '.MuiToolbar-dense': {
                height: '65px',
            },
            '.MuiTableSortLabel-active': {
                color: '#fff !important',
                textDecoration: 'underline',
            },
            '.MuiTableSortLabel-icon': {
                color: '#fff !important',
            },
            '.MuiTableSortLabel-root:hover': {
                color: '#fff !important',
                textDecoration: 'underline',
            },
            '.MuiIconButton-sizeSmall': {
                padding: '3px !important',
                '& svg': {
                    fontSize: '18px',
                },
            },
        })}
    />
);
