import { CampaignSubmissionData } from 'common/genericTypes';
import { Promotion } from 'models/promotion';
import { PromotionOffer } from 'models/promotionOffer';
import {
    PROMOTIONS_SUBMIT_FAILED,
    PROMOTIONS_SUBMIT_RECEIVED,
    PROMOTIONS_SUBMIT_REQUESTED,
    PROMOTION_CREATE_FAILED,
    PROMOTION_CREATE_RECEIVED,
    PROMOTION_CREATE_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_RECEIVED,
    PROMOTION_DEFAULT_MEDIA_REQUESTED,
    PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED,
    PROMOTION_OFFER_CREATE_FAILED,
    PROMOTION_OFFER_CREATE_RECEIVED,
    PROMOTION_OFFER_CREATE_REQUESTED,
    PROMOTION_RECEIVED,
    PROMOTION_REQUESTED,
    PROMOTION_REQUEST_FAILED,
    PROMOTION_REQUEST_RESET,
    PROMOTION_UPDATE_FAILED,
    PROMOTION_UPDATE_RECEIVED,
} from './promotionCreateTypes';

export const requestPromotionCreation = (promotion: Promotion) => ({
    type: PROMOTION_CREATE_REQUESTED,
    promotion,
});

export const receivedPromotionCreation = payload => ({
    type: PROMOTION_CREATE_RECEIVED,
    payload,
});

export const errorPromotionCreation = (requestAction, error, correlationId: string) => ({
    type: PROMOTION_CREATE_FAILED,
    error,
    requestAction,
    correlationId,
});

export const submitPromotions = (promotions: CampaignSubmissionData<Promotion>, finalizeForm = false) => ({
    type: PROMOTIONS_SUBMIT_REQUESTED,
    promotions,
    finalizeForm,
});

export const receiveSubmittedPromotions = payload => ({
    type: PROMOTIONS_SUBMIT_RECEIVED,
    payload,
});

export const errorSubmittedPromotions = (error, correlationId: string) => ({
    type: PROMOTIONS_SUBMIT_FAILED,
    error,
    correlationId,
});

export const receivedPromotionUpdate = payload => ({
    type: PROMOTION_UPDATE_RECEIVED,
    payload: { data: payload },
});

export const errorPromotionUpdate = requestAction => ({
    type: PROMOTION_UPDATE_FAILED,
    error: new Error('Failed to update Promotion.'),
    requestAction,
});

export const requestPromotion = id => ({
    type: PROMOTION_REQUESTED,
    id,
});
export const receivePromotion = payload => ({
    type: PROMOTION_RECEIVED,
    payload,
});
export const errorPromotionRequest = requestAction => ({
    type: PROMOTION_REQUEST_FAILED,
    error: new Error('Failed to retrieve the Promotion'),
    requestAction,
});

export const resetPromotions = () => ({
    type: PROMOTION_REQUEST_RESET,
});

/**
 * PROMOTION OFFERS
 */

export const requestPromotionOfferCreation = (promotionId: number, promotionOffer: PromotionOffer) => ({
    type: PROMOTION_OFFER_CREATE_REQUESTED,
    promotionId,
    promotionOffer,
});

export const receivedPromotionOfferCreation = payload => ({
    type: PROMOTION_OFFER_CREATE_RECEIVED,
    payload, //: { data: payload },
});

export const errorPromotionOfferCreation = (requestAction, error, correlationId: string) => ({
    type: PROMOTION_OFFER_CREATE_FAILED,
    error,
    requestAction,
    correlationId,
});

export const requestPromotionDefaultMedia = () => ({
    type: PROMOTION_DEFAULT_MEDIA_REQUESTED,
});

export const receivePromotionDefaultMedia = payload => ({
    type: PROMOTION_DEFAULT_MEDIA_RECEIVED,
    payload,
});
export const errorPromotionDefaultMediaRequest = requestAction => ({
    type: PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED,
    error: new Error('Failed to retrieve the Promotion Default Media'),
    requestAction,
});
