import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Theme, Typography } from '@mui/material';
import { BnclOffer, BnclOfferStatus, VendorCampaignStatus } from 'apis/bnclCampaign';
import ColoredIconButton from 'components/common/ColoredIconButton/ColoredIconButton';
import BigSwitch from 'components/common/Form/BigSwitch';
import { format as formatDate } from 'date-fns';
import _ from 'lodash';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';
import BnclOfferForm, { BnclOfferFormResult, BnclOfferHandlers } from './BnclOfferForm';

type BnclOfferAccordionProps = {
    vendor: Vendor;
    offers?: BnclOffer[];
    campaignStatus?: VendorCampaignStatus;
};

export type BnclOfferResult = BnclOfferFormResult & {
    status: BnclOfferStatus;
    currency: string;
};

export interface BnclAccordionHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<BnclOfferResult[] | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    accordion: {
        boxShadow: theme.shadows[3],
    },
    summary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > p': {
            fontSize: '16px !important',
            '&:nth-of-type(1)': {
                flexGrow: 0.2,
            },
            '&:nth-of-type(2)': {
                flex: '0.2 0 85px',
            },
            '&:nth-of-type(3)': {
                flexGrow: 1,
            },
        },
        color: theme.palette.grey[600],
    },
    actionsBlock: {
        textAlign: 'right',
        '& button': {
            marginLeft: theme.spacing(0.5),
        },
    },
    moveBtn: {
        marginLeft: '12px !important',
    },
    inactiveOffer: {
        textDecoration: 'line-through',
        color: theme.palette.text.disabled,
    },
    details: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: 0,
    },
    addOffer: {
        marginTop: theme.spacing(1),
        float: 'right',
    },
}));

function BnclOfferAccordion(
    { vendor, offers, campaignStatus = VendorCampaignStatus.DRAFT }: BnclOfferAccordionProps,
    ref,
): React.ReactElement {
    const { classes, cx } = useStyles();

    const offerRefs = useRef<Record<string, BnclOfferHandlers>>({});
    const [offersMap, setOffersMap] = useState<Record<string, BnclOffer | null>>({});
    const [offerStatuses, setOfferStatuses] = useState<Record<string, BnclOfferStatus>>({});
    const [expandedOffers, setExpandedOffers] = useState<string[]>([]);
    const [offerOrder, setOfferOrder] = useState<string[]>([]);

    useImperativeHandle(
        ref,
        (): BnclAccordionHandlers => ({
            submit: async () => {
                const forms = await Promise.all(offerOrder.map(id => offerRefs.current[id].submit()));
                const mappedForms = forms.map((v, idx) => ({
                    v,
                    id: offerOrder[idx],
                    status: offerStatuses[offerOrder[idx]],
                }));
                const errors = mappedForms.filter(v => !v.v);
                if (errors.length) {
                    setExpandedOffers(errors.map(v => v.id));
                    return;
                }

                return mappedForms.map(data => ({
                    ...(data.v as BnclOfferFormResult),
                    status: data.status,
                    currency: vendor.currency.abbreviation,
                }));
            },
            isModified: () => {
                const formsModified = offerOrder.map(id => ({
                    id,
                    isModified: offerRefs.current[id].isModified(),
                }));
                const hasModifiedForm = formsModified.some(v => v.isModified);

                const initialOrder = offers ? offers.map((_, idx) => `exist-${idx}`) : [];
                const isOrderModified = offers ? !_.isEqual(initialOrder, offerOrder) : offerOrder.length > 1;

                let initialStatuses: Record<string, BnclOfferStatus>;
                if (offers) {
                    initialStatuses = offers.reduce(
                        (acc, offer, idx) => ({
                            ...acc,
                            [`exist-${idx}`]: offer.status,
                        }),
                        {} as Record<string, BnclOfferStatus>,
                    );
                } else {
                    initialStatuses = offerOrder.reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: BnclOfferStatus.ACTIVE,
                        }),
                        {} as Record<string, BnclOfferStatus>,
                    );
                }
                const isStatusModified = !_.isEqual(offerStatuses, initialStatuses);

                if (hasModifiedForm) {
                    setExpandedOffers(formsModified.filter(f => f.isModified).map(f => f.id));
                }

                return hasModifiedForm || isOrderModified || isStatusModified;
            },
        }),
    );

    const onAdd = () => {
        const accordionId = `new-${uuidv4()}`;
        setOffersMap({
            ...offersMap,
            [accordionId]: null,
        });
        setExpandedOffers([...expandedOffers, accordionId]);
        setOfferOrder([...offerOrder, accordionId]);
        setOfferStatuses({
            ...offerStatuses,
            [accordionId]: BnclOfferStatus.ACTIVE,
        });
    };

    useEffect(() => {
        const newOffersMap: Record<string, BnclOffer> = {};
        const newStatuses: Record<string, BnclOfferStatus> = {};

        const list = offers || [];

        list.forEach((offer, idx) => {
            const id = `exist-${idx}`;
            newOffersMap[id] = offer;
            newStatuses[id] = offer.status;
        });
        setOffersMap(newOffersMap);
        setOfferStatuses(newStatuses);
        setExpandedOffers(Object.keys(newOffersMap).filter(key => newOffersMap[key].status === BnclOfferStatus.ACTIVE));
        setOfferOrder(Object.keys(newOffersMap));

        if (!offers) {
            onAdd();
        }
    }, [offers]);

    const onRemove = (id: string) => {
        const newMap = {
            ...offersMap,
        };
        delete newMap[id];
        setOffersMap(newMap);
        setExpandedOffers(expandedOffers.filter(key => key !== id));
        setOfferOrder(offerOrder.filter(key => key !== id));
        const newStatuses = {
            ...offerStatuses,
        };
        delete newStatuses[id];
        setOfferStatuses(newStatuses);
    };

    const expandChange = (id: string, expanded: boolean) => {
        if (expanded) {
            setExpandedOffers([...expandedOffers, id]);
        } else {
            setExpandedOffers(expandedOffers.filter(key => key !== id));
        }
    };

    const onOrderChange = (id: string, idx: number) => {
        const newOfferOrder = offerOrder.filter(key => key !== id);
        newOfferOrder.splice(idx, 0, id);
        setOfferOrder(newOfferOrder);
    };

    const onStatusChange = (id: string) => {
        setOfferStatuses({
            ...offerStatuses,
            [id]: offerStatuses[id] === BnclOfferStatus.ACTIVE ? BnclOfferStatus.INACTIVE : BnclOfferStatus.ACTIVE,
        });
    };

    return (
        <Box>
            <Box component="h2" className={classes.title}>
                Offer Blocks
            </Box>
            {offerOrder.map((accordionId, idx) => {
                const isExpanded = expandedOffers.includes(accordionId);
                const offer = offersMap[accordionId];
                const isDeleteShown =
                    (campaignStatus === VendorCampaignStatus.DRAFT || !offer) && offerOrder.length > 1;

                return (
                    <Accordion
                        key={accordionId}
                        expanded={isExpanded}
                        onChange={(ev, expanded) => expandChange(accordionId, expanded)}
                        className={classes.accordion}
                        data-cy="Bncl-OfferBlock"
                    >
                        <AccordionSummary data-cy="Bncl-OfferBlockSummary">
                            <Box className={classes.summary}>
                                <Typography
                                    className={cx({
                                        [classes.title]: true,
                                        [classes.inactiveOffer]:
                                            offerStatuses[accordionId] === BnclOfferStatus.INACTIVE,
                                    })}
                                >
                                    Offer Block {idx + 1}
                                </Typography>
                                {!isExpanded && offer ? (
                                    <>
                                        <Typography>
                                            {vendor.currency.symbol} {offer.voucherPrice}
                                        </Typography>
                                        <Typography>
                                            {formatDate(new Date(offer.expirationDate), 'dd/LL/yyyy, H:mm')}
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}

                                <Box className={classes.actionsBlock} onClick={event => event.stopPropagation()}>
                                    <BigSwitch
                                        checked={offerStatuses[accordionId] === BnclOfferStatus.ACTIVE}
                                        onChange={() => onStatusChange(accordionId)}
                                        data-cy="Bncl-ChangeOfferStatus"
                                    />
                                    {isDeleteShown && (
                                        <ColoredIconButton
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => onRemove(accordionId)}
                                            data-cy="Bncl-DeleteOffer"
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </ColoredIconButton>
                                    )}
                                    <ColoredIconButton
                                        className={classes.moveBtn}
                                        size="small"
                                        variant="outlined"
                                        disabled={idx === 0}
                                        onClick={() => onOrderChange(accordionId, idx - 1)}
                                        data-cy="Bncl-MoveOfferUp"
                                    >
                                        <ArrowDropUpIcon fontSize="small" />
                                    </ColoredIconButton>

                                    <ColoredIconButton
                                        size="small"
                                        variant="outlined"
                                        disabled={idx === offerOrder.length - 1}
                                        onClick={() => onOrderChange(accordionId, idx + 1)}
                                        data-cy="Bncl-MoveOfferDown"
                                    >
                                        <ArrowDropDownIcon fontSize="small" />
                                    </ColoredIconButton>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <BnclOfferForm
                                ref={(ref: BnclOfferHandlers) => (offerRefs.current[accordionId] = ref)}
                                offer={offer}
                                vendor={vendor}
                                campaignStatus={campaignStatus}
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            <Button
                startIcon={<AddIcon />}
                variant="text"
                className={classes.addOffer}
                data-cy="Bncl-AddOfferButton"
                onClick={onAdd}
            >
                Add Offer Block
            </Button>
        </Box>
    );
}

export default forwardRef(BnclOfferAccordion);
