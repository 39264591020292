import reduceReducers from 'reduce-reducers';
import { RequestState } from './RequestState';

/**
 * @returns {import("redux").Reducer<any, any>}
 */
export const createGenericReducer = (
    requestedActionType,
    receivedActionType,
    failedActionType,
    resetActionType,
    customReducer,
    customInitialState,
) => {
    const initialState = {
        requestState: RequestState.NotRequested,
    };
    const genericReducer = (state = customInitialState || initialState, action) => {
        switch (action.type) {
            case requestedActionType:
                return { ...state, ...action.payload, requestState: RequestState.InProgress };
            case receivedActionType:
                return { ...state, ...action.payload, requestState: RequestState.Finished };
            case failedActionType:
                return {
                    ...state,
                    error: action.error,
                    requestState: RequestState.Failed,
                    correlationId: action.correlationId,
                };
            case resetActionType:
                return customInitialState || initialState;
            default:
                return state;
        }
    };

    return customReducer ? reduceReducers(genericReducer, customReducer) : genericReducer;
};
