import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import * as React from 'react';

export type FormikSelectProps = SelectProps & {
    formik: any;
    options: Array<{ label: string; value: any }>;
    onChange?: (val: unknown) => void;
    label?: string;
};

export const FormikSelect = ({
    formik,
    name,
    options,
    onChange,
    label,
    fullWidth,
    ...props
}: FormikSelectProps): React.ReactElement => {
    if (!name) throw Error('name prop is required');

    const hasError = !!formik.touched[name] && !!formik.errors[name];

    const handleChange: SelectInputProps['onChange'] = (ev, ...rest) => {
        formik.handleChange(ev, ...rest);
        if (onChange) onChange(ev.target.value);
    };

    return (
        <FormControl style={fullWidth ? { width: '100%' } : {}} error={hasError}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select name={name} value={formik.values[name]} onChange={handleChange} fullWidth={fullWidth} {...props}>
                {options.map(({ label, value }, idx) => (
                    <MenuItem key={idx} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {hasError && <FormHelperText>{formik.errors[name]}</FormHelperText>}
        </FormControl>
    );
};
