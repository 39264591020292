/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getMerchandisingSlotPositions } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { all, call, put, takeEvery } from 'redux-saga/effects';

export const MERCHANDISING_SLOTS_POSITIONS_REQUESTED = 'MERCHANDISING_SLOTS_POSITIONS_REQUESTED';
export const MERCHANDISING_SLOTS_POSITIONS_RECEIVED = 'MERCHANDISING_SLOTS_POSITIONS_RECEIVED';
export const MERCHANDISING_SLOTS_POSITIONS_REQUEST_FAILED = 'MERCHANDISING_SLOTS_POSITIONS_REQUEST_FAILED';

export const requestMerchandisingSlotsPositions = () => ({
    type: MERCHANDISING_SLOTS_POSITIONS_REQUESTED,
});
export const receiveMerchandisingSlotsPositions = payload => ({
    type: MERCHANDISING_SLOTS_POSITIONS_RECEIVED,
    payload,
});
export const errorMerchandisingSlotsPositions = requestAction => ({
    type: MERCHANDISING_SLOTS_POSITIONS_REQUEST_FAILED,
    error: new Error('Failed to retrieve merchandising slots positions.'),
    requestAction,
});

export const getMerchandisingSlotsPositionsReducer = createGenericReducer(
    MERCHANDISING_SLOTS_POSITIONS_REQUESTED,
    MERCHANDISING_SLOTS_POSITIONS_RECEIVED,
    MERCHANDISING_SLOTS_POSITIONS_REQUEST_FAILED,
);

export function* doRequestMerchandisingSlotsPositions(action, requestInfo: RequestInfo) {
    const merchandisingSlotsPositions = yield call(getMerchandisingSlotPositions, {});
    yield put(receiveMerchandisingSlotsPositions(merchandisingSlotsPositions));
}

export function* watchMerchandisingSlotsStore() {
    yield all([
        takeEvery(
            MERCHANDISING_SLOTS_POSITIONS_REQUESTED,
            doCall(doRequestMerchandisingSlotsPositions, MERCHANDISING_SLOTS_POSITIONS_REQUEST_FAILED),
        ),
    ]);
}
