import {
    createLandingPage,
    deleteLandingPage,
    getLandingPage,
    getLandingPagesByCampaignId,
    updateLandingPage,
} from 'apis/campaignApi';
import { finalizeCampaignFormLandingPages } from 'application/campaignForm/campaignCreate/campaignFormStore';
import { requestCampaign } from 'application/campaignForm/campaignCreate/requestedCampaignStore';
import {
    errorDeleteLandingPage,
    errorSubmittedLandingPages,
    receivedDeleteLandingPage,
    receivedLandingPageCreation,
    receivedLandingPageUpdate,
    receiveLandingPage,
    receiveLandingPages,
    receiveSubmittedLandingPages,
} from 'application/campaignForm/landingPageCreate/landingPageCreateActions';
import {
    LANDING_PAGES_REQUESTED,
    LANDING_PAGES_REQUEST_FAILED,
    LANDING_PAGES_SUBMIT_FAILED,
    LANDING_PAGES_SUBMIT_REQUESTED,
    LANDING_PAGE_CREATE_FAILED,
    LANDING_PAGE_CREATE_REQUESTED,
    LANDING_PAGE_DELETE_FAILED,
    LANDING_PAGE_DELETE_REQUESTED,
    LANDING_PAGE_REQUESTED,
    LANDING_PAGE_REQUEST_FAILED,
    LANDING_PAGE_UPDATE_FAILED,
    LANDING_PAGE_UPDATE_REQUESTED,
} from 'application/campaignForm/landingPageCreate/landingPageCreateTypes';
import { doSubmitPromotions } from 'application/campaignForm/promotionCreate/promotionCreateSaga';
import { doCall } from 'common/genericSaga';
import { CampaignSubmissionData } from 'common/genericTypes';
import ResponseError from 'errors/ResponseError';
import { LandingPage } from 'models/landingPage';
import { Promotion } from 'models/promotion';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
export const getLandingPages = (state): LandingPage[] | null => state.landingPages;
export const getCampaign = (state): LandingPage[] | null => state.campaignForm;

export function* doRequestLandingPage(action, requestInfo) {
    const landingPages = yield select(getLandingPages);
    const landingPage = yield call(getLandingPage, action.id);
    const updatedLandingPages: Array<LandingPage> = [...landingPages.data];
    updatedLandingPages.push(landingPage.data);
    yield put(receiveLandingPage({ data: updatedLandingPages }));
}

export function* doRequestLandingPages(action: { campaignId: number }, requestInfo) {
    const landingPages = yield call(getLandingPagesByCampaignId, action.campaignId);
    yield put(receiveLandingPages({ data: landingPages.data }));
}

export function* doSubmitLandingPage(action, requestInfo) {
    const landingPages = yield select(getLandingPages);
    const createdLandingPage = yield call(createLandingPage, action.landingPage);
    const updatedLandingPages: Array<LandingPage> = [...landingPages.data];
    updatedLandingPages.push(createdLandingPage.data);
    yield put(receivedLandingPageCreation(createdLandingPage.data));
    yield put(receiveLandingPage({ data: updatedLandingPages }));
}

interface SubmitLandingPageAction {
    landingPages: CampaignSubmissionData<LandingPage>;
    promotions: Record<number, CampaignSubmissionData<Promotion>>;
    finalizeForm: boolean;
}

export function* doSubmitLandingPages(action: SubmitLandingPageAction, requestInfo) {
    //create landing page
    const landingPages: { data: Array<LandingPage> } = { data: [] };
    for (const itemsAction of ['itemsToCreate', 'itemsToUpdate']) {
        if (action.landingPages[itemsAction].length) {
            for (const landingPageObject of action.landingPages[itemsAction]) {
                try {
                    const persistedLandingPage: { data: LandingPage } = yield call(
                        itemsAction === 'itemsToCreate' ? createLandingPage : updateLandingPage,
                        landingPageObject,
                    );

                    const lpPromotions = action.promotions[persistedLandingPage.data.vendor_id as number];

                    //handle promotions
                    if (lpPromotions && persistedLandingPage.data.is_dedicated) {
                        const formattedPromotions = lpPromotions.itemsToCreate.map(promotion => {
                            return { ...promotion, landing_page_id: persistedLandingPage.data.id as number };
                        });
                        lpPromotions.itemsToCreate = formattedPromotions;

                        yield call(doSubmitPromotions, { promotions: lpPromotions }, requestInfo);

                        const lpWithPromos = yield call(getLandingPage, persistedLandingPage.data.id);
                        persistedLandingPage.data = lpWithPromos.data;
                    }

                    landingPages.data.push(persistedLandingPage.data);
                } catch (error) {
                    if (error instanceof ResponseError) {
                        yield put(errorSubmittedLandingPages(error, requestInfo.correlationId));
                        return;
                    }

                    throw error;
                }
            }
        }
    }

    yield put(receiveSubmittedLandingPages(landingPages));
    yield put(receiveLandingPages(landingPages));
    if (landingPages.data.length) {
        yield put(requestCampaign(landingPages.data[0].campaign_id));
    }
    if ('finalizeForm' in action && action.finalizeForm === true) {
        yield put(finalizeCampaignFormLandingPages(landingPages));
    }
}

export function* doUpdateLandingPage(action, requestInfo) {
    const updatedLandingPage = yield call(updateLandingPage, action.landingPageData);
    const landingPages: { data: LandingPage[] } = yield select(getLandingPages);
    const updatedLandingPages = { ...landingPages };
    const elementsIndex = landingPages.data.findIndex(element => element.id === action.landingPageData.id);
    updatedLandingPages.data[elementsIndex] = Object.assign(
        updatedLandingPages.data[elementsIndex],
        updatedLandingPage.data,
    );
    yield put(receivedLandingPageUpdate(updatedLandingPages.data));
}

export function* doDeleteLandingPage(action, requestInfo) {
    try {
        yield call(deleteLandingPage, action.id);
        const landingPages = yield select(getLandingPages);

        const updatedLandingPages: Array<LandingPage> = [...landingPages.data].filter(
            element => element.is_dedicated !== false,
        );

        yield put(receivedDeleteLandingPage({ data: updatedLandingPages }));
        yield put(requestCampaign(landingPages.data[0].campaign_id));
    } catch (error) {
        if (error instanceof ResponseError) {
            yield put(errorDeleteLandingPage(error));
            return;
        }
        throw error;
    }
}

export function* watchLandingPageCreationConfig() {
    yield all([
        takeLatest(LANDING_PAGE_REQUESTED, doCall(doRequestLandingPage, LANDING_PAGE_REQUEST_FAILED)),
        takeEvery(LANDING_PAGE_CREATE_REQUESTED, doCall(doSubmitLandingPage, LANDING_PAGE_CREATE_FAILED)),
        takeEvery(LANDING_PAGE_DELETE_REQUESTED, doCall(doDeleteLandingPage, LANDING_PAGE_DELETE_FAILED)),
        takeEvery(LANDING_PAGE_UPDATE_REQUESTED, doCall(doUpdateLandingPage, LANDING_PAGE_UPDATE_FAILED)),
        takeEvery(LANDING_PAGES_SUBMIT_REQUESTED, doCall(doSubmitLandingPages, LANDING_PAGES_SUBMIT_FAILED)),
        takeLatest(LANDING_PAGES_REQUESTED, doCall(doRequestLandingPages, LANDING_PAGES_REQUEST_FAILED)),
    ]);
}
