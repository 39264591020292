import { DependencyList, useEffect } from 'react';

export default function useThrottleCallback(callback: () => void, deps?: DependencyList, ms = 600): void {
    useEffect(() => {
        const timeout = setTimeout(callback, ms);
        return () => {
            clearTimeout(timeout);
        };
    }, deps);
}
