import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Channel, getChannels } from 'apis/attributionApi/channels';
import { getMediums, Medium } from 'apis/attributionApi/mediums';
import { getSources, postSource, Source, SourcesCreateRequest } from 'apis/attributionApi/sources';
import { createGenericExtraReducers } from 'common/createGenericExtraReducers';
import { RequestType } from 'common/RequestState';

type AttributionsState = {
    channels: RequestType<Channel[]>;
    mediums: RequestType<Medium[]>;
    sources: RequestType<Source[]>;
    sourcesCreateRequest: RequestType<Source[]>;
};

const alphabeticSort = (a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });

export const fetchChannels = createAsyncThunk('attributions/fetchChannels', async () => {
    const channels = await getChannels();
    channels.forEach(c => (c.sources = c.sources.sort(alphabeticSort)));
    return channels.sort(alphabeticSort);
});

export const fetchMediums = createAsyncThunk('attributions/fetchMediums', async () => {
    const mediums = await getMediums();
    return mediums.sort(alphabeticSort);
});

export const fetchSources = createAsyncThunk('attributions/fetchSources', async () => {
    const sources = await getSources();
    return sources.sort(alphabeticSort);
});

export const createSource = createAsyncThunk(
    'attributions/createSource',
    async (createRequest: SourcesCreateRequest) => {
        return await postSource(createRequest);
    },
);

export const attributionsSlice = createSlice({
    name: 'attributions',
    initialState: {} as AttributionsState,
    reducers: {},
    extraReducers: builder => {
        createGenericExtraReducers(builder, fetchChannels, 'channels');
        createGenericExtraReducers(builder, fetchMediums, 'mediums');
        createGenericExtraReducers(builder, fetchSources, 'sources');
        createGenericExtraReducers(builder, createSource, 'sourcesCreateRequest');
    },
});
