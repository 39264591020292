import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export interface RobotsUpdate {
    vendor: string;
    content: string;
}

export interface SitemapRoute {
    route: string;
    isIncluded: boolean;
    isIncludedForImages: boolean;
    isIncludedForVideos: boolean;
    metaTagsHash: string;
    bodyHash: string;
    lastModified: string;
}

export type SeoFileType = {
    [name: string]: string;
};

export const postSeoFiles = async (body): Promise<any> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoApiUrl', 'POST', 'robots', body, {}, token);
};

export const getSeoFiles = async (): Promise<SeoFileType[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoApiUrl', 'GET', 'robots', null, {}, token);
};

export const getVendorSitemap = async (vendorName: string): Promise<SitemapRoute[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoApiUrl', 'GET', `sitemap/${vendorName}`, null, {}, token);
};

export const putVendorSitemap = async (vendorName: string, routes: SitemapRoute[]): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoApiUrl', 'PATCH', `sitemap/${vendorName}`, { routes }, {}, token);
};
