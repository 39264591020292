export function preventBrowserClose(): () => void {
    function handler(event) {
        event.preventDefault();
        event.returnValue = true;
    }

    window.addEventListener('beforeunload', handler, true);

    return () => {
        window.removeEventListener('beforeunload', handler, true);
    };
}
