import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AddSourcePage from 'components/attribution/AddSourcePage/AddSourcePage';
import B2BOverview from 'components/b2b/accounts/Accounts';
import { AlbelliAuthBoundary } from 'components/common/AlbelliAuthBoundary/AlbelliAuthBoundary';
import { Bootstrap } from 'components/common/Bootstrap/Bootstrap';
import CustomBrowserRouter from 'components/common/CustomBrowserRouter/CustomBrowserRouter';
import PrivateRoute from 'components/common/CustomBrowserRouter/PrivateRoute';
import { CustomTheme, GlobalCss } from 'components/common/CustomTheme/CustomTheme';
import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { Header } from 'components/common/Header/Header';
import { Home } from 'components/common/Home/Home';
import { Loader } from 'components/common/Loader/Loader';
import SideNavRenderer from 'components/common/SideNavRenderer/SideNavRenderer';
import LODOverview from 'components/lod/LODOverview/LODOverview';
import SEOOverview from 'components/seo/SEOOverview/SEOOverview';
import BnclCampaigns from 'components/vouchers/bncl/Campaigns';
import CreateBnclCampaign from 'components/vouchers/bncl/CreateCampaign';
import EditBnclCampaign from 'components/vouchers/bncl/EditCampaign';
import { lodMomentRoutes } from 'config/lodMomentRoutes';
import React, { ReactElement, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { routes } from 'routes';
import { activeDirectoryServiceInstance } from 'services/activeDirectoryService';
import store from 'store';
import { makeStyles } from 'tss-react/mui';

const SEORobots = lazy(() => import('components/seo/Robots/SEORobots'));
const LODSettings = lazy(() => import('components/lod/Settings/LODSettings'));
const Sitemap = lazy(() => import('components/seo/Sitemap/Sitemap'));
const Redirects = lazy(() => import('components/seo/Redirects/Redirects'));
const SeoPages = lazy(() => import('components/seo/Pages/Pages'));
const CreateCustomPage = lazy(() => import('components/seo/Pages/CreateCustomPage'));
const EditCustomPage = lazy(() => import('components/seo/Pages/EditCustomPage'));
const SfpMetadataEditor = lazy(() => import('components/seo/Pages/SfpEditor'));
const Campaigns = lazy(() => import('components/campaigns/Campaigns/Campaigns'));
const DiscountForm = lazy(() => import('components/discounts/DiscountForm/DiscountForm'));
const Discounts = lazy(() => import('components/discounts/Discounts'));
const CampaignForm = lazy(() => import('components/campaigns/CampaignForm/CampaignForm'));
const CampaignListWrapper = lazy(() => import('components/campaigns/CampaingListWrapper/CampaignListWrapper'));

const useStyles = makeStyles()(() => ({
    container: {
        flexGrow: 1,
        marginLeft: 71,
    },
}));

export const renderIcon = (iconName, props): ReactElement => {
    const IconName = iconName;
    return <IconName {...props} />;
};

const App: React.FC = (): ReactElement => {
    const { classes } = useStyles();

    return (
        <Provider store={store}>
            <ErrorBoundary>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={CustomTheme}>
                        {GlobalCss}
                        <CustomBrowserRouter>
                            <Bootstrap>
                                <Header
                                    userInfo={activeDirectoryServiceInstance.userInfo}
                                    logout={activeDirectoryServiceInstance.logout}
                                />
                                <SideNavRenderer></SideNavRenderer>
                                <main className={classes.container}>
                                    <AlbelliAuthBoundary>
                                        <Suspense fallback={<Loader />}>
                                            <Switch>
                                                <PrivateRoute exact path={routes.addAttributionSource}>
                                                    <AddSourcePage />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.seo}>
                                                    <SEOOverview />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.lod}>
                                                    <LODOverview />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.b2b}>
                                                    <B2BOverview />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.robots}>
                                                    <SEORobots />
                                                </PrivateRoute>
                                                {Object.values(lodMomentRoutes).map((lodRouteObject, index) => (
                                                    <PrivateRoute key={index} exact path={lodRouteObject.path}>
                                                        <LODSettings moment={lodRouteObject.name} />
                                                    </PrivateRoute>
                                                ))}
                                                <PrivateRoute exact path={routes.sitemap}>
                                                    <Sitemap />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.redirects}>
                                                    <Redirects />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.seoPages}>
                                                    <SeoPages />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.seoPagesEditorNew}>
                                                    <CreateCustomPage />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.seoPagesEditorEdit}>
                                                    <EditCustomPage />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.sfpMetadataEdit}>
                                                    <SfpMetadataEditor />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.campaigns}>
                                                    <Campaigns />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={[routes.discountsNew, routes.discountsEdit]}>
                                                    <DiscountForm />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.discounts}>
                                                    <Discounts />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={[routes.campaignsNew, routes.campaignsEdit]}>
                                                    <CampaignForm />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.campaignsChannel}>
                                                    <CampaignListWrapper />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.vouchers}>
                                                    <div />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.bnclCampaigns}>
                                                    <BnclCampaigns />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.newBnclCampaign}>
                                                    <CreateBnclCampaign />
                                                </PrivateRoute>
                                                <PrivateRoute exact path={routes.editBnclCampaign}>
                                                    <EditBnclCampaign />
                                                </PrivateRoute>
                                                <PrivateRoute path={routes.home}>
                                                    <Home />
                                                </PrivateRoute>
                                            </Switch>
                                        </Suspense>
                                    </AlbelliAuthBoundary>
                                </main>
                            </Bootstrap>
                        </CustomBrowserRouter>
                    </ThemeProvider>
                </StyledEngineProvider>
            </ErrorBoundary>
        </Provider>
    );
};
export default App;
