import { CribOutlined, FavoriteBorder, Man2Outlined, ParkOutlined, Woman2Outlined } from '@mui/icons-material';

export const lodMomentRoutes = {
    christmas: {
        name: 'christmas',
        path: '/lod/christmas',
        icon: ParkOutlined,
        color: '#cd051f',
    },
    valentines: {
        name: 'valentines',
        path: '/lod/valentines',
        icon: FavoriteBorder,
        color: '#f25f70',
    },
    mothersday: {
        name: 'mothersday',
        path: '/lod/mothersday',
        icon: Woman2Outlined,
        color: '#68478d',
    },
    fathersday: {
        name: 'fathersday',
        path: '/lod/fathersday',
        icon: Man2Outlined,
        color: '#489fdf',
    },
    reyes: {
        name: 'reyes',
        path: '/lod/reyes',
        icon: CribOutlined,
        color: '#ebb923',
    },
};
