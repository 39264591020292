import { ListItem, ListItemIcon, Typography } from '@mui/material';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { renderIcon } from 'components/common/App/App';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { SideNavigationItem } from 'components/common/SideNavRenderer/sideNavItems';

const useStyles = makeStyles()(theme => ({
    listItemIcon: {
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        // maxWidth: `calc(100% - ${drawerWidth}px)`,
        alignItems: 'center',
    },
    listItem: {
        textAlign: 'center',
        padding: `${theme.spacing(2)} 0`,
        '&:hover': {
            background: CustomColors.navHover,
        },
    },
    listItemSelected: {
        backgroundColor: 'rgba(216, 216, 216, 0.3)',
    },
    icon: {
        fontSize: 35,
    },
    iconText: {
        marginTop: theme.spacing(1),
        fontSize: 11,
    },
    listLink: {
        textDecoration: 'none',
        paddingLeft: theme.spacing(1),
    },
}));

const SideNavItem: React.FC<SideNavigationItem> = (props: SideNavigationItem) => {
    const { classes, cx } = useStyles();

    return (
        <ListItem
            alignItems="center"
            disableGutters
            className={cx({
                [classes.listItem]: true, //always apply
                [classes.listItemSelected]: props.selected, //only when open === true
            })}
        >
            <NavLink
                to={props.url}
                className={classes.listLink}
                data-cy={`SideNavItem-NavLink-${props.text.replace(/ /g, '')}`}
            >
                <ListItemIcon className={classes.listItemIcon} style={{ maxWidth: 'calc(100% - 74px)' }}>
                    {renderIcon(props.icon, { className: classes.icon })}
                    <Typography variant="caption" display="block" className={classes.iconText}>
                        {props.text}
                    </Typography>
                </ListItemIcon>
            </NavLink>
        </ListItem>
    );
};

export default SideNavItem;
