/**
 * Generates a weak/unsafe random string (not cryptographically strong)
 * @param  {number} characters
 * @return {String}
 */
export const getRandomString = (characters: number): string => {
    return [...Array(characters)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
};

/**
 * Swaps key/value for a given object
 * @param object
 */
export const swap = (object: Record<any, any>): Record<any, any> => {
    const ret = {};
    for (const key in object) {
        ret[object[key]] = key;
    }
    return ret;
};

/**
 * Sorts an array of objects by key
 */
export const sortByKey = (array: Record<string, any>[], key: string): Record<string, any>[] => {
    return array.sort(function (a, b) {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
};
