/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createCategory, deleteCategory, updateCategory } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { all, call, put, takeEvery } from 'redux-saga/effects';

export const CATEGORIES_SUBMIT_REQUESTED = 'CATEGORIES_SUBMIT_REQUESTED';
export const CATEGORIES_SUBMIT_RECEIVED = 'CATEGORIES_SUBMIT_RECEIVED';
export const CATEGORIES_SUBMIT_REQUEST_FAILED = 'CATEGORIES_SUBMIT_REQUEST_FAILED';

export const submitCategories = categories => ({
    type: CATEGORIES_SUBMIT_REQUESTED,
    categories,
});
export const receiveSubmittedCategories = payload => {
    return {
        type: CATEGORIES_SUBMIT_RECEIVED,
        payload,
    };
};
export const errorSubmittedCategories = requestAction => ({
    type: CATEGORIES_SUBMIT_REQUEST_FAILED,
    error: new Error('Failed submitting the Categories.'),
    requestAction,
});

export const submittedCategoriesReducer = createGenericReducer(
    CATEGORIES_SUBMIT_REQUESTED,
    CATEGORIES_SUBMIT_RECEIVED,
    CATEGORIES_SUBMIT_REQUEST_FAILED,
);

export function* doSubmitCategory(submittedCategory) {
    const category = yield call(submittedCategory.id ? updateCategory : createCategory, submittedCategory);

    return category.data;
}

export function* doSubmitCategories(action, requestInfo: RequestInfo) {
    const categories: { data: Array<any> } = { data: [] };

    if (action.categories.itemsToCreate.length > 0) {
        const createdCategories = yield all(
            action.categories.itemsToCreate.map(category => call(doSubmitCategory, category)),
        );
        categories.data = [...categories.data, ...createdCategories];
    }
    if (action.categories.itemsToUpdate.length > 0) {
        const updatedCategories = yield all(
            action.categories.itemsToUpdate.map(category => call(doSubmitCategory, category)),
        );
        categories.data = [...categories.data, ...updatedCategories];
    }
    if (action.categories.itemsToDelete.length > 0) {
        yield all(action.categories.itemsToDelete.map(category => call(deleteCategory, category)));
    }

    yield put(receiveSubmittedCategories(categories));
}

export function* watchSubmittedCategoriesStore() {
    yield all([takeEvery(CATEGORIES_SUBMIT_REQUESTED, doCall(doSubmitCategories, CATEGORIES_SUBMIT_REQUEST_FAILED))]);
}
