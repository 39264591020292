import { Snackbar, SnackbarCloseReason } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import React, { SyntheticEvent } from 'react';
import { makeStyles } from 'tss-react/mui';

const Alert = (props: AlertProps): React.ReactElement => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles()(theme => ({
    root: {
        marginTop: theme.spacing(6),
    },
}));

export interface StatusSnackbarProps {
    open: boolean;
    handleClose: (event: SyntheticEvent<any, Event>) => void;
    text: string;
    severity?: AlertColor;
}

export const StatusSnackbar = ({
    open,
    handleClose,
    text,
    severity = 'success',
}: StatusSnackbarProps): React.ReactElement => {
    const { classes } = useStyles();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose as (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void}
            key="status-snackbar"
            autoHideDuration={3000}
            className={classes.root}
        >
            <div>
                <Alert onClose={handleClose} severity={severity} data-cy="StatusSnackbar-Alert">
                    <strong>{text}</strong>
                </Alert>
            </div>
        </Snackbar>
    );
};
