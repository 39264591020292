export const CAMPAIGN_CURRENT_REQUESTED = 'CAMPAIGN_CURRENT_REQUESTED';
export const CAMPAIGN_CURRENT_RECEIVED = 'CAMPAIGN_CURRENT_RECEIVED';
export const CAMPAIGN_CURRENT_REQUEST_FAILED = 'CAMPAIGN_CURRENT_REQUEST_FAILED';

export const CAMPAIGN_SCHEDULED_REQUESTED = 'CAMPAIGN_SCHEDULED_REQUESTED';
export const CAMPAIGN_SCHEDULED_RECEIVED = 'CAMPAIGN_SCHEDULED_RECEIVED';
export const CAMPAIGN_SCHEDULED_REQUEST_FAILED = 'CAMPAIGN_SCHEDULED_REQUEST_FAILED';

export const CAMPAIGN_EXPIRED_REQUESTED = 'CAMPAIGN_EXPIRED_REQUESTED';
export const CAMPAIGN_EXPIRED_RECEIVED = 'CAMPAIGN_EXPIRED_RECEIVED';
export const CAMPAIGN_EXPIRED_REQUEST_FAILED = 'CAMPAIGN_EXPIRED_REQUEST_FAILED';
