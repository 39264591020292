import { createAsyncThunk } from '@reduxjs/toolkit';
import { AttributionCreate, getAttributionByConditions, postAttribution } from 'apis/attributionApi/attributions';
import { postTrackingCode, TrackingCode } from 'apis/attributionApi/trackingCodes';
import { DiscountCodeType, DiscountDefinition, DiscountStatus } from 'models/discounts/discount';
import { completeFormStep, updateDiscount } from './discountsSlice';

export type DiscountFormSubmission = {
    discount: DiscountDefinition;
    discountCodes: DiscountCodeType[];
};

export const createAttribution = createAsyncThunk(
    'attribution/create',
    async (attributionCreate: AttributionCreate, thunkApi) => {
        const variationsErrors: Record<string, any>[] = [];
        const discount = (thunkApi.getState() as any).discountForm.discount;
        attributionCreate.discountId = discount.id;
        attributionCreate.validFrom = new Date(discount.validFrom);
        attributionCreate.validUntil = new Date(discount.validTo);

        const attribution = !attributionCreate.id
            ? await postAttribution(attributionCreate)
            : await getAttributionByConditions({ discountId: discount.id });

        const createdTrackingCode: TrackingCode[] = [];
        const newTrackingCodes = attributionCreate.trackingCodes.filter(trackingCode => trackingCode.id === null);
        const newTrackingCodesVariations = newTrackingCodes.map(trackingCode => trackingCode.suffix);
        const oldTrackingCodes = attributionCreate.trackingCodes.filter(trackingCode => trackingCode.id !== null);

        if (newTrackingCodesVariations.length) {
            const timestamp = oldTrackingCodes.length
                ? oldTrackingCodes[0].code.split('_').pop()
                : newTrackingCodes[0].code.split('_').pop();

            for (const suffix of newTrackingCodesVariations) {
                try {
                    createdTrackingCode.push(
                        await postTrackingCode({ attributionId: attribution.id, suffix: suffix, timestamp: timestamp }),
                    );
                } catch (error) {
                    variationsErrors.push({ suffix: suffix, error: error });
                }
            }
        } else {
            createdTrackingCode.push(await postTrackingCode({ attributionId: attribution.id }));
        }

        await thunkApi.dispatch(
            updateDiscount({
                discount: { ...discount, status: DiscountStatus.published },
                discountCodes: [],
            }),
        );
        await thunkApi.dispatch(completeFormStep({ currentStep: 2, nextStep: 2 }));

        return {
            ...attribution,
            trackingCodes: [...oldTrackingCodes, ...createdTrackingCode],
            errors: variationsErrors,
        };
    },
);

export const retrieveAttributionByDiscountUuid = createAsyncThunk(
    'attribution/get',
    async (discountUuid: string, thunkApi) => {
        const attribution = await getAttributionByConditions({ discountId: discountUuid });

        return attribution;
    },
);
