import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'common/RequestState';
import { oidc } from 'services/oidcService';

export const login = createAsyncThunk('auth/login', async () => {
    const token = await oidc.getAccessToken();

    if (!token) {
        const oidcManager = oidc.getOidcManager();
        await oidcManager.signin();
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        requestState: RequestState.NotRequested,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(login.pending, state => {
            state.requestState = RequestState.InProgress;
        });
        builder.addCase(login.fulfilled, state => {
            state.requestState = RequestState.Finished;
        });
        builder.addCase(login.rejected, state => {
            state.requestState = RequestState.Failed;
        });
    },
});
