import { getAppConfig } from 'services/configService';
import { subdomainVendors } from '../config/subdomainVendors';
import { betaVendors } from '../config/betaVendors';

export const getStorefrontUrl = (vendorDomain: string, path = ''): string => {
    const prodEnv = getAppConfig().Env === 'prd';
    const subdomainVendor = subdomainVendors.includes(vendorDomain);
    const betaVendor = betaVendors?.includes(vendorDomain);

    if (prodEnv && subdomainVendor) {
        const urlTemplate = getAppConfig().StorefrontUrl.replace(/\b[a-z-]+\./, '');
        return new URL(path, urlTemplate.replace('%vendorDomain%', vendorDomain)).href;
    }

    if (prodEnv && betaVendor) {
        const urlTemplate = getAppConfig().StorefrontUrl.replace(/\b[a-z-]+\./, '');
        return new URL(path, urlTemplate.replace('%vendorDomain%', `beta.${vendorDomain}`)).href;
    }

    const urlTemplate = getAppConfig().StorefrontUrl;
    return new URL(path, urlTemplate.replace('%vendorDomain%', vendorDomain)).href;
};
