import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        fontSize: '2rem',
    },
}));

export function Home(): React.ReactElement {
    const { classes } = useStyles();
    return (
        <Container maxWidth="xl">
            <Box component="div" m={1}>
                <Typography variant="subtitle1" component="h1" className={classes.title}>
                    Admin Panel Home Page
                </Typography>
            </Box>
        </Container>
    );
}
