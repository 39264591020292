import { getAllRoutes } from 'apis/storefront';
import {
    receiveAllRoutes,
    SF_ALL_ROUTES_REQUESTED,
    SF_ALL_ROUTES_REQUEST_FAILED,
} from 'application/storefront/storefrontActions';
import { doCall } from 'common/genericSaga';
import { call, put, takeEvery } from 'redux-saga/effects';

export function* doRequestAllRoutes(action) {
    const routes = yield call(getAllRoutes, action.vendor.name, action.forceCacheUpdate);
    yield put(receiveAllRoutes(routes, action.vendor));
}

export function* watchStorefront() {
    yield takeEvery(SF_ALL_ROUTES_REQUESTED, doCall(doRequestAllRoutes, SF_ALL_ROUTES_REQUEST_FAILED));
}
