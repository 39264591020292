// partition an array based on a callback function and return two arrays
// 1. elements that pass callback validation
// 2. elements that fail callback validation
// https://stackoverflow.com/a/47225591
export function partition(arrayToPartition, callback) {
    return arrayToPartition.reduce(
        ([pass, fail], elem) => {
            return callback(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
        },
        [[], []],
    );
}
