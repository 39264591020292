/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { updateCampaignStatus } from 'apis/campaignApi';
import { resetLandingPages } from 'application/campaignForm/landingPageCreate/landingPageCreateActions';
import { receivedParseDiscountCode } from 'application/campaignForm/offerCreate/discountCodesStore';
import {
    receivedOffers,
    receivedOfferTemporaryCreation,
} from 'application/campaignForm/offerCreate/offerCreateActions';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { RequestState } from 'common/RequestState';
import { isJson } from 'helpers/stringHelper';
import { CampaignForm, CampaignStatus } from 'models/campaign';
import { LandingPage } from 'models/landingPage';
import { Offer } from 'models/offer';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAppConfig } from 'services/configService';
import { CAMPAIGN_RECEIVED, requestCampaign, resetCampaignRequest } from './requestedCampaignStore';
import { showStatusMessage } from './visualFeedbackStore';

export const CAMPAIGN_FORM_SUBMITTED = 'CAMPAIGN_FORM_SUBMITTED';
export const CAMPAIGN_FORM_OFFERS_FINALIZED = 'CAMPAIGN_FORM_OFFERS_FINALIZED';
export const CAMPAIGN_FORM_LANDING_PAGES_FINALIZED = 'CAMPAIGN_FORM_LANDING_PAGES_FINALIZED';
export const CAMPAIGN_FORM_FINALIZE_REQUEST = 'CAMPAIGN_FORM_FINALIZE_REQUEST';
export const CAMPAIGN_FORM_FINALIZED = 'CAMPAIGN_FORM_FINALIZED';
export const CAMPAIGN_FORM_SWITCH_STEP = 'CAMPAIGN_FORM_SWITCH_STEP';
export const CAMPAIGN_FORM_RESET = 'CAMPAIGN_FORM_RESET';
export const CAMPAIGN_FORM_RESET_FAILED = 'CAMPAIGN_FORM_RESET_FAILED';

export const CAMPAIGN_STATUS_CHANGE_REQUESTED = 'CAMPAIGN_STATUS_CHANGE_REQUESTED';
export const CAMPAIGN_STATUS_CHANGE_RECEIVED = 'CAMPAIGN_STATUS_CHANGE_RECEIVED';
export const CAMPAIGN_STATUS_CHANGE_FAILED = 'CAMPAIGN_STATUS_CHANGE_FAILED';
export const CAMPAIGN_STATUS_CHANGE_FAILED_REPORT = 'CAMPAIGN_STATUS_CHANGE_FAILED_REPORT';

export const FORM_DATA_RESET = 'RESET';
export const GENERIC_ERROR = 'Unexpected Error Ocurred';

export const completeCampaignFormSubmission = (formStep, campaign, moveNext) => ({
    type: CAMPAIGN_FORM_SUBMITTED,
    formStep,
    campaign,
    moveNext,
});

export const switchCampaignFormStep = formStep => ({
    type: CAMPAIGN_FORM_SWITCH_STEP,
    formStep,
});

export const finalizeCampaignFormOffers = offers => ({
    type: CAMPAIGN_FORM_OFFERS_FINALIZED,
    offers,
});

export const finalizeCampaignFormLandingPages = landingPages => ({
    type: CAMPAIGN_FORM_LANDING_PAGES_FINALIZED,
    landingPages,
});

export const finalizeCampaignFormRequest = () => ({
    type: CAMPAIGN_FORM_FINALIZE_REQUEST,
});

export const finalizeCampaignForm = (categories, merchandisingSlots) => ({
    type: CAMPAIGN_FORM_FINALIZED,
    categories,
    merchandisingSlots,
});

export const resetCampaignForm = () => ({
    type: CAMPAIGN_FORM_RESET,
});

export const requestCampaignStatusUpdate = (id, status) => ({
    type: CAMPAIGN_STATUS_CHANGE_REQUESTED,
    id,
    status,
});
export const receiveCampaignStatusUpdate = (errors: Record<string, any>) => ({
    type: CAMPAIGN_STATUS_CHANGE_RECEIVED,
    errors,
});

export const errorCampaignStatusUpdate = requestAction => ({
    type: CAMPAIGN_STATUS_CHANGE_FAILED,
    error: new Error('Failed to update the Campaign Status'),
    requestAction,
});

const initCampaignForm: CampaignForm = {
    formStep: 1,
    campaign: undefined,
    completedSteps: [],
    errors: {},
    requestState: RequestState.NotRequested,
};

export const campaignFormReducer = (campaignForm = initCampaignForm, action) => {
    switch (action.type) {
        case CAMPAIGN_RECEIVED:
            let formCompletedSteps = [...campaignForm.completedSteps];
            if (
                action.payload.data.status === CampaignStatus.published ||
                action.payload.data.status === CampaignStatus.scheduled
            ) {
                let stepsCount = action.channelConfiguration.supported_entities.length + 1;
                //TODO remove when LP flag is removed
                if (
                    action.channelConfiguration.supported_entities.includes('LandingPage') &&
                    getAppConfig().EnableLandingPages === false
                ) {
                    stepsCount--;
                }
                formCompletedSteps = [];
                for (let index = 1; index <= stepsCount; index++) {
                    formCompletedSteps.push(index);
                }
            }
            return {
                ...campaignForm,
                campaign: action.payload.data,
                completedSteps: formCompletedSteps,
                requestState: RequestState.Finished,
            };
        // This one submits the campaign and navigates to the next step
        case CAMPAIGN_FORM_SUBMITTED:
            return {
                ...campaignForm,
                formStep: action.moveNext ? action.formStep + 1 : action.formStep,
                campaign: action.campaign,
                completedSteps: [
                    //just keep the steps lower than the current step to enforce saving further steps again
                    ...new Set([
                        ...campaignForm.completedSteps.filter(step => step < action.formStep),
                        action.formStep,
                    ]),
                ],
                requestState: RequestState.Finished,
            };

        // This one navigates to the next step after offers have been saved individually
        case CAMPAIGN_FORM_OFFERS_FINALIZED:
            return {
                ...campaignForm,
                formStep: campaignForm.formStep + 1,
                campaign: {
                    ...campaignForm.campaign,
                    offers: action.offers as { data: Offer[] },
                },
                completedSteps: [
                    ...new Set([
                        ...campaignForm.completedSteps.filter(step => step !== campaignForm.formStep + 1), //make sure the current step + 1 is saved
                        campaignForm.formStep,
                    ]),
                ],
                requestState: RequestState.Finished,
            };

        case CAMPAIGN_FORM_LANDING_PAGES_FINALIZED:
            return {
                ...campaignForm,
                formStep: campaignForm.formStep,
                campaign: {
                    ...campaignForm.campaign,
                    landing_pages: action.landingPages as { data: LandingPage[] },
                },
                completedSteps: [...new Set([...campaignForm.completedSteps, campaignForm.formStep])],
                requestState: RequestState.Finished,
            };

        case CAMPAIGN_FORM_FINALIZED:
            return {
                ...campaignForm,
                campaign: {
                    ...campaignForm.campaign,
                    categories: action.categories,
                    merchandising_slots: action.merchandisingSlots,
                },
                completedSteps: [...new Set([...campaignForm.completedSteps, campaignForm.formStep])],
                requestState: RequestState.Finished,
            };

        // This is to navigate backwards
        case CAMPAIGN_FORM_SWITCH_STEP:
            return {
                ...campaignForm,
                formStep: action.formStep,
                requestState: RequestState.Finished,
            };

        case CAMPAIGN_FORM_RESET:
            return {
                formStep: initCampaignForm.formStep,
                campaign: initCampaignForm.campaign,
                completedSteps: [...initCampaignForm.completedSteps],
                errors: { ...initCampaignForm.errors },
                requestState: RequestState.NotRequested,
            };

        case CAMPAIGN_STATUS_CHANGE_REQUESTED:
            return { ...campaignForm, requestState: RequestState.InProgress };

        case CAMPAIGN_STATUS_CHANGE_RECEIVED:
            return { ...campaignForm, errors: action.errors, requestState: RequestState.Finished };

        default:
            return campaignForm;
    }
};

//Saga

export function* finalizeCampaign(action, requestInfo: RequestInfo) {
    let categories = yield select(state => state.submittedCategories);
    categories = { ...categories };
    let merchandisingSlots = yield select(state => state.submittedMerchandisingSlots);
    if (!categories.data || Object.keys(categories.data).length === 0) {
        const campaignForm: CampaignForm = yield select(state => state.campaignForm);
        categories = campaignForm.campaign ? campaignForm.campaign.categories : {};
    }
    merchandisingSlots = { ...merchandisingSlots };
    // We only need the data
    delete categories.requestState;
    delete merchandisingSlots.requestState;

    yield put(finalizeCampaignForm(categories, merchandisingSlots));
}

export function* doResetForm(action, requestInfo: RequestInfo) {
    //clean offers
    yield put(receivedOffers({ data: [FORM_DATA_RESET] }));
    //clean offer temp
    yield put(receivedOfferTemporaryCreation({ data: [FORM_DATA_RESET] }));
    //clean discountCodes
    yield put(receivedParseDiscountCode({ data: [] }));
    //reset campaign request
    yield put(resetCampaignRequest());
    //reset landing pages
    yield put(resetLandingPages());
}

export function* doRequestCampaignStatusChange(action) {
    const response = yield call(updateCampaignStatus, action.id, action.status);
    yield put(receiveCampaignStatusUpdate([]));
    if (response.success === true || !response.errors) {
        yield put(requestCampaign(action.id));
        yield put(showStatusMessage('Status changed successfully', 'success'));
    }
}

export function* doHandleCampaignStatusChangeError(action) {
    let parsedErrors: Array<string> = [];
    //check if is know error
    if (action.error && isJson(action.error)) {
        const internaleParsedErrors = JSON.parse(action.error);
        if (isJson(internaleParsedErrors.message)) {
            const childMessage = JSON.parse(internaleParsedErrors.message);
            childMessage.errors
                ? (parsedErrors = parsedErrors.concat(childMessage.errors))
                : parsedErrors.push(GENERIC_ERROR);
        } else {
            parsedErrors.push(internaleParsedErrors.message);
        }
    } else {
        parsedErrors.push(GENERIC_ERROR);
    }
    yield put(receiveCampaignStatusUpdate(parsedErrors));
    yield put(showStatusMessage(parsedErrors.length ? parsedErrors[0] : '', parsedErrors.length ? 'error' : 'success'));
}

export function* watchCampaignFormStore() {
    yield all([
        takeLatest(CAMPAIGN_FORM_RESET, doCall(doResetForm, CAMPAIGN_FORM_RESET_FAILED)),
        takeEvery(
            CAMPAIGN_STATUS_CHANGE_REQUESTED,
            doCall(doRequestCampaignStatusChange, CAMPAIGN_STATUS_CHANGE_FAILED),
        ),
        takeEvery(
            CAMPAIGN_STATUS_CHANGE_FAILED,
            doCall(doHandleCampaignStatusChangeError, CAMPAIGN_STATUS_CHANGE_FAILED_REPORT),
        ),
        takeLatest(CAMPAIGN_FORM_FINALIZE_REQUEST, doCall(finalizeCampaign)),
    ]);
}
