import { Alert } from '@mui/material';
import { fetchBnclCampaign } from 'application/vouchers/bnclSlice';
import { Loader } from 'components/common/Loader/Loader';
import { isFailed, isLoading } from 'helpers/requestStateHelper';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../store';
import CampaignEditor from './CampaignEditor';
import DuplicateCampaign from './DuplicateCampaign';

export default function EditCampaign(): React.ReactElement {
    const campaign = useSelector(state => state.vouchers.bncl.bnclCampaign);
    const params = useParams<{ campaignId: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBnclCampaign(params.campaignId));
    }, [params.campaignId]);

    const key = useMemo(() => Math.random(), [campaign.payload]); // need to force re-render, otherwise editor thinks it was a user update

    return (
        <>
            {isLoading(campaign) && <Loader />}
            {campaign.payload && <DuplicateCampaign campaign={campaign.payload} />}
            {isFailed(campaign) && (
                <Alert variant="filled" severity="error" data-cy="Bncl-Campaign-LoadError">
                    Failed to load Bncl Campaign {params.campaignId}
                </Alert>
            )}
            {!isFailed(campaign) && <CampaignEditor campaign={campaign.payload} key={key} />}
        </>
    );
}
