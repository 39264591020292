import { useState } from 'react';

export const useCopier = (): [string, (code: string) => void] => {
    const [copiedText, setCopiedText] = useState<string>('');
    const onCopy = (code: string) => {
        navigator.clipboard.writeText(code);
        setCopiedText(code);
        setTimeout(() => setCopiedText(''), 1000);
    };

    return [copiedText, onCopy];
};
