import { getVersionedCacheKey } from 'helpers/cacheHelper';
import { callApi } from './callApi';

const CACHE_EXPIRATION_SECONDS = 3600 * 3;

export const getVendors = data => {
    const options = {
        cacheKey: getVersionedCacheKey('PUBLIC_CONFIG_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('PublicConfigUrl', 'GET', 'vendors', null, options);
};
