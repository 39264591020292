import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVendorSitemap, putVendorSitemap, SitemapRoute } from 'apis/seo';
import { RequestState, RequestType } from 'common/RequestState';

export interface SitemapUpdate {
    vendorName: string;
    routes: SitemapRoute[];
}

type SitemapState = {
    [vendor: string]: RequestType<SitemapRoute[]>;
};

export const fetchVendorSitemap = createAsyncThunk<SitemapRoute[], string>(
    'seo/sitemap/fetch',
    async (vendorName: string) => {
        const sitemapResponse = await getVendorSitemap(vendorName);
        return sitemapResponse;
    },
);

export const updateVendorSitemap = createAsyncThunk<void, SitemapUpdate>('seo/sitemap/update', async sitemapUpdate => {
    const sitemapResponse = await putVendorSitemap(sitemapUpdate.vendorName, sitemapUpdate.routes);
    return sitemapResponse;
});

export const sitemapSlice = createSlice({
    name: 'sitemap',
    initialState: {} as SitemapState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchVendorSitemap.pending, (state, action) => {
            state[action.meta.arg] = state[action.meta.arg] || {};
            state[action.meta.arg].requestState = RequestState.InProgress;
        });
        builder.addCase(fetchVendorSitemap.fulfilled, (state, action) => {
            state[action.meta.arg].payload = action.payload;
            state[action.meta.arg].requestState = RequestState.Finished;
        });
        builder.addCase(fetchVendorSitemap.rejected, (state, action) => {
            state[action.meta.arg].requestState = RequestState.Failed;
        });

        builder.addCase(updateVendorSitemap.pending, (state, action) => {
            state[action.meta.arg.vendorName] = state[action.meta.arg.vendorName] || {};
            state[action.meta.arg.vendorName].requestState = RequestState.InProgress;
        });
        builder.addCase(updateVendorSitemap.fulfilled, (state, action) => {
            state[action.meta.arg.vendorName].payload = action.meta.arg.routes;
            state[action.meta.arg.vendorName].requestState = RequestState.Finished;
        });
        builder.addCase(updateVendorSitemap.rejected, (state, action) => {
            state[action.meta.arg.vendorName].requestState = RequestState.Failed;
        });
    },
});
