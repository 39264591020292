import { OfferDiscountDetails } from 'apis/bnclCampaign';
import { PapDetailsType } from 'apis/productCatalogApi';
import { Translation } from 'application/translation/translationSlice';
import usePrevious from 'components/hooks/usePrevious';
import { supportedPapTags } from 'helpers/bnclHelper';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store';

type useBnclOfferOptionsInput = {
    papDetails: PapDetailsType | null;
    formik: any;
    discountDetails: OfferDiscountDetails | null;
    isInitialDetails: boolean;
};

type useBnclOfferOptionsState = {
    rawOptions: {
        included: Translation[];
        excluded: Translation[];
    };
    options: {
        included: Translation[];
        excluded: Translation[];
    };
    usedOptionKeys: string[];
};

type LastCalculatedState = {
    included: (Translation & { isCustom: boolean })[];
    excluded: (Translation & { isCustom: boolean })[];
    papDetails: PapDetailsType | null;
    discountDetails: OfferDiscountDetails | null;
};

const beautifyOptionText = (
    translation: Translation,
    papDetails: PapDetailsType | null,
    discountDetails: OfferDiscountDetails | null,
): Translation => {
    let text = translation.value;

    if (translation.key.includes('.ExtraPages.value')) {
        const extraAmount = discountDetails?.options.ExtraPages?.value;
        if (papDetails && extraAmount) {
            const x = papDetails.pageCount;
            const z = +extraAmount + x;
            text = text.replace('{x}', String(x)).replace('{y}', extraAmount).replace('{z}', String(z));
        }
    }

    return {
        ...translation,
        value: text,
    };
};

const toExcludeOnIncludedMatch = {
    // included: toExclude
    'articleType.value.HardCoverPhotoBook': 'articleType.value.SoftCoverPhotoBook',
    'articleType.value.SoftCoverPhotoBook': 'articleType.value.HardCoverPhotoBook',
};

const optionOrderPriority = [
    ['.ExtraPages', 100],
    ['.articleType.', 90],
    ['CUSTOM.', 20],
    ['.CoverType', 10],
    // any not matched = 0
    ['.freeShipping', -100],
] as const;

const optionsSortFn = (a: Translation, b: Translation): number => {
    const aPriority = optionOrderPriority.find(([prefix]) => a.key.includes(prefix));
    const bPriority = optionOrderPriority.find(([prefix]) => b.key.includes(prefix));
    const aWeight = aPriority ? aPriority[1] : 0;
    const bWeight = bPriority ? bPriority[1] : 0;

    if (aWeight === bWeight) return b.value < a.value ? 1 : -1; // abc

    return bWeight - aWeight;
};

export default function useBnclOfferOptions({
    formik,
    papDetails,
    discountDetails,
    isInitialDetails,
}: useBnclOfferOptionsInput): useBnclOfferOptionsState {
    const optionsTranslations = useSelector(state => state.translations.list);
    const papType = useMemo(() => {
        if (!papDetails) return null;
        const type = Object.entries(supportedPapTags).find(([_, value]) => papDetails.categories.includes(value));
        return type ? type[0] : null;
    }, [papDetails]);

    const [lastCalculatedOptions, setCalculatedOptions] = useState<LastCalculatedState>({
        papDetails,
        discountDetails,
        included: [],
        excluded: [],
    });

    const form = formik.values;

    const rawOptions = useMemo(() => {
        const prefixes = ['bncl.options'];
        if (papType) prefixes.push(`bncl.options.${papType}`);

        const included = optionsTranslations.payload.filter(option =>
            prefixes.find(prefix => option.key.startsWith(`${prefix}.included.`)),
        );
        const excluded = optionsTranslations.payload.filter(option =>
            prefixes.find(prefix => option.key.startsWith(`${prefix}.excluded.`)),
        );
        return { included, excluded };
    }, [papType, optionsTranslations]);

    const options = useMemo(() => {
        return {
            included: rawOptions.included.map(tr => beautifyOptionText(tr, papDetails, discountDetails)),
            excluded: rawOptions.excluded.map(tr => beautifyOptionText(tr, papDetails, discountDetails)),
        };
    }, [rawOptions, papDetails, discountDetails]);

    const currentState = useMemo(() => {
        const curState: LastCalculatedState = { papDetails, discountDetails, included: [], excluded: [] };
        form.includedOptions.forEach(optionVal => {
            const translation = options.included.filter(option => option.value === optionVal);
            if (translation.length > 0) {
                translation.forEach(translation => {
                    curState.included.push({ ...translation, isCustom: false });
                });
            } else if (optionVal) {
                curState.included.push({ key: `CUSTOM.${optionVal}`, value: optionVal, isCustom: true });
            }
        });
        form.excludedOptions.forEach(optionVal => {
            const translation = options.excluded.filter(option => option.value === optionVal);
            if (translation.length > 0) {
                translation.forEach(translation => {
                    curState.excluded.push({ ...translation, isCustom: false });
                });
            } else if (optionVal) {
                curState.included.push({ key: `CUSTOM.${optionVal}`, value: optionVal, isCustom: true });
            }
        });
        return curState;
    }, [form.includedOptions, form.excludedOptions, papDetails, discountDetails]);

    const prevState = usePrevious(currentState);

    useEffect(() => {
        if (prevState === currentState) return;
        if (
            lastCalculatedOptions.papDetails === currentState.papDetails &&
            lastCalculatedOptions.discountDetails === currentState.discountDetails
        ) {
            // user input
            setCalculatedOptions(currentState);
        }
    }, [prevState, currentState]);

    useEffect(() => {
        if (isInitialDetails) {
            setCalculatedOptions(currentState);
            return;
        }

        if (!papDetails || !discountDetails) return;

        const newState: LastCalculatedState = {
            included: lastCalculatedOptions.included.filter(o => o.isCustom),
            excluded: lastCalculatedOptions.excluded.filter(o => o.isCustom),
            papDetails,
            discountDetails,
        };

        const addToIncluded = (key: string) => {
            const tr = rawOptions.included.find(raw => raw.key.endsWith(key));
            if (tr) {
                newState.included.push({ ...beautifyOptionText(tr, papDetails, discountDetails), isCustom: false });
            }
            return !!tr;
        };
        const addToExcluded = (key: string) => {
            const tr = rawOptions.excluded.find(raw => raw.key.endsWith(key));
            if (tr) {
                newState.excluded.push({ ...beautifyOptionText(tr, papDetails, discountDetails), isCustom: false });
            }
            return !!tr;
        };

        const articleTypeKey = `articleType.value.${papDetails.articleType}`;
        addToIncluded(articleTypeKey);
        if (toExcludeOnIncludedMatch[articleTypeKey]) {
            addToExcluded(toExcludeOnIncludedMatch[articleTypeKey]);
        }
        Object.entries(discountDetails.options).forEach(([optionKey, optionValue]) => {
            if (!optionValue.isFree) {
                addToExcluded(`excluded.${optionKey}`);
            } else {
                // https://albelli-photobox.atlassian.net/browse/CMS-17216?focusedCommentId=990789
                let wasAdded = false;
                if (optionValue.value) {
                    wasAdded = addToIncluded(`included.${optionKey}.value.${optionValue.value}`);
                    !wasAdded && (wasAdded = addToIncluded(`included.${optionKey}.value`));
                } else {
                    wasAdded = addToIncluded(`included.${optionKey}.any`);
                }
                !wasAdded && addToIncluded(`included.${optionKey}.default`);
            }
        });
        if (discountDetails.freeShipping) {
            addToIncluded('included.freeShipping');
        } else {
            addToExcluded('excluded.freeShipping');
        }

        newState.included.sort(optionsSortFn);
        newState.excluded.sort(optionsSortFn);

        formik.setFieldValue('includedOptions', newState.included.length ? newState.included.map(o => o.value) : ['']);
        formik.setFieldValue('excludedOptions', newState.excluded.length ? newState.excluded.map(o => o.value) : ['']);
        setCalculatedOptions(newState);
    }, [
        // form, <- intentional
        papDetails,
        discountDetails,
    ]);

    const usedOptionKeys = useMemo(() => {
        const keys: string[] = [];

        lastCalculatedOptions.included.forEach(o => {
            if (!o.isCustom) {
                keys.push(o.key);
            }
        });
        lastCalculatedOptions.excluded.forEach(o => {
            if (!o.isCustom) {
                keys.push(o.key);
            }
        });

        return keys;
    }, [lastCalculatedOptions]);

    return {
        rawOptions,
        options,
        usedOptionKeys,
    };
}
