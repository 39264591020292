import { TableCell } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { withStyles } from 'tss-react/mui';

export const CustomTableCell = withStyles(TableCell, (theme: Theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 'bolder',
    },
}));
