import { DiscountCodeDetails } from 'application/campaignForm/offerCreate/discountCodesStore';
import { Offer } from 'models/offer';
import { Vendor } from 'models/vendor';

export const getDiscountCodes = (state): { data: Array<DiscountCodeDetails> } | null => {
    return state.discountCodes;
};
export const getOffers = (state): Offer[] | null => state.offers;
export const getOfferTemp = (state): Offer[] | null => state.offerTemp;
export const getVendorsInSaga = (state): { data: Vendor[] } | null => state.apiConfig.vendors;
