import { SideNav } from 'components/common/SideNav/SideNav';
import { generateSideNavItems, SideNavigationItem } from 'components/common/SideNavRenderer/sideNavItems';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes';

function SideNavRenderer(): React.ReactElement {
    const location = useLocation();
    const [items, setItems] = useState<SideNavigationItem[] | []>([]);
    const [baseRoute, setBaseRoute] = useState<string>('');

    const updateBaseRoute = (): void => {
        if (location.pathname.indexOf(routes.campaigns) >= 0) {
            setBaseRoute(routes.campaigns);
        } else if (location.pathname.indexOf(routes.discounts) >= 0) {
            setBaseRoute(routes.discounts);
        } else if (location.pathname.indexOf(routes.seo) >= 0) {
            setBaseRoute(routes.seo);
        } else if (location.pathname.indexOf(routes.lod) >= 0) {
            setBaseRoute(routes.lod);
        } else if (location.pathname.indexOf(routes.b2b) >= 0) {
            setBaseRoute(routes.b2b);
        } else if (location.pathname.indexOf(routes.vouchers) >= 0) {
            setBaseRoute(routes.vouchers);
        } else {
            setBaseRoute('');
        }
    };

    /**
     *  Check if item is selected or not
     */
    const prepareItems = (items: SideNavigationItem[] | []): SideNavigationItem[] | [] => {
        const path = location.pathname;
        return Object.keys(items).map(key => {
            const selected = path === items[key].url;
            return { ...items[key], selected: selected };
        });
    };

    useEffect(() => {
        setItems(generateSideNavItems(baseRoute));
    }, [baseRoute]);

    useEffect(() => {
        if (location) {
            updateBaseRoute();
        }
    }, [location]);

    return <SideNav menuItems={prepareItems(items)} id={baseRoute.replace('/', '')}></SideNav>;
}

export default SideNavRenderer;
