import { doRequest } from 'services/httpService';
import { oidc } from 'services/oidcService';
import { callApi } from './callApi';
import { Vendor } from '../models/vendor';
import { getAppConfig } from '../services/configService';

export enum CampaignCategory {
    MERCHANDISING = 'merchandising',
    CRM = 'crm',
}

export const CampaignCategoryHumanizedNames = {
    [CampaignCategory.MERCHANDISING]: 'Merchandising',
    [CampaignCategory.CRM]: 'CRM',
};

export enum VendorCampaignStatus {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    UNPUBLISHED = 'unpublished',
}

export enum BnclOfferStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type BnclCampaignsDto = {
    campaignId: string;
    name: string;
    startDate: string; // ISO, UTC
    endDate: string; // ISO, UTC
    category: CampaignCategory;
    vendorCampaignList: Array<{ name: string; status: VendorCampaignStatus; attributions: string[] }>;
};

export type OfferDiscountDetails = {
    papId: string;
    options: Record<
        string,
        {
            isFree: boolean;
            value: string;
        }
    >;
    freeShipping: boolean;
};

export interface BnclOffer {
    voucherId: string;
    discountId: string;
    discountDetails: OfferDiscountDetails;
    productBlockTitle: string;
    checkoutLabel: string;
    imagePath: string;
    voucherPrice: number;
    productPrice: number;
    currency: string;
    includedOptions: string[];
    excludedOptions: string[];
    lastPublishDate: string;
    expirationDate: string;
    status: BnclOfferStatus;
    attributionId?: string;
    attributionCode?: string;
}

export type PageDetailsDto = {
    title: string;
    subtitle: string;
};

export type BnclVendorCampaign = {
    vendorName: string;
    offers: BnclOffer[];
    pageDetails: PageDetailsDto;
    attributions: [
        {
            category: CampaignCategory;
            code: string;
            id: number;
        },
    ];
    status: VendorCampaignStatus;
};

export type BnclCampaignDto = {
    campaignId: string;
    name: string;
    startDate: string;
    endDate: string;
    category: CampaignCategory;
    vendorCampaignList: BnclVendorCampaign[];
};

type UpdateBnclCampaignInput = {
    name: string;
    startDate: Date | string;
    endDate: Date;
    category: CampaignCategory;
    vendorCampaign: {
        vendorName: string;
        offers: Array<{
            voucherId: string;
            discountId: string;
            discountDetails: OfferDiscountDetails;
            productBlockTitle: string;
            checkoutLabel: string;
            imagePath: string;
            voucherPrice: number;
            productPrice: number;
            currency: string;
            includedOptions: string[];
            excludedOptions: string[];
            lastPublishDate: Date;
            expirationDate: Date;
            status: BnclOfferStatus;
            attributionId?: string;
            attributionCode?: string;
        }>;
        pageDetails: {
            title: string;
            subtitle: string;
        };
        attributions: Array<{
            category: CampaignCategory;
            code: string;
            id: number;
        }>;
        status: VendorCampaignStatus;
    };
};

type CreateBnclCampaignInput = UpdateBnclCampaignInput & {
    campaignId?: string;
};

export const getBnclCampaigns = async (): Promise<BnclCampaignsDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('VoucherApiUrl', 'GET', 'campaigns', null, {}, token);
};

export const getBnclCampaign = async (campaignId: string): Promise<BnclCampaignDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('VoucherApiUrl', 'GET', `campaigns/${campaignId}`, null, {}, token);
};

export const createBnclCampaign = async (data: CreateBnclCampaignInput): Promise<{ campaignId: string }> => {
    const token = await oidc.getAccessToken();
    return await callApi('VoucherApiUrl', 'POST', 'campaigns', data, {}, token);
};

export const updateBnclCampaign = async (campaignId: string, data: UpdateBnclCampaignInput): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi('VoucherApiUrl', 'PUT', `campaigns/${campaignId}`, data, {}, token);
};

export const deleteBnclCampaign = async (campaignId: string, vendorName: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return await callApi('VoucherApiUrl', 'DELETE', `campaigns/${campaignId}/vendors/${vendorName}`, null, {}, token);
};

export const countBnclDiscountDetailsPrice = async (
    vendorName: string,
    discountDetails: OfferDiscountDetails,
): Promise<{ productPrice: number }> => {
    const token = await oidc.getAccessToken();
    return await callApi(
        'VoucherApiUrl',
        'POST',
        `campaigns/${vendorName}/discount-details-price`,
        discountDetails,
        {},
        token,
    );
};

export async function uploadBnclImages(files: { img: File; name: string }[]): Promise<void> {
    if (files.length === 0) return;

    const token = await oidc.getAccessToken();
    const config = await callApi('VoucherApiUrl', 'POST', '/images/presigned-post', null, {}, token);

    await Promise.all(
        files.map(async ({ img, name }) => {
            const formData = new FormData();
            Object.keys(config.fields).forEach(key => formData.append(key, config.fields[key]));

            formData.append('Content-Type', img.type);
            formData.append(
                'file',
                // renamed copy
                new File([img], name, {
                    type: img.type,
                    lastModified: img.lastModified,
                }),
            );
            await doRequest('POST', config.url, formData);
        }),
    );
}

export const getDefaultOfferImage = async (
    papId: string,
    vendor: Vendor,
): Promise<{ file: File; path: string } | null> => {
    const possiblePaths = [
        '/product-pictures/${PAP_ID}/block/lady-image-product-block-${COUNTRY}.jpg',
        '/product-pictures/${PAP_ID}/block/lady-image-product-block.jpg',
    ];
    const vendorCountry = vendor.locale.split('-').pop() as string;

    for (const pathPattern of possiblePaths) {
        const imagePath = pathPattern.replaceAll('${PAP_ID}', papId).replaceAll('${COUNTRY}', vendorCountry);
        const response = await fetch(new URL(imagePath, getAppConfig().StorefrontAssetsS3Url).href);
        if (!response.ok) {
            continue;
        }

        const blob = await response.blob();
        return {
            file: new File([blob], imagePath.split('/').pop() as string, { type: blob.type }),
            path: imagePath,
        };
    }

    return null;
};
