export const IMAGE_SUBMIT_REQUESTED = 'IMAGE_SUBMIT_REQUESTED';
export const IMAGE_RECEIVED = 'IMAGE_RECEIVED';
export const IMAGE_REQUEST_FAILED = 'IMAGE_REQUEST_FAILED';

export const MERCHANDISING_SLOT_SUBMIT_REQUESTED = 'MERCHANDISING_SLOT_SUBMIT_REQUESTED';
export const MERCHANDISING_SLOT_SUBMIT_RECEIVED = 'MERCHANDISING_SLOT_SUBMIT_RECEIVED';
export const MERCHANDISING_SLOT_SUBMIT_FAILED = 'MERCHANDISING_SLOT_SUBMIT_FAILED';
export const MERCHANDISING_SLOT_SUBMIT_RESET = 'MERCHANDISING_SLOT_SUBMIT_RESET';

export const MERCHANDISING_SLOTS_UPDATE_COUNTDOWN = 'MERCHANDISING_SLOTS_UPDATE_COUNTDOWN';
export const MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_RECEIVED = 'MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_RECEIVED';
export const MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED = 'MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED';

export const MERCHANDISING_SLOTS_SUBMIT_REQUESTED = 'MERCHANDISING_SLOTS_SUBMIT_REQUESTED';
export const MERCHANDISING_SLOTS_SUBMIT_RECEIVED = 'MERCHANDISING_SLOTS_SUBMIT_RECEIVED';
export const MERCHANDISING_SLOTS_SUBMIT_FAILED = 'MERCHANDISING_SLOTS_SUBMIT_FAILED';
export const MERCHANDISING_SLOTS_SUBMIT_RESET = 'MERCHANDISING_SLOTS_SUBMIT_RESET';

export const CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED = 'CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED';
export const CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_COMPLETED = 'CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_COMPLETED';
