/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getCampaignChannels } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo } from 'common/genericTypes';
import { all, call, put, takeLeading } from 'redux-saga/effects';

export const CAMPAIGN_CHANNELS_REQUESTED = 'CAMPAIGN_CHANNELS_REQUESTED';
export const CAMPAIGN_CHANNELS_RECEIVED = 'CAMPAIGN_CHANNELS_RECEIVED';
export const CAMPAIGN_CHANNELS_REQUEST_FAILED = 'CAMPAIGN_CHANNELS_REQUEST_FAILED';

export const requestCampaignChannels = () => ({
    type: CAMPAIGN_CHANNELS_REQUESTED,
});
export const receiveCampaignChannels = payload => ({
    type: CAMPAIGN_CHANNELS_RECEIVED,
    payload,
});
export const errorRequestCampaignChannels = requestAction => ({
    type: CAMPAIGN_CHANNELS_REQUEST_FAILED,
    error: new Error('Failed to retrieve Campaign Channels'),
    requestAction,
});

export const getCampaignChannelsReducer = createGenericReducer(
    CAMPAIGN_CHANNELS_REQUESTED,
    CAMPAIGN_CHANNELS_RECEIVED,
    CAMPAIGN_CHANNELS_REQUEST_FAILED,
);

export function* doRequestCampaignChannels(action, requestInfo: RequestInfo) {
    const channels = yield call(getCampaignChannels);
    yield put(receiveCampaignChannels(channels));
}

export function* watchCampaignChannelsStore() {
    yield all([
        takeLeading(CAMPAIGN_CHANNELS_REQUESTED, doCall(doRequestCampaignChannels, CAMPAIGN_CHANNELS_REQUEST_FAILED)),
    ]);
}
