export const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
export const DISCOUNT_TYPE_PERCENTAGE_UP_TO = 'percentage_up_to';
export const DISCOUNT_TYPE_FREE_SHIPPING = 'free_shipping';
export const DISCOUNT_TYPE_FIXED_AMOUNT = 'fixed_amount';
export const DISCOUNT_TYPE_FIXED_AMOUNT_UP_TO = 'fixed_amount_up_to';

export type DiscountType =
    | typeof DISCOUNT_TYPE_PERCENTAGE
    | typeof DISCOUNT_TYPE_FREE_SHIPPING
    | typeof DISCOUNT_TYPE_FIXED_AMOUNT
    | typeof DISCOUNT_TYPE_PERCENTAGE_UP_TO // translated from percentage type with higher offer value
    | typeof DISCOUNT_TYPE_FIXED_AMOUNT_UP_TO //translated from fixed amount type with higher offer value
    | 'minimum_order_value' //unsupported
    | 'minimum_order_quantity'; //unsupported

export interface Discount {
    type: DiscountType;
    offer_value?: number;
    offer_percentage?: number;
}

export type SortingOrder = 'asc' | 'desc';
