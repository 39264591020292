/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ConflictResponseError from 'errors/ConflictResponseError';
import ResponseError, { ResponseData } from 'errors/ResponseError';

const CACHE_EXPIRE_HEADER = 'X-Cache-Expires';

const getHeaders = params => {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...params,
    };

    return headers;
};

/**
 * Caches a Response using the Cache API
 * @async
 * @param string   cacheKey
 * @param string   requestPath
 * @param Response response
 * @param number   cacheDuration
 */
const cacheResponse = async (cacheKey: string, requestPath: string, response: Response, cacheDuration: number) => {
    // Checks if the browser supports the Cache API
    if (!('caches' in window)) {
        return;
    }

    // Response can only be consumed once
    // cloning it to re-use it and avoid tampering the original one
    const clonedResponse = response.clone();

    // Set the expiration time to the response header
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + cacheDuration);

    // Re-create the response, by copying all the properties of the cloned response
    const cachedResponseFields = {
        ...clonedResponse,
        headers: {
            ...clonedResponse.headers,
            [CACHE_EXPIRE_HEADER]: expires.toUTCString(),
        },
    };
    const responseBody = await clonedResponse.blob();

    // Stores the response in the Cache
    const cache = await window.caches.open(cacheKey);
    cache.put(requestPath, new Response(responseBody, cachedResponseFields));
};

/**
 * Retrieves a response from the cache
 * @async
 * @param  string cacheKey
 * @param  string requestPath
 * @return Response | null
 */
const getCachedResponse = async (cacheKey: string, requestPath: string) => {
    // Checks if the browser supports the Cache API
    if (!('caches' in window)) {
        return null;
    }

    const cache = await window.caches.open(cacheKey);

    const cachedResponse = await cache.match(requestPath);

    if (!cachedResponse) {
        return null;
    }

    // Validate if the cache is stale
    const now = new Date();
    const expirationDate = new Date(cachedResponse.headers.get(CACHE_EXPIRE_HEADER) || '');

    if (expirationDate < now) {
        await cache.delete(requestPath);
        return null;
    }

    cachedResponse.headers.delete(CACHE_EXPIRE_HEADER);
    return cachedResponse;
};

export const doRequest = async (
    method = 'GET',
    path = '',
    data: any = null,
    headers = {},
    cacheKey = '',
    cacheDuration = 3600,
    forceCacheUpdate = false,
): Promise<any> => {
    const options: RequestInit = {
        method,
        headers: getHeaders(headers),
        mode: 'cors',
        cache: 'default',
    };

    if (data) {
        if (data instanceof FormData) {
            options.body = data;
            if (options.headers) {
                // required because fetch will gernerate a Content-Type with a boundary uniqcode
                delete options.headers['Content-Type'];
            }
        } else {
            options.body = JSON.stringify(data);
        }
    }

    let response;
    if (cacheKey && !forceCacheUpdate) {
        response = await getCachedResponse(cacheKey, path);
    }

    // If the response is empty, do the actual API request
    if (!response) {
        response = await fetch(path, options);

        // Throw an Error if it's an unexpected error code
        if (!response.ok) {
            const responseCopy = response.clone();
            const parsedResponse = await responseCopy.json().catch(() => response.text());
            const responseData: ResponseData = {
                statusCode: response.status,
                method,
                path: path.split('?')[0].replace(/\d+/g, ''),
            };

            switch (response.status) {
                case 409:
                    if (parsedResponse.errors instanceof Array) {
                        throw new ConflictResponseError(parsedResponse.errors[0], responseData, parsedResponse.data);
                    }
                    break;
                case 401:
                    throw new Error('Unauthorized');
            }

            let message = parsedResponse;
            if (parsedResponse.errors) {
                message = parsedResponse.errors instanceof Array ? parsedResponse.errors[0] : parsedResponse.errors;
            } else if (parsedResponse.message) {
                message = parsedResponse.message;
            }
            throw new ResponseError(message, responseData);
        }

        // If this is a cached request then store the response in the cache to re-use it
        if (cacheKey && response.ok && !window.Cypress) {
            await cacheResponse(cacheKey, path, response, cacheDuration);
        }
    }
    const responseCopy = response.clone();
    return response.status !== 204 ? responseCopy.json().catch(_ => response.text()) : {};
};
