export const VENDORS_PREDESIGNED_PRODUCT_IDS = {
    'albelli.nl': 'a13db0ae-8225-4e8e-ac35-7326aad2d7f6',
    'albelli.be': 'f95411d6-e8a1-4b81-a11c-fff139375006',
    'bonusprint.co.uk': '6b100aa8-e027-4de4-99f0-178aa57548d1',
    'onskefoto.se': 'c61b3c15-e819-4a3d-8627-0c2190ee9d76',
    'fotoknudsen.no': '1e7785b1-0879-4f45-947a-70fa806aec7b',
    'monalbumphoto.fr': 'cbe046fd-9420-4185-a1b4-2f5005391e9c',
    'photobox.co.uk': '6b100aa8-e027-4de4-99f0-178aa57548d1',
    'photobox.fr': 'cbe046fd-9420-4185-a1b4-2f5005391e9c',
    'photobox.dk': 'a13db0ae-8225-4e8e-ac35-7326aad2d7f6',
    'photobox.ie': '6b100aa8-e027-4de4-99f0-178aa57548d1',
    'photobox.it': 'a13db0ae-8225-4e8e-ac35-7326aad2d7f6',
    'hofmann.es': 'a13db0ae-8225-4e8e-ac35-7326aad2d7f6',
    'hofmann.pt': 'a13db0ae-8225-4e8e-ac35-7326aad2d7f6',
    'posterxxl.de': '93b8eb05-e126-4419-a208-ee3af3ba757c',
    'posterxxl.at': '93b8eb05-e126-4419-a208-ee3af3ba757c',
};
