import { GlobalConfiguration } from 'application/config/configTypes';
import { globalObject } from 'helpers/globalObject';

export function getAppConfig(): GlobalConfiguration {
    return globalObject.config;
}

export function setAppConfig(config): void {
    globalObject.config = config;
}
