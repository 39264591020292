/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CampaignCategoryType, getConfigCategories } from 'apis/campaignApi';
import { createGenericReducer } from 'common/genericReducer';
import { doCall } from 'common/genericSaga';
import { RequestInfo, SagaStoreDuckType } from 'common/genericTypes';
import { all, call, put, takeLeading } from 'redux-saga/effects';

export const CONFIG_CATEGORIES_REQUESTED = 'CONFIG_CATEGORIES_REQUESTED';
export const CONFIG_CATEGORIES_RECEIVED = 'CONFIG_CATEGORIES_RECEIVED';
export const CONFIG_CATEGORIES_REQUEST_FAILED = 'CONFIG_CATEGORIES_REQUEST_FAILED';

export const requestConfigCategories = () => ({
    type: CONFIG_CATEGORIES_REQUESTED,
});
export const receiveConfigCategories = payload => ({
    type: CONFIG_CATEGORIES_RECEIVED,
    payload,
});
export const errorRequestConfigCategories = requestAction => ({
    type: CONFIG_CATEGORIES_REQUEST_FAILED,
    error: new Error('Failed to retrieve config categories'),
    requestAction,
});

export const getConfigCategoriesReducer: SagaStoreDuckType<CampaignCategoryType[]> = createGenericReducer(
    CONFIG_CATEGORIES_REQUESTED,
    CONFIG_CATEGORIES_RECEIVED,
    CONFIG_CATEGORIES_REQUEST_FAILED,
);

export function* doRequestConfigCategories(action, requestInfo: RequestInfo) {
    const categories = yield call(getConfigCategories);
    yield put(receiveConfigCategories(categories));
}

export function* watchConfigCategoriesStore() {
    yield all([
        takeLeading(CONFIG_CATEGORIES_REQUESTED, doCall(doRequestConfigCategories, CONFIG_CATEGORIES_REQUEST_FAILED)),
    ]);
}
