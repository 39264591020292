export type ConstantColor = {
    name: string;
    color: string;
    textColor: string;
};

export const COLORS = {
    orange: {
        name: 'Orange',
        color: '#ff6a13',
        textColor: '#fff',
    } as ConstantColor,
    blue: {
        name: 'Blue',
        color: '#489fdf',
        textColor: '#fff',
    } as ConstantColor,
    cyan: {
        name: 'Cyan',
        color: '#38bfc4',
        textColor: '#fff',
    } as ConstantColor,
    yellow: {
        name: 'Yellow',
        color: '#ebb923',
        textColor: '#fff',
    } as ConstantColor,
    red: {
        name: 'Red',
        color: '#cd051f',
        textColor: '#fff',
    } as ConstantColor,
    white: {
        name: 'White',
        color: '#fff',
        textColor: '#333',
    } as ConstantColor,
    black: {
        name: 'Black',
        color: '#333',
        textColor: '#fff',
    } as ConstantColor,
} as const;
