import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import React, { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        marginRight: theme.spacing(2),
    },
}));

export interface CampaignTitleProps {
    title: string;
    uuid: string;
}

export const CampaignTitle = ({ title, uuid }: CampaignTitleProps): React.ReactElement => {
    const { classes } = useStyles();

    return (
        <Fragment>
            <Chip
                label={
                    <div>
                        <strong>{'UUID: '}</strong>
                        <i data-cy="CampaignNav-uuid">{uuid}</i>
                    </div>
                }
                className={classes.chip}
            />
            <strong data-cy="CampaignNav-name">{title}</strong>
        </Fragment>
    );
};
