import { createGenericReducer } from 'common/genericReducer';
import {
    MERCHANDISING_SLOT_SUBMIT_REQUESTED,
    MERCHANDISING_SLOT_SUBMIT_RECEIVED,
    MERCHANDISING_SLOT_SUBMIT_FAILED,
    MERCHANDISING_SLOT_SUBMIT_RESET,
    MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
    MERCHANDISING_SLOTS_SUBMIT_RECEIVED,
    MERCHANDISING_SLOTS_SUBMIT_FAILED,
    MERCHANDISING_SLOTS_SUBMIT_RESET,
} from './merchandisingSlotCreateTypes';

export const submittedMerchandisingSlotReducer = createGenericReducer(
    MERCHANDISING_SLOT_SUBMIT_REQUESTED,
    MERCHANDISING_SLOT_SUBMIT_RECEIVED,
    MERCHANDISING_SLOT_SUBMIT_FAILED,
    MERCHANDISING_SLOT_SUBMIT_RESET,
);

export const submittedMerchandisingSlotsReducer = createGenericReducer(
    MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
    MERCHANDISING_SLOTS_SUBMIT_RECEIVED,
    MERCHANDISING_SLOTS_SUBMIT_FAILED,
    MERCHANDISING_SLOTS_SUBMIT_RESET,
);
