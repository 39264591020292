import {
    LANDING_PAGES_RECEIVED,
    LANDING_PAGES_REQUESTED,
    LANDING_PAGES_REQUEST_FAILED,
    LANDING_PAGES_SUBMIT_FAILED,
    LANDING_PAGES_SUBMIT_RECEIVED,
    LANDING_PAGES_SUBMIT_REQUESTED,
    LANDING_PAGES_SUBMIT_RESET,
    LANDING_PAGE_CREATE_FAILED,
    LANDING_PAGE_CREATE_RECEIVED,
    LANDING_PAGE_CREATE_REQUESTED,
    LANDING_PAGE_DELETE_FAILED,
    LANDING_PAGE_DELETE_RECEIVED,
    LANDING_PAGE_DELETE_REQUESTED,
    LANDING_PAGE_RECEIVED,
    LANDING_PAGE_REQUESTED,
    LANDING_PAGE_REQUEST_FAILED,
    LANDING_PAGE_REQUEST_RESET,
    LANDING_PAGE_UPDATE_FAILED,
    LANDING_PAGE_UPDATE_RECEIVED,
    LANDING_PAGE_UPDATE_REQUESTED,
} from 'application/campaignForm/landingPageCreate/landingPageCreateTypes';
import { createGenericReducer } from 'common/genericReducer';
import { RequestState } from 'common/RequestState';
import reduceReducers from 'reduce-reducers';

export const submittedLandingPageReducer = createGenericReducer(
    LANDING_PAGE_CREATE_REQUESTED,
    LANDING_PAGE_CREATE_RECEIVED,
    LANDING_PAGE_CREATE_FAILED,
);

export const submittedLandingPagesReducer = createGenericReducer(
    LANDING_PAGES_SUBMIT_REQUESTED,
    LANDING_PAGES_SUBMIT_RECEIVED,
    LANDING_PAGES_SUBMIT_FAILED,
    LANDING_PAGES_SUBMIT_RESET,
);
export const landingPages = reduceReducers(
    createGenericReducer(LANDING_PAGES_REQUESTED, LANDING_PAGES_RECEIVED, LANDING_PAGES_REQUEST_FAILED),
    createGenericReducer(
        LANDING_PAGE_REQUESTED,
        LANDING_PAGE_RECEIVED,
        LANDING_PAGE_REQUEST_FAILED,
        LANDING_PAGE_REQUEST_RESET,
        null,
        {
            data: [],
            requestState: RequestState.NotRequested,
        },
    ),
    createGenericReducer(LANDING_PAGE_UPDATE_REQUESTED, LANDING_PAGE_UPDATE_RECEIVED, LANDING_PAGE_UPDATE_FAILED),
    createGenericReducer(LANDING_PAGE_DELETE_REQUESTED, LANDING_PAGE_DELETE_RECEIVED, LANDING_PAGE_DELETE_FAILED),
);
