import { getVersionedCacheKey } from 'helpers/cacheHelper';
import { CampaignStatus } from 'models/campaign';
import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

const CACHE_EXPIRATION_SECONDS = 3600 * 3;
export interface MetaData {
    pagination: {
        total: number;
        count: number;
        per_page: number;
        current_page: number;
        total_pages: number;
        links?: {
            next?: string;
        };
    };
}

export type CampaignCategoryType = {
    type: string;
    name: string;
    color: string;
    colors: string[];
    textColor: string;
};

// CAMPAIGNS
export const getCampaigns = async data => {
    const token = await oidc.getAccessToken();
    const parameters = {};
    const allowedParams = ['status', 'channel', 'page'];
    allowedParams.forEach(element => (element in data ? (parameters[element] = data[element]) : ''));
    const queryString = Object.keys(parameters).length ? '?' + new URLSearchParams(parameters).toString() : '';
    return callApi('CampaignManagerUrl', 'GET', 'campaigns' + queryString, null, {}, token);
};

export const getCampaign = async (id: number) => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `campaigns/${id}`, null, {}, token);
};

export const createCampaign = async campaign => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'campaigns', campaign, {}, token);
};

export const updateCampaign = async campaign => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `campaigns/${campaign.id}`, campaign, {}, token);
};

export const updateCampaignStatus = async (campaignId: number, status: CampaignStatus) => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `campaigns/${campaignId}/status`, { status: status } as any, {}, token);
};

// CATEGORIES
export const getCampaignCategory = async (id: number) => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `categories/${id}`, null, {}, token);
};
export const createCategory = async category => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'categories', category, {}, token);
};
export const updateCategory = async category => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `categories/${category.id}`, category, {}, token);
};
export const deleteCategory = async category => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', `categories/${category.id}`, {}, {}, token);
};

// MERCHANDISING SLOTS
export const getMerchandisingSlot = async (id: BigInteger) => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `merchandisingSlots/${id}`, null, {}, token);
};

export const createMerchandisingSlot = async merchandisingSlot => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'merchandisingSlots', merchandisingSlot, {}, token);
};
export const updateMerchandisingSlot = async merchandisingSlot => {
    const token = await oidc.getAccessToken();
    return callApi(
        'CampaignManagerUrl',
        'PUT',
        `merchandisingSlots/${merchandisingSlot.id}`,
        merchandisingSlot,
        {},
        token,
    );
};
export const deleteMerchandisingSlot = async merchandisingSlot => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', `merchandisingSlots/${merchandisingSlot.id}`, null, {}, token);
};
export const bulkCreateMerchandisingSlots = async merchandisingSlotsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'merchandisingSlots/bulk/create', merchandisingSlotsBatch, {}, token);
};
export const bulkUpdateMerchandisingSlots = async merchandisingSlotsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', 'merchandisingSlots/bulk/update', merchandisingSlotsBatch, {}, token);
};
export const bulkDeleteMerchandisingSlots = async merchandisingSlotsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'merchandisingSlots/bulk/delete', merchandisingSlotsBatch, {}, token);
};

// OFFERS
export const createOffer = async data => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'offers', data, {}, token);
};

export const updateOffer = async data => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', 'offers/' + data.id, data, {}, token);
};

export const deleteOffer = async id => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', 'offers/' + id, null, {}, token);
};

export const getOfferDiscountTypes = async data => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', 'offers/discountTypes', null, {}, token);
};

// TRANSLATIONS
export const createTranslation = async translation => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'translations', translation, {}, token);
};
export const updateTranslation = async translation => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `translations/${translation.id}`, translation, {}, token);
};
export const deleteTranslation = async translation => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', `translations/${translation.id}`, null, {}, token);
};
export const bulkCreateTranslations = async translationsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'translations/bulk/create', translationsBatch, {}, token);
};
export const bulkUpdateTranslations = async translationsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', 'translations/bulk/update', translationsBatch, {}, token);
};
export const bulkDeleteTranslations = async translationsBatch => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'translations/bulk/delete', translationsBatch, {}, token);
};

// STATIC CONFIGURATIONS (either DB based like vendors or config based)
// Vendors
export const getVendors = async data => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'vendors', null, options, token);
};

// Config categories
export const getConfigCategories = async (): Promise<{ data: CampaignCategoryType[] }> => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'campaigns/categories', null, options, token);
};

export const getCampaignStatuses = async data => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'campaigns/statuses', null, options, token);
};

export const getArticleTypes = async data => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'offers/articleTypes', null, options, token);
};

export const getCampaignChannels = async () => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'campaigns/channels', null, options, token);
};

// Merchandising Slots
export const getMerchandisingSlotPositions = async data => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'merchandisingSlots/positions', null, options, token);
};

//Predefined Translations
export const getPredefinedTranslations = async data => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    let url = 'translations?type=phrase';
    if (data.page) {
        url += `&page=${data.page}`;
    }
    return callApi('CampaignManagerUrl', 'GET', url, null, options, token);
};

// IMAGE
export const uploadImage = async formData => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'image', formData, {}, token);
};

// MEDIA
export const createMedia = async media => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'media', media, {}, token);
};
export const updateMedia = async media => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `media/${media.id}`, media, {}, token);
};

//LANDING PAGES
export const createLandingPage = async landingPage => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'landingPages', landingPage, {}, token);
};
export const updateLandingPage = async landingPage => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `landingPages/${landingPage.id}`, landingPage, {}, token);
};
export const getLandingPage = async landingPageId => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `landingPages/${landingPageId}`, null, {}, token);
};

export const getLandingPagesByCampaignId = async campaignId => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `landingPages/?campaign_id=${campaignId}`, null, {}, token);
};

export const deleteLandingPage = async landingPageId => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', `landingPages/${landingPageId}`, null, {}, token);
};

//PROMOTIONS
export const createPromotion = async promotion => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'POST', 'promotions', promotion, {}, token);
};
export const updatePromotion = async promotion => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'PUT', `promotions/${promotion.id}`, promotion, {}, token);
};
export const getPromotion = async promotionId => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'GET', `promotions/${promotionId}`, null, {}, token);
};
export const deletePromotion = async promotionId => {
    const token = await oidc.getAccessToken();
    return callApi('CampaignManagerUrl', 'DELETE', `promotions/${promotionId}`, null, {}, token);
};
export const getPromotionDefaultMedia = async () => {
    const token = await oidc.getAccessToken();
    const options = {
        cacheKey: getVersionedCacheKey('CAMPAIGN_MANAGER_CACHE'),
        cacheDuration: CACHE_EXPIRATION_SECONDS,
    };
    return callApi('CampaignManagerUrl', 'GET', 'promotions/defaultMedia', null, options, token);
};

//PROMOTION OFFERS
export const createPromotionOffer = async (promotionId, promotionOffer) => {
    const token = await oidc.getAccessToken();
    return callApi(
        'CampaignManagerUrl',
        'POST',
        `promotions/${promotionId.toString()}/offers`,
        promotionOffer,
        {},
        token,
    );
};
export const deletePromotionOffers = async (promotionId, promotionOfferIds) => {
    const token = await oidc.getAccessToken();
    return callApi(
        'CampaignManagerUrl',
        'POST',
        `promotions/${promotionId.toString()}/offers/bulk/delete`,
        promotionOfferIds,
        {},
        token,
    );
};
