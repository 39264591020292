import { attributionsSlice } from 'application/attributions/attributionsSlice';
import { authSlice } from 'application/auth/authSlice';
import { accountsSlice } from 'application/b2b/accountsSlice';
import { getArticleTypesReducer } from 'application/campaignForm/campaignCreate/articleTypesStore';
import { getCampaignChannelsReducer } from 'application/campaignForm/campaignCreate/campaignChannelsStore';
import { campaignFormReducer } from 'application/campaignForm/campaignCreate/campaignFormStore';
import { getConfigCategoriesReducer } from 'application/campaignForm/campaignCreate/configCategoriesStore';
import { getMerchandisingSlotsPositionsReducer } from 'application/campaignForm/campaignCreate/merchandisingSlotsStore';
import { getPredefinedTranslationsReducer } from 'application/campaignForm/campaignCreate/predefinedTranslationStore';
import { getCampaignReducer } from 'application/campaignForm/campaignCreate/requestedCampaignStore';
import { submittedCampaignReducer } from 'application/campaignForm/campaignCreate/submittedCampaignStore';
import { submittedCategoriesReducer } from 'application/campaignForm/campaignCreate/submittedCategoriesStore';
import { visualFeedbackReducer } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import {
    landingPages,
    submittedLandingPageReducer,
    submittedLandingPagesReducer,
} from 'application/campaignForm/landingPageCreate/landingPageCreateReducer';
import {
    submittedMerchandisingSlotReducer,
    submittedMerchandisingSlotsReducer,
} from 'application/campaignForm/merchandisingSlotCreate/merchandisingSlotCreateReducer';
import { discountCodes } from 'application/campaignForm/offerCreate/discountCodesStore';
import { getDiscountTypes, offers, offerTemp } from 'application/campaignForm/offerCreate/offerCreateReducer';
import {
    defaultMediaReducer,
    submittedPromotionOfferReducer,
    submittedPromotionReducer,
    submittedPromotionsReducer,
} from 'application/campaignForm/promotionCreate/promotionCreateReducer';
import {
    campaignsListCurrent,
    campaignsListExpired,
    campaignsListScheduled,
} from 'application/campaignList/campaignListReducer';
import { config } from 'application/config/configReducer';
import { getVendorsReducer } from 'application/config/vendorsStore';
import { discountFormSlice, discountsSlice } from 'application/discounts/discountsSlice';
import { promotionCatalogSlice } from 'application/discounts/promotionCatalogSlice';
import { lodSettingsSlice } from 'application/lod/lodDataSlice';
import { redirectSlice } from 'application/seo/redirectSlice';
import { robotsSlice } from 'application/seo/robotsSlice';
import { seoPagesSlice } from 'application/seo/seoPagesSlice';
import { sitemapSlice } from 'application/seo/sitemapSlice';
import { getAllRoutesReducer } from 'application/storefront/storefrontReducer';
import { bnclSlice } from 'application/vouchers/bnclSlice';
import { combineReducers } from 'redux';
import { translationsSlice } from './application/translation/translationSlice';

export const indexReducer = combineReducers({
    auth: authSlice.reducer,
    campaignsListCurrent,
    campaignsListExpired,
    campaignsListScheduled,
    submittedCampaign: submittedCampaignReducer,
    submittedCategories: submittedCategoriesReducer,
    submittedMerchandisingSlots: submittedMerchandisingSlotsReducer,
    submittedLandingPage: submittedLandingPageReducer,
    submittedLandingPages: submittedLandingPagesReducer,
    submittedPromotion: submittedPromotionReducer,
    submittedPromotions: submittedPromotionsReducer,
    submittedPromotionOffer: submittedPromotionOfferReducer,
    apiConfig: combineReducers({
        articleTypes: getArticleTypesReducer,
        categories: getConfigCategoriesReducer,
        merchandisingSlotsPositions: getMerchandisingSlotsPositionsReducer,
        vendors: getVendorsReducer,
        discountTypes: getDiscountTypes,
        predefinedTranslations: getPredefinedTranslationsReducer,
        channels: getCampaignChannelsReducer,
        defaultMedia: defaultMediaReducer,
    }),
    storefront: combineReducers({
        allRoutes: getAllRoutesReducer,
    }),
    requestedCampaign: getCampaignReducer,
    campaignForm: campaignFormReducer,
    offers,
    landingPages,
    offerTemp,
    merchandisingSlot: submittedMerchandisingSlotReducer,
    config,
    discountCodes,
    visualFeedbackElements: visualFeedbackReducer,
    discountForm: discountFormSlice.reducer,
    discounts: discountsSlice.reducer,
    attributions: attributionsSlice.reducer,
    seo: robotsSlice.reducer,
    lod: lodSettingsSlice.reducer,
    sitemap: sitemapSlice.reducer,
    redirect: redirectSlice.reducer,
    seoPages: seoPagesSlice.reducer,
    b2b: combineReducers({
        accounts: accountsSlice.reducer,
    }),
    vouchers: combineReducers({
        bncl: bnclSlice.reducer,
    }),
    promotionCatalog: promotionCatalogSlice.reducer,
    translations: translationsSlice.reducer,
});
