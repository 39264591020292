import { login } from 'application/auth/authSlice';
import { Loader } from 'components/common/Loader/Loader';
import { checkLocalStorage } from 'helpers/localStorageHelper';
import { isFinished, isLoading } from 'helpers/requestStateHelper';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

export const AlbelliAuthBoundary = ({ children }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const config = useSelector(state => state.config);
    const location = useLocation();
    const history = useHistory();
    const isLocalStorageEnabled = useMemo(checkLocalStorage, []);
    const windowRoute = window.location.pathname + window.location.search;
    const reactRoute = location.pathname + location.search;

    useEffect(() => {
        if (!isFinished(config)) throw new Error('Config should be loaded before authentication');

        if (!isLocalStorageEnabled) {
            return;
        }

        dispatch(login());
    }, []);

    // syncing React Router location with window.location after authentication library redirects
    useEffect(() => {
        if (reactRoute !== windowRoute) {
            history.replace(windowRoute);
        }
    }, [windowRoute, reactRoute]);

    if (!isLocalStorageEnabled) {
        return (
            <div>
                <h4>Please enable site data and cookies in your browser to use the website</h4>
                <p>
                    Your browser is currently set to block site data and cookies. In order to visit the website, you
                    need to allow site data and cookies.
                </p>
            </div>
        );
    }

    if (isLoading(auth)) {
        return <Loader />;
    }
    return children;
};
