import { watchArticleTypeStore } from 'application/campaignForm/campaignCreate/articleTypesStore';
import { watchCampaignChannelsStore } from 'application/campaignForm/campaignCreate/campaignChannelsStore';
import { watchCampaignFormStore } from 'application/campaignForm/campaignCreate/campaignFormStore';
import { watchConfigCategoriesStore } from 'application/campaignForm/campaignCreate/configCategoriesStore';
import { watchMerchandisingSlotsStore } from 'application/campaignForm/campaignCreate/merchandisingSlotsStore';
import { watchPredefinedTranslationsStore } from 'application/campaignForm/campaignCreate/predefinedTranslationStore';
import { watchCampaignStore } from 'application/campaignForm/campaignCreate/requestedCampaignStore';
import { watchSubmittedFormStore } from 'application/campaignForm/campaignCreate/submittedCampaignStore';
import { watchSubmittedCategoriesStore } from 'application/campaignForm/campaignCreate/submittedCategoriesStore';
import { watchVendorsStore } from 'application/config/vendorsStore';
import { watchLandingPageCreationConfig } from 'application/campaignForm/landingPageCreate/landingPageCreateSaga';
import { watchMerchandisingSlotCreation } from 'application/campaignForm/merchandisingSlotCreate/merchandisingSlotCreateSaga';
import { watchDiscountCodes } from 'application/campaignForm/offerCreate/discountCodesStore';
import { watchRequestOfferCreate } from 'application/campaignForm/offerCreate/offerCreateSaga';
import { watchPromotionCreationConfig } from 'application/campaignForm/promotionCreate/promotionCreateSaga';
import { watchRequestCampaigns } from 'application/campaignList/campaignListSaga';
import { watchConfig } from 'application/config/configSaga';
import { watchStorefront } from 'application/storefront/storefrontSaga';
import { watchErrors } from 'common/errorSaga';
import { all } from 'redux-saga/effects';

export function* indexSaga() {
    return yield all([
        watchErrors(),
        watchConfig(),
        watchRequestCampaigns(),
        watchRequestOfferCreate(),
        watchSubmittedFormStore(),
        watchMerchandisingSlotCreation(),
        watchStorefront(),
        watchLandingPageCreationConfig(),
        watchPromotionCreationConfig(),
        watchArticleTypeStore(),
        watchCampaignChannelsStore(),
        watchVendorsStore(),
        watchSubmittedCategoriesStore(),
        watchCampaignStore(),
        watchPredefinedTranslationsStore(),
        watchMerchandisingSlotsStore(),
        watchCampaignFormStore(),
        watchConfigCategoriesStore(),
        watchDiscountCodes(),
    ]);
}
