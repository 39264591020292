export enum RequestState {
    NotRequested = 'NotRequested',
    InProgress = 'InProgress',
    Finished = 'Finished',
    Failed = 'Failed',
}

export type RequestType<Result = any, Initial = undefined> = {
    requestState: RequestState;
} & (Initial extends undefined ? { payload?: Result } : { payload: Result });

export const getInitialRequestState = <T, I extends T | undefined>(initialPayload?: I): RequestType<T, I> => ({
    requestState: RequestState.NotRequested,
    payload: initialPayload as T,
});
