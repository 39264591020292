import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

type VoucherExtraOptionType = {
    description?: string;
    basePrice: number;
    validFrom: Date;
    validTo: Date;
    preDesignedProductId: string;
};

export const createVoucherExtraOption = async (
    vendorName: string,
    data: VoucherExtraOptionType,
): Promise<{ extraOptionId: string } & VoucherExtraOptionType> => {
    const token = await oidc.getAccessToken();

    const channel = vendorName === 'onskefoto.se' ? 'albelli.se' : vendorName;

    return await callApi('CatalogueApiUrl', 'POST', `/Articles/${channel}/Vouchers/ExtraOptions`, data, {}, token);
};

export const updateVoucherExtraOption = async (
    extraOptionId: string,
    vendorName: string,
    data: VoucherExtraOptionType,
): Promise<void> => {
    const token = await oidc.getAccessToken();

    const channel = vendorName === 'onskefoto.se' ? 'albelli.se' : vendorName;

    return await callApi(
        'CatalogueApiUrl',
        'PUT',
        `/Articles/${channel}/Vouchers/ExtraOptions/${extraOptionId}`,
        data,
        {},
        token,
    );
};
