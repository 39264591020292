import { Alert, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Account } from 'apis/b2bAccounts';
import { CustomTableCell } from 'components/common/CustomTable/CustomTableCell';
import { TableStatusRow } from 'components/common/TableStatusRow/TableStatusRow';
import { formatDistance } from 'date-fns';
import React from 'react';
import { withStyles } from 'tss-react/mui';
import { humanizeAccountStatus } from './b2bAccountsHelpers';

type AccountsTableProps = {
    items: Account[];
    header?: string;
    onRowClick: (item: Account) => void;
    onSorting: () => void;
    ascendingOrder: boolean;
    isSortDisabled: boolean;
};

const StyledTableRow = withStyles(TableRow, theme => ({
    root: {
        cursor: 'pointer',
        height: '37px',

        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.selected,
        },
    },
}));

const getDays = date => formatDistance(new Date(date), new Date(), { addSuffix: true });

const convertStatus = status => {
    switch (status) {
        case 'Required':
            return 'draft';
        case 'Approved':
            return 'published';
        default:
            return 'cancelled';
    }
};

const AccountTableRow = ({ account, onClick }) => {
    return (
        <StyledTableRow onClick={onClick.bind(account)} data-cy="AccountTableRow">
            <TableStatusRow status={convertStatus(account.status)} />
            <TableCell component="th" scope="row" align="left">
                {account.accountId}
            </TableCell>
            <TableCell component="th" scope="row" align="left">
                {account.email}
            </TableCell>
            <TableCell component="th" scope="row" align="left">
                {humanizeAccountStatus(account)}
            </TableCell>
            <TableCell component="th" scope="row" align="left">
                {account.reviewRequestedDate && getDays(account.reviewRequestedDate)}
            </TableCell>
        </StyledTableRow>
    );
};

const AccountsTable = (props: AccountsTableProps): React.ReactElement<AccountsTableProps> => {
    return (
        <>
            {props.items.length ? (
                <Box>
                    <TableContainer>
                        <Table aria-label="Accounts Table" size="small" data-cy={props['data-cy']}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell style={{ width: '4px', padding: '0px' }}></CustomTableCell>
                                    <CustomTableCell align="left">Account id</CustomTableCell>
                                    <CustomTableCell align="left">E-mail address</CustomTableCell>
                                    <CustomTableCell align="left">Status</CustomTableCell>
                                    <CustomTableCell align="left">
                                        {props.isSortDisabled ? (
                                            <>Requested</>
                                        ) : (
                                            <TableSortLabel
                                                active={true}
                                                direction={props.ascendingOrder ? 'asc' : 'desc'}
                                                onClick={props.onSorting}
                                                data-cy="B2BAccounts-RequestedSort"
                                            >
                                                Requested
                                            </TableSortLabel>
                                        )}
                                    </CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.items.map((item, id) => (
                                    <AccountTableRow account={item} onClick={() => props.onRowClick(item)} key={id} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                <Alert severity="info">No Accounts Available</Alert>
            )}
        </>
    );
};

export default AccountsTable;
