export const ARTICLE_TYPE_WALLDECOR = 'Walldecor';
export const ARTICLE_TYPE_CALENDAR = 'Calendar';
export const ARTICLE_TYPE_CARD = 'Card';
export const ARTICLE_TYPE_PRINTS = 'Prints';
export const ARTICLE_TYPE_PHOTOBOOK = 'Photobook';
export const ARTICLE_TYPE_PADDED_PHOTOBOOK = 'PaddedPhotoBook';
export const ARTICLE_TYPE_CUTOUT_PHOTOBOOK = 'CutoutPhotoBook';
export const ARTICLE_TYPE_MOMENTS_PHOTOBOOK = 'MomentsPhotoBook';
export const ARTICLE_TYPE_CANVAS = 'Canvas';
export const ARTICLE_TYPE_FOREX = 'Forex';
export const ARTICLE_TYPE_ALUMINIUM = 'Aluminium';
export const ARTICLE_TYPE_PLEXIGLAS = 'Plexiglas';
export const ARTICLE_TYPE_POSTER = 'Poster';
export const ARTICLE_TYPE_MUG = 'Mug';
export const ARTICLE_TYPE_LOVEMUG = 'LoveMug';
export const ARTICLE_TYPE_MAGICMUG = 'MagicMug';
export const ARTICLE_TYPE_MAGNET = 'Magnet';
export const ARTICLE_TYPE_JIGSAW = 'Jigsaw';
export const ARTICLE_TYPE_CUSHION = 'Cushion';
export const ARTICLE_TYPE_PHOTOBLOCK = 'Photoblock';
export const ARTICLE_TYPE_STUDENTPLAKAT = 'StudentPlakat';
export const ARTICLE_TYPE_VOUCHER = 'Voucher';

export const ARTICLE_TYPE_ALL = [
    ARTICLE_TYPE_CALENDAR,
    ARTICLE_TYPE_CARD,
    ARTICLE_TYPE_PRINTS,
    ARTICLE_TYPE_PHOTOBOOK,
    ARTICLE_TYPE_PADDED_PHOTOBOOK,
    ARTICLE_TYPE_CUTOUT_PHOTOBOOK,
    ARTICLE_TYPE_MOMENTS_PHOTOBOOK,
    ARTICLE_TYPE_CANVAS,
    ARTICLE_TYPE_FOREX,
    ARTICLE_TYPE_ALUMINIUM,
    ARTICLE_TYPE_PLEXIGLAS,
    ARTICLE_TYPE_POSTER,
    ARTICLE_TYPE_LOVEMUG,
    ARTICLE_TYPE_MAGICMUG,
    ARTICLE_TYPE_MUG,
    ARTICLE_TYPE_MAGNET,
    ARTICLE_TYPE_JIGSAW,
    ARTICLE_TYPE_CUSHION,
    ARTICLE_TYPE_PHOTOBLOCK,
    ARTICLE_TYPE_VOUCHER,
];

export const WALLDECOR_ARTICLES = [
    ARTICLE_TYPE_ALUMINIUM,
    ARTICLE_TYPE_PLEXIGLAS,
    ARTICLE_TYPE_FOREX,
    ARTICLE_TYPE_CANVAS,
    ARTICLE_TYPE_POSTER,
];

export const ARTICLE_TYPE_EXCLUDED = [ARTICLE_TYPE_WALLDECOR, ARTICLE_TYPE_VOUCHER];

// Arbitrary products order defined by marketing
export const ARTICLE_TYPE_ORDER = {
    Photobook: 1,
    PaddedPhotoBook: 2,
    CutoutPhotoBook: 3,
    MomentsPhotoBook: 4,
    Walldecor: 5,
    Canvas: 6,
    Forex: 7,
    Aluminium: 8,
    Plexiglas: 9,
    Poster: 10,
    Prints: 11,
    Calendar: 12,
    Card: 13,
    LoveMug: 14,
    MagicMug: 15,
    Mug: 16,
    Magnet: 17,
    Jigsaw: 18,
    Cushion: 19,
    Photoblock: 20,
    StudentPlakat: 21,
    Voucher: 22,
};

/**
 * Filter out products that should not be present in offers
 */
export const retriveFilteredProducts = (articleTypes: string[]): string[] => {
    return articleTypes.filter(articleType => !ARTICLE_TYPE_EXCLUDED.includes(articleType));
};

/**
 * Verify if a given article type belongs to the walldecor group
 */
export const isWallDecor = (articleType: string): boolean => {
    return WALLDECOR_ARTICLES.some(wd => wd === articleType);
};
