import { callApi } from 'apis/callApi';
import { oidc } from 'services/oidcService';
import { Source } from './sources';

export interface Channel {
    id: number;
    name: string;
    shortCode: string;
    description: string;
    sources: Source[];
}

export const getChannels = async (): Promise<Channel[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('AttributionApiUrl', 'GET', 'channels', null, {}, token);
};
