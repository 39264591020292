import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export interface SpinnerProps {
    boxClass?: string;
    iconClass?: string;
}

const useStyles = makeStyles()(() => ({
    box: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(255 255 255 / 50%)',
        zIndex: 1,
    },
    spin: {
        animation: 'spin 1s linear infinite',
    },
}));

// use inside `position: relative` element
export const Spinner: React.FC<SpinnerProps> = ({ boxClass = '', iconClass = '' }: SpinnerProps) => {
    const { classes, cx } = useStyles();

    return (
        <Box className={cx(classes.box, boxClass)}>
            <AutorenewIcon className={cx(classes.spin, iconClass)} />
        </Box>
    );
};
