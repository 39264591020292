import { getCampaigns } from 'apis/campaignApi';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { doCall } from 'common/genericSaga';
import { receiveCurrentCampaigns, receiveExpiredCampaigns, receiveScheduledCampaigns } from './campaignListActions';
import {
    CAMPAIGN_CURRENT_REQUESTED,
    CAMPAIGN_CURRENT_REQUEST_FAILED,
    CAMPAIGN_SCHEDULED_REQUESTED,
    CAMPAIGN_SCHEDULED_REQUEST_FAILED,
    CAMPAIGN_EXPIRED_REQUESTED,
    CAMPAIGN_EXPIRED_REQUEST_FAILED,
} from './campaignListTypes';

export function* doRequestCampaigns(action, requestInfo) {
    if (action.status) {
        requestInfo['status'] = action.status.join();
    }
    requestInfo['channel'] = action.channel;
    requestInfo['page'] = action.page;

    const campaigns = yield call(getCampaigns, requestInfo);

    switch (action.type) {
        case CAMPAIGN_CURRENT_REQUESTED:
            yield put(receiveCurrentCampaigns(campaigns));
            break;

        case CAMPAIGN_SCHEDULED_REQUESTED:
            yield put(receiveScheduledCampaigns(campaigns));
            break;

        case CAMPAIGN_EXPIRED_REQUESTED:
            yield put(receiveExpiredCampaigns(campaigns));
            break;
    }
}

export function* watchRequestCampaigns() {
    yield all([
        takeEvery(CAMPAIGN_CURRENT_REQUESTED, doCall(doRequestCampaigns, CAMPAIGN_CURRENT_REQUEST_FAILED)),
        takeEvery(CAMPAIGN_SCHEDULED_REQUESTED, doCall(doRequestCampaigns, CAMPAIGN_SCHEDULED_REQUEST_FAILED)),
        takeEvery(CAMPAIGN_EXPIRED_REQUESTED, doCall(doRequestCampaigns, CAMPAIGN_EXPIRED_REQUEST_FAILED)),
    ]);
}
