import { Catalog } from '@albelli/ecom-promotions-editor';
import { validate as validateDiscount } from '@albelli/ecom-promotions-editor/providers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, FormControl, Grid, InputAdornment, Link, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    BnclOffer,
    OfferDiscountDetails,
    VendorCampaignStatus,
    countBnclDiscountDetailsPrice,
    getDefaultOfferImage,
} from 'apis/bnclCampaign';
import { PapDetailsType, getPapDetails } from 'apis/productCatalogApi';
import { getDiscount } from 'apis/promotionsApi';
import { showStatusMessage } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import BorderedBox from 'components/common/BorderedBox/BorderedBox';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { FormikDateTimePicker } from 'components/common/Form/FormikDateTimePicker';
import { FormikDiscountEditor } from 'components/common/Form/FormikDiscountEditor';
import { FormikTextField } from 'components/common/Form/FormikTextField';
import ImageUploadInput from 'components/common/Form/ImageUploadInput';
import { Spinner } from 'components/common/Spinner/Spinner';
import useThrottleCallback from 'components/hooks/useThrottleCallback';
import { isPast } from 'date-fns';
import { FormikErrors, useFormik } from 'formik';
import { isValidDate, parseAtomToDate } from 'helpers/dateHelper';
import { getStorefrontUrl } from 'helpers/urlHelper';
import _ from 'lodash';
import { DiscountDefinition } from 'models/discounts/discount';
import { Vendor } from 'models/vendor';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { makeStyles } from 'tss-react/mui';
import BnclDiscountConstructor, { BnclDiscountConstructorHandlers } from './BnclDiscountConstructor';
import CheckoutTextField from './particles/CheckoutTextField';
import OptionsEditor from './particles/OptionsEditor';
import useBnclOfferOptions from './particles/useBnclOfferOptions';

type BnclOfferFormProps = {
    offer: BnclOffer | null;
    vendor: Vendor;
    campaignStatus: VendorCampaignStatus;
};

type FormType = {
    discountCode: string;
    imagePath: string;
    imageFile: File | null;
    productBlockTitle: string;
    checkoutLabel: string;
    voucherPrice: number;
    productPrice: number;
    expirationDate: Date;
    includedOptions: string[];
    excludedOptions: string[];
};

export type BnclOfferFormResult = {
    voucherId?: string;
    discount: {
        initial: DiscountDefinition | null;
        action: 'create' | 'update' | null;
        code: string;
    };
    discountDetails: OfferDiscountDetails;
    image: { file: File; path?: never } | { file?: never; path: string };
    productBlockTitle: string;
    checkoutLabel: string;
    voucherPrice: number;
    productPrice: number;
    expirationDate: Date;
    includedOptions: string[];
    excludedOptions: string[];
};

export interface BnclOfferHandlers {
    /**
     * returns void if invalid
     */
    submit: () => Promise<BnclOfferFormResult | void>;
    isModified: () => boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    wrapper: {
        padding: theme.spacing(1),
        border: `0.5px solid ${theme.palette.grey[400]}`,
        width: '100%',
    },
    column: {
        height: '100%',
        padding: theme.spacing(1.5),
    },
    title: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    showDefinitionText: {
        marginTop: theme.spacing(1.5),
        fontSize: theme.typography.pxToRem(13),
        marginBottom: theme.spacing(1),
        color: '#676767',
        lineHeight: 1.2,
        fontStyle: 'italic',
        cursor: 'pointer',
        '& svg': {
            marginBottom: -3,
        },
    },
    errorText: {
        color: theme.palette.error.main,
    },
    comment: {
        fontSize: theme.typography.pxToRem(10),
        marginBottom: theme.spacing(1),
        color: '#676767',
        lineHeight: 1.2,
    },
    discountEditorContainer: {
        border: `1px solid ${CustomColors.border}`,
        borderRadius: '3px',
    },
    discountError: {
        borderColor: theme.palette.error.main,
    },
}));

const validate = (values: FormType, { catalog, checkoutPrefix }: { catalog: Catalog; checkoutPrefix: string }) => {
    const errors: FormikErrors<FormType> = {};

    if (values.discountCode.trim()) {
        const codeErrors = validateDiscount(values.discountCode, catalog);
        if (codeErrors.length) {
            errors.discountCode = codeErrors.map(e => e.message).join('\n');
        }
    }

    if (!values.imagePath && !values.imageFile) {
        errors.imageFile = 'Image is required';
    }
    if (!values.productBlockTitle) {
        errors.productBlockTitle = 'Product block title is required';
    }
    if (!values.checkoutLabel || !values.checkoutLabel.replace(checkoutPrefix, '')) {
        errors.checkoutLabel = 'Checkout label is required';
    }
    if (!values.voucherPrice) {
        errors.voucherPrice = 'Voucher price is required';
    } else if (values.voucherPrice <= 0) {
        errors.voucherPrice = 'Voucher price must be greater than 0';
    } else if (values.productPrice && values.voucherPrice > values.productPrice) {
        errors.voucherPrice = 'Voucher price must be less than product price';
    }
    if (!values.productPrice) {
        errors.productPrice = 'Product price is required';
    } else if (values.productPrice <= 0) {
        errors.productPrice = 'Product price must be greater than 0';
    }
    if (!values.expirationDate) {
        errors.expirationDate = 'Expiration date is required';
    } else if (!isValidDate(values.expirationDate)) {
        errors.expirationDate = 'Expiration date should be a valid date';
    } else if (isPast(values.expirationDate)) {
        errors.expirationDate = 'Expiration date should not be in the past';
    }
    if (
        values.excludedOptions.filter(item => !!item).length === 0 &&
        values.includedOptions.filter(item => !!item).length === 0
    ) {
        errors.excludedOptions = errors.includedOptions = 'At least one included or excluded option is required.';
    }

    return errors;
};

const offerToForm = (data: BnclOffer, code = ''): FormType => ({
    discountCode: code,
    imagePath: data.imagePath,
    imageFile: null,
    productBlockTitle: data.productBlockTitle,
    checkoutLabel: data.checkoutLabel,
    voucherPrice: data.voucherPrice,
    productPrice: data.productPrice,
    expirationDate: parseAtomToDate(data.expirationDate),
    includedOptions: data.includedOptions.length ? data.includedOptions : [''],
    excludedOptions: data.excludedOptions.length ? data.excludedOptions : [''],
});

function BnclOfferForm(
    { offer, vendor, campaignStatus = VendorCampaignStatus.DRAFT }: BnclOfferFormProps,
    ref,
): React.ReactElement {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const catalog = useSelector(state => state.promotionCatalog);
    const constructorRef = React.useRef<BnclDiscountConstructorHandlers>();
    const [discountDefinition, setDiscountDefinition] = useState<DiscountDefinition | null>(null);
    const [discountDetails, setDiscountDetails] = useState<OfferDiscountDetails | null>(offer?.discountDetails || null);
    const [papDetails, setPapDetails] = useState<PapDetailsType | null>(null);
    const [isDefinitionHidden, setIsDefinitionHidden] = useState(true);
    const [isPriceLoading, setIsPriceLoading] = useState(false);
    const [defaultOfferImage, setDefaultOfferImage] = useState<{ file: File; path: string } | null>(null);
    const translations = useSelector(state => state.translations.list);
    const checkoutPrefix = useMemo(() => {
        const tr = translations.payload.find(t => t.key === 'bncl.checkoutlabel.prefix');
        return tr ? `${tr.value}: ` : '';
    }, [translations]);

    const expirationDate = new Date();
    expirationDate.setHours(23);
    expirationDate.setMinutes(59);

    const formik = useFormik<FormType>({
        initialValues: {
            discountCode: '',
            imagePath: '',
            imageFile: null,
            productBlockTitle: '',
            checkoutLabel: checkoutPrefix,
            voucherPrice: 0,
            productPrice: 0,
            expirationDate,
            includedOptions: [''],
            excludedOptions: [''],
        },
        onSubmit: async _ => _,
        validate: (values: FormType) => validate(values, { catalog: catalog.payload, checkoutPrefix }),
    });

    const { options, usedOptionKeys } = useBnclOfferOptions({
        papDetails,
        formik,
        discountDetails,
        isInitialDetails: discountDetails === offer?.discountDetails,
    });

    useEffect(() => {
        if (!offer) return;
        formik.setValues(offerToForm(offer));

        let isMounted = true;

        (async (id: string) => {
            try {
                const discount = await getDiscount(id);
                if (!isMounted) return;
                if (discount) {
                    setDiscountDefinition(discount);
                    formik.setFieldValue('discountCode', discount.definition);
                } else {
                    formik.setFieldValue('discountCode', 'Does not exist anymore');
                }
            } catch (error) {
                if (isMounted) formik.setFieldValue('discountCode', 'Failed to load');
            }
        })(offer.discountId);

        (async (papId: string, vendorName: string) => {
            try {
                const papDetails = await getPapDetails(papId, vendorName);
                if (!isMounted) return;
                setPapDetails(papDetails);
            } catch (error) {
                if (isMounted) dispatch(showStatusMessage(`${papId} does not exist is Catalog`, 'error'));
            }
        })(offer.discountDetails.papId, vendor.name);

        return () => {
            isMounted = false;
        };
    }, [offer]);

    useThrottleCallback(async () => {
        if (!discountDetails || discountDetails === offer?.discountDetails) return;
        setIsPriceLoading(true);
        try {
            const { productPrice } = await countBnclDiscountDetailsPrice(vendor.name, discountDetails);
            formik.setFieldValue('productPrice', productPrice);
        } catch (err) {
            dispatch(showStatusMessage('Failed to count Product Price', 'error'));
        } finally {
            setIsPriceLoading(false);
        }
    }, [discountDetails]);

    const getDefaultImage = async papId => {
        const defaultImage = await getDefaultOfferImage(papId, vendor);
        if (!defaultImage) {
            setDefaultOfferImage(null);
            formik.setFieldValue('imageFile', null);
            return;
        }

        setDefaultOfferImage({
            file: defaultImage.file,
            path: defaultImage.path,
        });
        formik.setFieldValue('imageFile', defaultImage.file);
    };

    const getNewPapDetails = async (papId: string) => {
        try {
            const papDetails = await getPapDetails(papId, vendor.name);
            setPapDetails(papDetails);
        } catch (error) {
            dispatch(showStatusMessage(`${papId} does not exist in Catalog`, 'error'));
        }
    };

    const onDiscountDetailsChange = (newDiscountDetails: OfferDiscountDetails) => {
        const isPapChanged = newDiscountDetails.papId !== discountDetails?.papId;
        if (isPapChanged) {
            setPapDetails(null);
            formik.setFieldValue('includedOptions', []);
            formik.setFieldValue('excludedOptions', []);
            const defaultTitle = translations.payload.find(
                t => t.key === `products.${newDiscountDetails.papId}.fullTitle`,
            );
            if (defaultTitle) {
                formik.setFieldValue('productBlockTitle', defaultTitle.value);
                formik.setFieldValue('checkoutLabel', `${checkoutPrefix}${defaultTitle.value}`);
            } else {
                formik.setFieldValue('productBlockTitle', '');
                formik.setFieldValue('checkoutLabel', checkoutPrefix);
            }
            getDefaultImage(newDiscountDetails.papId);
            getNewPapDetails(newDiscountDetails.papId);
        }
        setDiscountDetails(newDiscountDetails);
    };

    const isDisabled = campaignStatus !== VendorCampaignStatus.DRAFT && !!offer;

    useImperativeHandle(
        ref,
        (): BnclOfferHandlers => ({
            submit: async () => {
                const [vals, discountDetails] = await Promise.all([
                    (await formik.submitForm()) as FormType | null,
                    constructorRef.current?.submit(),
                ]);
                if (!vals || !discountDetails) return;
                const formResult: BnclOfferFormResult = {
                    voucherId: offer?.voucherId,
                    discount: {
                        initial: discountDefinition,
                        action: discountDefinition
                            ? discountDefinition.definition === vals.discountCode
                                ? null
                                : 'update'
                            : 'create',
                        code: vals.discountCode,
                    },
                    discountDetails,
                    image: vals.imageFile ? { file: vals.imageFile } : { path: vals.imagePath },
                    productBlockTitle: vals.productBlockTitle,
                    checkoutLabel: vals.checkoutLabel,
                    voucherPrice: vals.voucherPrice,
                    productPrice: vals.productPrice,
                    expirationDate: vals.expirationDate,
                    includedOptions: vals.includedOptions.filter(item => !!item),
                    excludedOptions: vals.excludedOptions.filter(item => !!item),
                };
                return formResult;
            },
            isModified: () => {
                const initialValues = offer ? offerToForm(offer, discountDefinition?.definition) : formik.initialValues;

                return !_.isEqual(initialValues, formik.values) || constructorRef.current?.isModified() || false;
            },
        }),
    );

    return (
        <Box className={classes.wrapper} data-cy="Bncl-OfferForm">
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <BorderedBox className={classes.column}>
                        <Box component="h3" className={classes.title}>
                            Create discount definition
                        </Box>
                        <BnclDiscountConstructor
                            ref={constructorRef}
                            details={offer?.discountDetails}
                            vendor={vendor}
                            onChange={(form: OfferDiscountDetails, code: string) => {
                                formik.setFieldValue('discountCode', code);
                                onDiscountDetailsChange(form);
                            }}
                            disabled={isDisabled}
                        />
                        <Typography
                            className={cx({
                                [classes.showDefinitionText]: true,
                                [classes.errorText]: !!formik.touched.discountCode && !!formik.errors.discountCode,
                            })}
                            component="p"
                            onClick={() => setIsDefinitionHidden(!isDefinitionHidden)}
                            data-cy="Bncl-OfferForm-ShowDefinition"
                        >
                            <span>show generated discount definition</span>
                            {isDefinitionHidden ? (
                                <ExpandMoreIcon fontSize="small" />
                            ) : (
                                <ExpandLessIcon fontSize="small" />
                            )}
                        </Typography>

                        {!isDefinitionHidden && (
                            <Typography className={classes.comment} component="p">
                                See the{' '}
                                <Link
                                    href="https://wiki.albelli.net/wiki/Discount_definition_FAQ"
                                    target="_blank"
                                    rel="noreferrer"
                                    underline="always"
                                >
                                    FAQ
                                </Link>{' '}
                                for more information on discounts, only 1 PAP allowed for BNCL
                            </Typography>
                        )}
                        <FormikDiscountEditor
                            formik={formik}
                            name="discountCode"
                            disabled={true}
                            hidden={isDefinitionHidden}
                            height="170px"
                            cy="Bncl-OfferForm-DiscountEditor"
                        />
                        <Box component="h3" className={classes.title}>
                            Offer image*
                        </Box>
                        <FormControl error={!!formik.errors.imageFile}>
                            <ImageUploadInput
                                name="offerImg"
                                buttonText="select image"
                                previewWidth={137}
                                fileValue={defaultOfferImage?.file || null}
                                filePath={getStorefrontUrl(vendor.name, offer?.imagePath || defaultOfferImage?.path)}
                                error={!!formik.errors.imageFile}
                                helperText={formik.touched.imageFile ? formik.errors.imageFile : ''}
                                handleChange={file => {
                                    formik.setFieldValue('imageFile', file);
                                }}
                                disabled={isDisabled}
                                allowDeletion={false}
                            />
                        </FormControl>
                    </BorderedBox>
                </Grid>
                <Grid item sm={6}>
                    <BorderedBox className={classes.column}>
                        <Box component="h3" className={classes.title}>
                            Offer details
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <FormikTextField
                                    label="Product block title"
                                    name="productBlockTitle"
                                    fullWidth
                                    required
                                    formik={formik}
                                    disabled={isDisabled}
                                    data-cy="Bncl-OfferForm-ProductBlockTitleInput"
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <CheckoutTextField formik={formik} disabled={false} prefix={checkoutPrefix} />
                            </Grid>

                            <Grid item sm={6} md={3}>
                                <FormikTextField
                                    label="Voucher price"
                                    name="voucherPrice"
                                    type="number"
                                    fullWidth
                                    required
                                    formik={formik}
                                    disabled={isDisabled}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{vendor.currency.symbol}</InputAdornment>
                                        ),
                                    }}
                                    data-cy="Bncl-OfferForm-VoucherPriceInput"
                                />
                            </Grid>
                            <Grid item sm={6} md={3} style={{ position: 'relative' }}>
                                {isPriceLoading && <Spinner />}
                                <FormikTextField
                                    label="Product price"
                                    name="productPrice"
                                    type="number"
                                    fullWidth
                                    required
                                    formik={formik}
                                    disabled={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{vendor.currency.symbol}</InputAdornment>
                                        ),
                                    }}
                                    data-cy="Bncl-OfferForm-ProductPriceInput"
                                />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                <FormikDateTimePicker
                                    label="Discount expiry date"
                                    name="expirationDate"
                                    fullWidth
                                    required
                                    formik={formik}
                                    disabled={isDisabled}
                                    InputLabelProps={{ shrink: true }}
                                    data-cy="Bncl-OfferForm-ExpiryDate"
                                    showTzHints
                                />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                <OptionsEditor
                                    formik={formik}
                                    label={'Included in the offer'}
                                    name={'includedOptions'}
                                    disabled={false}
                                    options={options.included}
                                    usedOptionKeys={usedOptionKeys}
                                />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                <OptionsEditor
                                    formik={formik}
                                    label={'Excluded from the offer'}
                                    name={'excludedOptions'}
                                    disabled={false}
                                    options={options.excluded}
                                    usedOptionKeys={usedOptionKeys}
                                />
                            </Grid>
                        </Grid>
                    </BorderedBox>
                </Grid>
            </Grid>
        </Box>
    );
}

export default forwardRef(BnclOfferForm);
