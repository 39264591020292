import {
    SF_ALL_ROUTES_RECEIVED,
    SF_ALL_ROUTES_REQUESTED,
    SF_ALL_ROUTES_REQUEST_FAILED,
} from 'application/storefront/storefrontActions';
import { createGenericReducer } from 'common/genericReducer';
import { RequestState } from 'common/RequestState';
import { Vendor } from 'models/vendor';

const intialAllRoutesState = { data: {}, requestState: RequestState.NotRequested };
const customAllRoutesReducer = (
    allRoutes = intialAllRoutesState,
    action: { type: string; payload: any; vendor: Vendor; error?: any; correlationId?: string },
) => {
    switch (action.type) {
        case SF_ALL_ROUTES_RECEIVED:
            return {
                ...allRoutes,
                data: { ...allRoutes.data, [action.vendor.locale]: action.payload },
                requestState: RequestState.Finished,
            };
        case SF_ALL_ROUTES_REQUESTED:
            return { ...allRoutes, requestState: RequestState.InProgress };
        case SF_ALL_ROUTES_REQUEST_FAILED:
            return {
                ...allRoutes,
                error: action.error,
                requestState: RequestState.Failed,
                correlationId: action.correlationId,
            };

        default:
            return allRoutes;
    }
};

export const getAllRoutesReducer = createGenericReducer(null, null, null, intialAllRoutesState, customAllRoutesReducer);
