export const LANDING_PAGE_CREATE_REQUESTED = 'LANDING_PAGE_CREATE_REQUESTED';
export const LANDING_PAGE_CREATE_RECEIVED = 'LANDING_PAGE_CREATE_RECEIVED';
export const LANDING_PAGE_CREATE_FAILED = 'LANDING_PAGE_CREATE_FAILED';

export const LANDING_PAGES_REQUESTED = 'LANDING_PAGES_REQUESTED';
export const LANDING_PAGES_RECEIVED = 'LANDING_PAGES_RECEIVED';
export const LANDING_PAGES_REQUEST_FAILED = 'LANDING_PAGES_REQUEST_FAILED';

export const LANDING_PAGES_SUBMIT_REQUESTED = 'LANDING_PAGES_SUBMIT_REQUESTED';
export const LANDING_PAGES_SUBMIT_RECEIVED = 'LANDING_PAGES_SUBMIT_RECEIVED';
export const LANDING_PAGES_SUBMIT_FAILED = 'LANDING_PAGES_SUBMIT_FAILED';
export const LANDING_PAGES_SUBMIT_RESET = 'LANDING_PAGES_SUBMIT_RESET';

export const LANDING_PAGE_UPDATE_REQUESTED = 'LANDING_PAGE_UPDATE_REQUESTED';
export const LANDING_PAGE_UPDATE_RECEIVED = 'LANDING_PAGE_UPDATE_RECEIVED';
export const LANDING_PAGE_UPDATE_FAILED = 'LANDING_PAGE_UPDATE_FAILED';

export const LANDING_PAGE_DELETE_REQUESTED = 'LANDING_PAGE_DELETE_REQUESTED';
export const LANDING_PAGE_DELETE_RECEIVED = 'LANDING_PAGE_DELETE_RECEIVED';
export const LANDING_PAGE_DELETE_FAILED = 'LANDING_PAGE_DELETE_FAILED';

export const LANDING_PAGE_REQUESTED = 'LANDING_PAGE_REQUESTED';
export const LANDING_PAGE_RECEIVED = 'LANDING_PAGE_RECEIVED';
export const LANDING_PAGE_REQUEST_FAILED = 'LANDING_PAGE_REQUEST_FAILED';
export const LANDING_PAGE_REQUEST_RESET = 'LANDING_PAGE_REQUEST_RESET';
