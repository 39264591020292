import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export const CATEGORY_TYPE = {
    SFP: 'Storefront Platform',
    SEO: 'SEO Page',
} as const;

export type SeoPagesVendorDto = {
    name: string;
    url: string;
    isIncomplete?: boolean;
};

export type SeoPagesDto = {
    pageId: string;
    category: (typeof CATEGORY_TYPE)[keyof typeof CATEGORY_TYPE];
    vendors: SeoPagesVendorDto[];
};

type SeoPageMetaDto = {
    title: string;
    description: string;
};

type OpenGraphMetaDto = {
    title: string;
    description: string;
    image?: string;
};

type MetaRobots = {
    follow: boolean;
    index: boolean;
};

type StorefrontPageVendorMetadataDto = {
    meta: SeoPageMetaDto;
    openGraph: OpenGraphMetaDto;
    canonicalUrl: string;
    metaRobots: MetaRobots;
    structuredJson: string;
};

export type StorefrontPageVendorDto = {
    vendorName: string;
    pageUrl: string;
    metadata: StorefrontPageVendorMetadataDto;
};

export type StorefrontPageDto = {
    pageId: string;
    category: string;
    vendors: Record<string, StorefrontPageVendorDto>;
};

export type StorefrontPageVendorUpdateDto = {
    updatedPage: {
        metadata: StorefrontPageVendorMetadataDto;
    };
    image?: any;
};

type StorefrontSyncItemDto = {
    pageId: string;
    vendorName: string;
    pageUrl: string;
};

type StorefrontSyncDto = {
    created: StorefrontSyncItemDto[];
    updated: StorefrontSyncItemDto[];
};

export const getSeoPages = async (): Promise<SeoPagesDto[]> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoCmsApiUrl', 'GET', 'pages', null, {}, token);
};

export const synchronizeStorefrontPages = async (): Promise<StorefrontSyncDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoCmsApiUrl', 'POST', '/storefront/sync-pages', null, {}, token);
};

export const getStorefrontPage = async (pageId: string): Promise<StorefrontPageDto> => {
    const token = await oidc.getAccessToken();
    return await callApi('SeoCmsApiUrl', 'GET', `/storefront/pages/${pageId}`, null, {}, token);
};

export const putStorefrontPage = async (
    pageId: string,
    vendorName: string,
    pageData: StorefrontPageVendorUpdateDto,
): Promise<StorefrontPageDto> => {
    const token = await oidc.getAccessToken();
    const formData = new FormData();
    formData.append('updatedPage', JSON.stringify(pageData.updatedPage));
    if (pageData.image) formData.append('image', pageData.image);
    return await callApi(
        'SeoCmsApiUrl',
        'PATCH',
        `/storefront/pages/${pageId}/vendors/${vendorName}`,
        formData,
        {},
        token,
    );
};
