import { TableCell, Theme } from '@mui/material';
import { CampaignStatus } from 'models/campaign';
import { DiscountStatus } from 'models/discounts/discount';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        height: '100%',
        width: '4px',
        padding: '0px',
    },
    draft: {
        backgroundColor: theme.palette.warning.light,
    },
    scheduled: {
        backgroundColor: theme.palette.success.light,
    },
    published: {
        backgroundColor: theme.palette.success.main,
    },
    expired: {
        backgroundColor: theme.palette.info.light,
    },
    cancelled: {
        backgroundColor: theme.palette.error.light,
    },
}));
export interface StatusBadgeProps {
    status: string | DiscountStatus | CampaignStatus;
}

export const TableStatusRow = ({ status }: StatusBadgeProps): React.ReactElement => {
    const { classes, cx } = useStyles();
    return <TableCell className={cx(classes.root, classes[status])}>&nbsp;</TableCell>;
};
