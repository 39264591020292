declare global {
    interface Window {
        SFPAdminPanel: any;
        Cypress: any;
        NREUM: any;
        store: any;
        oidcManager: any;
    }
}

export const getGlobalObject = (): any => {
    if (typeof window.SFPAdminPanel === 'undefined') {
        window.SFPAdminPanel = {};
    }

    return window.SFPAdminPanel;
};

export const globalObject = getGlobalObject();
