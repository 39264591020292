import { call, takeEvery } from 'redux-saga/effects';
import { handleError } from 'services/errorService';

function* doLogErrors(action) {
    yield call(handleError, action.error, { correlationId: action.correlationId });
}

export function* watchErrors() {
    yield takeEvery(action => action.type && action.type.indexOf('_FAILED') > 0, doLogErrors);
}
