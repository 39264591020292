import React from 'react';

import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { renderIcon } from 'components/common/App/App';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        margin: `${theme.spacing(2.5)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    card: {
        margin: `${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
    },
    tile: {
        height: 140,
        textAlign: 'center',
    },
    cardIcon: {
        color: theme.palette.common.white,
        fontSize: 60,
        top: '33%',
        position: 'relative',
    },
    link: {
        textDecoration: 'none',
    },
}));

export interface CampaingChannelTileProps {
    title: string;
    link: string;
    color: string;
    icon: any;
    cypressTag: string;
}

export const CampaingChannelTile: React.JSXElementConstructor<CampaingChannelTileProps> = ({
    title,
    link,
    color,
    icon,
    cypressTag,
}: CampaingChannelTileProps) => {
    const { classes } = useStyles();
    return (
        <NavLink to={link} className={classes.link} data-cy={cypressTag}>
            <Card className={classes.card}>
                <CardActionArea>
                    <CardMedia className={classes.tile} style={{ backgroundColor: color }} title={title}>
                        {renderIcon(icon, { className: classes.cardIcon })}
                    </CardMedia>
                    <CardContent>
                        <Typography align="center" variant="h6" component="h2">
                            {title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </NavLink>
    );
};
