export const DISCOUNT_TYPES_REQUESTED = 'DISCOUNT_TYPES_REQUESTED';
export const DISCOUNT_TYPES_RECEIVED = 'DISCOUNT_TYPES_RECEIVED';
export const DISCOUNT_TYPES_REQUEST_FAILED = 'DISCOUNT_TYPES_REQUEST_FAILED';

export const OFFER_TEMPORARY_CREATE_REQUESTED = 'OFFER_TEMPORARY_CREATE_REQUESTED';
export const OFFER_TEMPORARY_CREATE_RECEIVED = 'OFFER_TEMPORARY_CREATE_RECEIVED';
export const OFFER_TEMPORARY_CREATE_FAILED = 'OFFER_TEMPORARY_CREATE_FAILED';
export const OFFER_TEMPORARY_CREATE_RESET = 'OFFER_TEMPORARY_CREATE_RESET';

export const OFFER_TEMPORARY_UPDATE_REQUESTED = 'OFFER_TEMPORARY_UPDATE_REQUESTED';
export const OFFER_TEMPORARY_UPDATE_RECEIVED = 'OFFER_TEMPORARY_UPDATE_RECEIVED';
export const OFFER_TEMPORARY_UPDATE_FAILED = 'OFFER_TEMPORARY_UPDATE_FAILED';
export const OFFER_TEMPORARY_UPDATE_RESET = 'OFFER_TEMPORARY_UPDATE_RESET';

export const OFFER_TEMPORARY_DELETE_REQUESTED = 'OFFER_TEMPORARY_DELETE_REQUESTED';
export const OFFER_TEMPORARY_DELETE_RECEIVED = 'OFFER_TEMPORARY_DELETE_RECEIVED';
export const OFFER_TEMPORARY_DELETE_FAILED = 'OFFER_TEMPORARY_DELETE_FAILED';

export const OFFER_CREATE_REQUESTED = 'OFFER_CREATE_REQUESTED';
export const OFFER_CREATE_RECEIVED = 'OFFER_CREATE_RECEIVED';
export const OFFER_CREATE_FAILED = 'OFFER_CREATE_FAILED';

export const OFFER_UPDATE_REQUESTED = 'OFFER_UPDATE_REQUESTED';
export const OFFER_UPDATE_RECEIVED = 'OFFER_UPDATE_RECEIVED';
export const OFFER_UPDATE_FAILED = 'OFFER_UPDATE_FAILED';

export const OFFER_DELETE_REQUESTED = 'OFFER_DELETE_REQUESTED';
export const OFFER_DELETE_RECEIVED = 'OFFER_DELETE_RECEIVED';
export const OFFER_DELETE_FAILED = 'OFFER_DELETE_FAILED';

export const OFFERS_REQUESTED = 'OFFERS_REQUESTED';
export const OFFERS_RECEIVED = 'OFFERS_RECEIVED';
export const OFFERS_REQUEST_FAILED = 'OFFERS_REQUEST_FAILED';

export const CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED = 'CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_REQUESTED';
export const CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_RECEIVED = 'CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_RECEIVED';
export const CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED = 'CAMPAIGN_MERCHANDISING_SLOTS_UPDATE_FAILED';

export const DISCOUNT_CODE_PARSE_REQUESTED = 'DISCOUNT_CODE_PARSE_REQUESTED';
export const DISCOUNT_CODE_PARSE_RECEIVED = 'DISCOUNT_CODE_PARSE_RECEIVED';
export const DISCOUNT_CODE_PARSE_FAILED = 'DISCOUNT_CODE_PARSE_FAILED';
export const DISCOUNT_CODE_PARSE_RESET = 'DISCOUNT_CODE_PARSE_RESET';

export const DISCOUNT_CODE_DELETE_REQUESTED = 'DISCOUNT_CODE_DELETE_REQUESTED';
export const DISCOUNT_CODE_DELETE_RECEIVED = 'DISCOUNT_CODE_DELETE_RECEIVED';
export const DISCOUNT_CODE_DELETE_FAILED = 'DISCOUNT_CODE_DELETE_FAILED';

export const OFFER_DISCOUNT_CODE_UPDATE_REQUESTED = 'OFFER_DISCOUNT_CODE_UPDATE_REQUESTED';
export const OFFER_DISCOUNT_CODE_UPDATE_RECEIVED = 'OFFER_DISCOUNT_CODE_UPDATE_RECEIVED';
export const OFFER_DISCOUNT_CODE_UPDATE_FAILED = 'OFFER_DISCOUNT_CODE_UPDATE_FAILED';
