import {
    LANDING_PAGES_RECEIVED,
    LANDING_PAGES_REQUESTED,
    LANDING_PAGES_REQUEST_FAILED,
    LANDING_PAGES_SUBMIT_FAILED,
    LANDING_PAGES_SUBMIT_RECEIVED,
    LANDING_PAGES_SUBMIT_REQUESTED,
    LANDING_PAGE_CREATE_FAILED,
    LANDING_PAGE_CREATE_RECEIVED,
    LANDING_PAGE_CREATE_REQUESTED,
    LANDING_PAGE_DELETE_FAILED,
    LANDING_PAGE_DELETE_RECEIVED,
    LANDING_PAGE_DELETE_REQUESTED,
    LANDING_PAGE_RECEIVED,
    LANDING_PAGE_REQUESTED,
    LANDING_PAGE_REQUEST_FAILED,
    LANDING_PAGE_REQUEST_RESET,
    LANDING_PAGE_UPDATE_FAILED,
    LANDING_PAGE_UPDATE_RECEIVED,
    LANDING_PAGE_UPDATE_REQUESTED,
} from 'application/campaignForm/landingPageCreate/landingPageCreateTypes';
import { CampaignSubmissionData } from 'common/genericTypes';
import { LandingPage } from 'models/landingPage';
import { Promotion } from 'models/promotion';

export const requestedLandingPageCreation = landingPage => ({
    type: LANDING_PAGE_CREATE_REQUESTED,
    landingPage,
});

export const receivedLandingPageCreation = payload => ({
    type: LANDING_PAGE_CREATE_RECEIVED,
    payload,
});

export const errorLandingPageCreation = (requestAction, error, correlationId: string) => ({
    type: LANDING_PAGE_CREATE_FAILED,
    error,
    requestAction,
    correlationId,
});

export const submitLandingPages = (
    landingPages: CampaignSubmissionData<LandingPage>,
    promotions: Record<number, CampaignSubmissionData<Promotion>>,
    finalizeForm = false,
) => ({
    type: LANDING_PAGES_SUBMIT_REQUESTED,
    landingPages,
    promotions,
    finalizeForm,
});

export const receiveSubmittedLandingPages = payload => ({
    type: LANDING_PAGES_SUBMIT_RECEIVED,
    payload,
});

export const errorSubmittedLandingPages = (error, correlationId: string) => ({
    type: LANDING_PAGES_SUBMIT_FAILED,
    error,
    correlationId,
});

export const requestedLandingPageUpdate = (landingPageData: LandingPage) => ({
    type: LANDING_PAGE_UPDATE_REQUESTED,
    landingPageData,
});

export const receivedLandingPageUpdate = payload => ({
    type: LANDING_PAGE_UPDATE_RECEIVED,
    payload: { data: payload },
});

export const errorLandingPageUpdate = requestAction => ({
    type: LANDING_PAGE_UPDATE_FAILED,
    error: new Error('Failed to update Landing Page.'),
    requestAction,
});

export const requestLandingPages = campaignId => ({
    type: LANDING_PAGES_REQUESTED,
    campaignId,
});
export const receiveLandingPages = payload => ({
    type: LANDING_PAGES_RECEIVED,
    payload,
});
export const errorLandingPagesRequest = requestAction => ({
    type: LANDING_PAGES_REQUEST_FAILED,
    error: new Error('Failed to retrieve the LandingPages'),
    requestAction,
});

export const requestLandingPage = id => ({
    type: LANDING_PAGE_REQUESTED,
    id,
});
export const receiveLandingPage = payload => ({
    type: LANDING_PAGE_RECEIVED,
    payload,
});
export const errorLandingPageRequest = requestAction => ({
    type: LANDING_PAGE_REQUEST_FAILED,
    error: new Error('Failed to retrieve the LandingPage'),
    requestAction,
});

export const resetLandingPages = () => ({
    type: LANDING_PAGE_REQUEST_RESET,
});

export const requestDeleteLandingPage = id => ({
    type: LANDING_PAGE_DELETE_REQUESTED,
    id,
});
export const receivedDeleteLandingPage = payload => ({
    type: LANDING_PAGE_DELETE_RECEIVED,
    payload,
});
export const errorDeleteLandingPage = requestAction => ({
    type: LANDING_PAGE_DELETE_FAILED,
    error: new Error('Failed to delete landing page.'),
    requestAction,
});
