export const PROMOTION_CREATE_REQUESTED = 'PROMOTION_CREATE_REQUESTED';
export const PROMOTION_CREATE_RECEIVED = 'PROMOTION_CREATE_RECEIVED';
export const PROMOTION_CREATE_FAILED = 'PROMOTION_CREATE_FAILED';

export const PROMOTIONS_SUBMIT_REQUESTED = 'PROMOTIONS_SUBMIT_REQUESTED';
export const PROMOTIONS_SUBMIT_RECEIVED = 'PROMOTIONS_SUBMIT_RECEIVED';
export const PROMOTIONS_SUBMIT_FAILED = 'PROMOTIONS_SUBMIT_FAILED';
export const PROMOTIONS_SUBMIT_RESET = 'PROMOTIONS_SUBMIT_RESET';

export const PROMOTION_UPDATE_REQUESTED = 'PROMOTION_UPDATE_REQUESTED';
export const PROMOTION_UPDATE_RECEIVED = 'PROMOTION_UPDATE_RECEIVED';
export const PROMOTION_UPDATE_FAILED = 'PROMOTION_UPDATE_FAILED';

export const PROMOTION_REQUESTED = 'PROMOTION_REQUESTED';
export const PROMOTION_RECEIVED = 'PROMOTION_RECEIVED';
export const PROMOTION_REQUEST_FAILED = 'PROMOTION_REQUEST_FAILED';
export const PROMOTION_REQUEST_RESET = 'PROMOTION_REQUEST_RESET';

export const PROMOTION_OFFER_CREATE_REQUESTED = 'PROMOTION_OFFER_CREATE_REQUESTED';
export const PROMOTION_OFFER_CREATE_RECEIVED = 'PROMOTION_OFFER_CREATE_RECEIVED';
export const PROMOTION_OFFER_CREATE_FAILED = 'PROMOTION_OFFER_CREATE_FAILED';

export const PROMOTION_DEFAULT_MEDIA_REQUESTED = 'PROMOTION_DEFAULT_MEDIA_REQUESTED';
export const PROMOTION_DEFAULT_MEDIA_RECEIVED = 'PROMOTION_DEFAULT_MEDIA_RECEIVED';
export const PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED = 'PROMOTION_DEFAULT_MEDIA_REQUEST_FAILED';
