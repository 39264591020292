/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    bulkCreateMerchandisingSlots,
    bulkDeleteMerchandisingSlots,
    bulkUpdateMerchandisingSlots,
    createMedia,
    createMerchandisingSlot,
    deleteMerchandisingSlot,
    getMerchandisingSlotPositions,
    updateMedia,
    updateMerchandisingSlot,
} from 'apis/campaignApi';
import {
    finalizeCampaignFormRequest,
    switchCampaignFormStep,
} from 'application/campaignForm/campaignCreate/campaignFormStore';
import { requestCampaign } from 'application/campaignForm/campaignCreate/requestedCampaignStore';
import { doSubmitCategories } from 'application/campaignForm/campaignCreate/submittedCategoriesStore';
import { doUploadImageFile } from 'application/campaignForm/promotionCreate/promotionCreateSaga';
import { doBulkSubmitTranslations, doSubmitTranslation } from 'application/translation/translationSaga';
import { doCall } from 'common/genericSaga';
import { CampaignSubmissionData, RequestInfo } from 'common/genericTypes';
import ResponseError from 'errors/ResponseError';
import { partition } from 'helpers/partitionHelper';
import { Category } from 'models/category';
import { Media } from 'models/media';
import { hasTranslation, MerchandisingSlot, MerchandisingSlotPosition } from 'models/merchandisingSlot';
import { TranslationControl, TranslationTypes } from 'models/translation';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
    errorSubmittedMerchandisingSlot,
    errorSubmittedMerchandisingSlots,
    finalizeCampaignFormMerchSlotsCompleted,
    receiveSubmittedMerchandisingSlot,
    receiveSubmittedMerchandisingSlots,
    submitMerchandisingSlots,
} from './merchandisingSlotCreateActions';
import {
    CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED,
    MERCHANDISING_SLOTS_SUBMIT_FAILED,
    MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
    MERCHANDISING_SLOTS_UPDATE_COUNTDOWN,
    MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED,
    MERCHANDISING_SLOT_SUBMIT_FAILED,
    MERCHANDISING_SLOT_SUBMIT_REQUESTED,
} from './merchandisingSlotCreateTypes';

function* doExtractTranslationToSubmit(slots: MerchandisingSlot[], create: boolean) {
    if (slots.length === 0) {
        return [];
    }
    const translations = slots.map(merchandisingSlot => merchandisingSlot.translation);
    const receivedTranslations = yield call(doBulkSubmitTranslations, translations, create);

    // Map translations to merchandisingSlots based on array order since we might have no ids yet
    const mappedTranslations = receivedTranslations.data.map((translation, index) => {
        return {
            ...translation,
            // assign these properties as we might need to rely on them later,
            // since maybe there were no translation ids when translations were submitted
            position: slots[index].position,
            vendor_id: slots[index].vendor_id,
        };
    });

    return mappedTranslations;
}

export function* doBulkSubmitMerchSlotTranslations(merchandisingSlots, create: boolean) {
    let mappedTranslations;
    //if create do not filter between updates and creation. Do a single call
    if (create) {
        mappedTranslations = yield call(doExtractTranslationToSubmit, merchandisingSlots, true);
    } else {
        //find slot traslations that did not have translation before(to create)
        const [merchandisingWithNewTranslation, slotsToUpdate] = partition(
            merchandisingSlots,
            submittedMerchandisingSlot => !submittedMerchandisingSlot.translation.data.id,
        );

        const mappedCreatedTranslations = yield call(
            doExtractTranslationToSubmit,
            merchandisingWithNewTranslation,
            true,
        );
        const mappedUpdatedTranslation = yield call(doExtractTranslationToSubmit, slotsToUpdate, create);
        mappedTranslations = [...mappedCreatedTranslations, ...mappedUpdatedTranslation];
    }

    return mappedTranslations;
}

function* doAddTranslationToEntity(entity: Media, translation: TranslationControl, translationKey: string) {
    // Only persist the translation if it is custom translation, contains text and changed or hasn't been submitted
    if (
        (('type' in translation && translation.type === TranslationTypes.CUSTOM_TRANSLATION) || !translation.type) &&
        (translation.changed === true || !translation.id) &&
        'translation' in translation &&
        translation.translation !== undefined
    ) {
        const translationData = { data: translation };
        const submittedTranslation = yield call(doSubmitTranslation, translationData);
        translation = submittedTranslation.data;
    }

    // If the translation exists or was submitted or is predefined, associate it
    if (translation.id) {
        entity[translationKey] = translation.id;
    }

    return entity;
}

function* doCreateMediaTranslations(media: any, locale: string) {
    const translationKeys = ['banner_title_translation', 'banner_subtitle_translation', 'cta_translation'];
    for (const translationKey of translationKeys) {
        const translationData = media[translationKey];
        translationData.locale = locale;
        // Make sure this is not sent, as the API expects an ID
        delete media[translationKey];
        media = yield call(doAddTranslationToEntity, media, translationData, translationKey + '_id');
    }

    return media;
}

export function* submitMedia(media) {
    if (media.id) {
        return yield call(updateMedia, media);
    }
    delete media.id;
    return yield call(createMedia, media);
}

function* doSubmitMedia(media: any, merchandisingSlotId) {
    media.merchandising_slots = [merchandisingSlotId];

    yield call(submitMedia, media);
}

function* doUploadImage(media: any) {
    // If don't have a File object, considere that the image
    //  was not update
    if (media.file instanceof File) {
        media.asset_path = yield call(doUploadImageFile, media.file);
        delete media.file;
    }

    return media;
}

export function* doSubmitMerchandisingSlot(action, requestInfo: RequestInfo) {
    if (action.merchandisingSlot.translation && 'translation' in action.merchandisingSlot.translation.data) {
        const translation = yield call(doSubmitTranslation, action.merchandisingSlot.translation);
        action.merchandisingSlot.translation_id = translation.id;
    }

    try {
        const merchandisingSlot = yield call(
            action.merchandisingSlot.id ? updateMerchandisingSlot : createMerchandisingSlot,
            action.merchandisingSlot,
        );

        if (action.merchandisingSlot.media) {
            // Upload de medias in case have a new one
            let media = action.merchandisingSlot.media;
            media = yield all(media.map(medium => doUploadImage(medium)));
            // Create the custom translations
            const locale = action.merchandisingSlot.locale ?? merchandisingSlot.data.vendor.data.locale;
            media = yield all(media.map(medium => doCreateMediaTranslations(medium, locale)));
            // Create/Update the Media
            media = yield all(media.map(medium => doSubmitMedia(medium, merchandisingSlot.data.id)));

            merchandisingSlot.data.media = media;
        }

        yield put(receiveSubmittedMerchandisingSlot(merchandisingSlot.data));
        yield put(requestCampaign(merchandisingSlot.data.campaign_id));
    } catch (error) {
        if (error instanceof ResponseError) {
            yield put(errorSubmittedMerchandisingSlot(error, requestInfo.correlationId));
            return;
        }

        throw error;
    }
}

export function* doBulkSubmitMerchandisingSlots(submittedMerchandisingSlots, create: boolean) {
    // remove Merchandising Slots with invalid translation data, like Media Merchandising Slots
    const [merchandisingSlotsToTranslate, otherMerchandsingSlots] = partition(
        submittedMerchandisingSlots,
        submittedMerchandisingSlot => hasTranslation(submittedMerchandisingSlot, false),
    );

    let translations;
    if (merchandisingSlotsToTranslate.length > 0) {
        translations = yield call(doBulkSubmitMerchSlotTranslations, merchandisingSlotsToTranslate, create);
    }

    // map translations to corresponding merchandisingSlotsToTranslate based on
    // either id (when updating) or position && vendor (when creating, no id match possible)
    merchandisingSlotsToTranslate.map((merchandisingSlot, index) => {
        const matchingTranslation = translations.find(translation => {
            return create || merchandisingSlot.translation.data.id === null
                ? translation.vendor_id === merchandisingSlot.vendor_id &&
                      translation.position === merchandisingSlot.position
                : translation.id === merchandisingSlot.translation.data!.id;
        });
        // If there's no translation then we can't create the Merch. Slot
        if (matchingTranslation && 'id' in matchingTranslation) {
            merchandisingSlot.translation_id = matchingTranslation.id;
        } else {
            delete merchandisingSlotsToTranslate[index];
        }
    });

    const merchandisingSlotsToCreateOrUpdate = [...merchandisingSlotsToTranslate, ...otherMerchandsingSlots];
    const updatedSlots = yield call(create === true ? bulkCreateMerchandisingSlots : bulkUpdateMerchandisingSlots, {
        merchandising_slots: merchandisingSlotsToCreateOrUpdate,
    });

    return updatedSlots;
}

export function* doDeleteMerchandisingSlot(merchandisingSlot) {
    return yield call(deleteMerchandisingSlot, merchandisingSlot);
}

interface SubmitMerchandisingSlotsAction {
    merchandisingSlots: CampaignSubmissionData<MerchandisingSlot>;
}

export function* doSubmitMerchandisingSlots(action: SubmitMerchandisingSlotsAction, requestInfo: RequestInfo) {
    const merchandisingSlots: { data: Array<any> } = { data: [] };
    const itemsActions = ['itemsToCreate', 'itemsToUpdate'];
    for (const itemsAction of itemsActions) {
        if (action.merchandisingSlots[itemsAction].length) {
            try {
                const persistedMerchandisingSlots = yield call(
                    doBulkSubmitMerchandisingSlots,
                    action.merchandisingSlots[itemsAction],
                    itemsAction === 'itemsToCreate',
                );
                merchandisingSlots.data = [...merchandisingSlots.data, ...persistedMerchandisingSlots.data];
            } catch (error) {
                if (error instanceof ResponseError) {
                    yield put(errorSubmittedMerchandisingSlots(error, requestInfo.correlationId));
                    return;
                }

                throw error;
            }
        }
    }

    if (action.merchandisingSlots.itemsToDelete.length) {
        yield call(bulkDeleteMerchandisingSlots, {
            merchandising_slots: action.merchandisingSlots.itemsToDelete,
        });
    }

    yield put(receiveSubmittedMerchandisingSlots(merchandisingSlots));
    return merchandisingSlots;
}

export interface FinalizeMerchSlotsFormStepAction {
    merchandisingSlots: CampaignSubmissionData<MerchandisingSlot>;
    categories: CampaignSubmissionData<Category>;
    finalizeForm: boolean;
    moveToNextStep: boolean;
}

export function* finalizeMerchSlotsFormStep(action: FinalizeMerchSlotsFormStepAction, requestInfo: RequestInfo) {
    yield call(doSubmitMerchandisingSlots, { merchandisingSlots: action.merchandisingSlots }, requestInfo);
    yield call(doSubmitCategories, { categories: action.categories }, requestInfo);

    if ('finalizeForm' in action && action.finalizeForm === true) {
        yield put(finalizeCampaignFormRequest());
    }

    if ('moveToNextStep' in action && action.moveToNextStep === true) {
        //move to next step
        yield put(switchCampaignFormStep(4));
    }

    yield put(finalizeCampaignFormMerchSlotsCompleted());
}

export const prepareMerchandisingSlotsSubmitData = formValues => {
    // KEEP ONLY TEXT SLOTS that already exist or that have a translation text so we dont try to delete non-existant slots
    const textSlots = formValues.textMerchandisingSlots.filter(
        merchandisingSlot =>
            merchandisingSlot.id ||
            (merchandisingSlot.id === null && hasTranslation(merchandisingSlot, true)) ||
            (merchandisingSlot.id === null && merchandisingSlot.has_countdown === true),
    );

    // CREATE TEXT SLOTS that have id === null and a translation text or have countdown enabled
    const [textSlotsToCreate, otherTextSlots] = partition(
        textSlots,
        merchandisingSlot =>
            (merchandisingSlot.id === null && hasTranslation(merchandisingSlot, true)) ||
            (merchandisingSlot.id === null && merchandisingSlot.has_countdown === true),
    );

    // UPDATE TEXT SLOTS that have an id and a translation text OR id and countdown
    // DELETE TEXT SLOTS that have an id but no translation text OR id but not countdown
    const [textSlotsToUpdate, textSlotsToDelete] = partition(
        otherTextSlots,
        merchandisingSlot =>
            (!!merchandisingSlot.id && hasTranslation(merchandisingSlot)) ||
            (!!merchandisingSlot.id && merchandisingSlot.has_countdown === true),
    );

    // CREATE MEDIA SLOTS that have id === null
    // UPDATE MEDIA SLOTS that have an id
    const [mediaSlotsToCreate, mediaSlotsToUpdate] = partition(
        formValues.mediaMerchandisingSlots,
        merchandisingSlot => merchandisingSlot.id === null,
    );

    const itemsToCreate = [...textSlotsToCreate, ...mediaSlotsToCreate];
    // remove unneeded field
    itemsToCreate.forEach(merchandisingSlot => delete merchandisingSlot.id);

    const itemsToUpdate = [...textSlotsToUpdate, ...mediaSlotsToUpdate];
    // MERGE items that do not match business rules in setInitFormMerchandisingSlots and textSlotsToDelete
    const itemsToDelete = [...formValues.merchandisingSlotsToDelete, ...textSlotsToDelete];

    return {
        itemsToUpdate,
        itemsToCreate,
        itemsToDelete,
    };
};

export function* doUpdateMerchandisingSlotsCounter(
    action: { textSlots: MerchandisingSlot[]; withCountdown: boolean; vendorId: number },
    requestInfo: RequestInfo,
) {
    const slotsPositions = yield call(getMerchandisingSlotPositions, {});

    // filter slots with coundown enabled
    const enabledPositions = slotsPositions.data
        .filter((position: MerchandisingSlotPosition) => position.countdown_compatible === true)
        .map((position: MerchandisingSlotPosition) => position.id);

    // update the values
    const updatedSlots = action.textSlots.map(slot =>
        enabledPositions.includes(slot.position) && slot.vendor_id === action.vendorId
            ? { ...slot, has_countdown: action.withCountdown }
            : slot,
    );

    const formattedSlots = prepareMerchandisingSlotsSubmitData({
        textMerchandisingSlots: updatedSlots,
        mediaMerchandisingSlots: [],
        merchandisingSlotsToDelete: [],
    });

    //sumbit update
    yield put(submitMerchandisingSlots(formattedSlots));
}

export function* watchMerchandisingSlotCreation() {
    yield all([
        takeEvery(
            MERCHANDISING_SLOT_SUBMIT_REQUESTED,
            doCall(doSubmitMerchandisingSlot, MERCHANDISING_SLOT_SUBMIT_FAILED),
        ),
        takeEvery(
            MERCHANDISING_SLOTS_SUBMIT_REQUESTED,
            doCall(doSubmitMerchandisingSlots, MERCHANDISING_SLOTS_SUBMIT_FAILED),
        ),
        takeEvery(
            MERCHANDISING_SLOTS_UPDATE_COUNTDOWN,
            doCall(doUpdateMerchandisingSlotsCounter, MERCHANDISING_SLOTS_UPDATE_COUNTDOWN_FAILED),
        ),
        takeEvery(
            CAMPAIGN_FORM_MERCH_SLOTS_FINALIZE_REQUESTED,
            doCall(finalizeMerchSlotsFormStep, MERCHANDISING_SLOTS_SUBMIT_FAILED),
        ),
    ]);
}
